import React from 'react'
import { Provider } from 'react-redux'
import { MuiThemeProvider as Mui1ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom'
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

import locale from 'moment/locale/hu'

import ForkAdmin from './ForkAdmin'
import { mui1Theme } from './Theme.js'
import store from './store'

class App extends React.Component{
	render(){
		return (
			<Provider store={store}>
				<Mui1ThemeProvider theme={mui1Theme}>
					<MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
						<Router basename="/admin"><ForkAdmin /></Router>
					</MuiPickersUtilsProvider>
				</Mui1ThemeProvider>
			</Provider>
		)
	}
}

export default App