import teal from '@material-ui/core/colors/teal';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { createMuiTheme } from '@material-ui/core/styles';
const teal500 = teal['500'];
const teal600 = teal['600'];
const teal700 = teal['700'];
const deepOrange500 = deepOrange['500'];

const primary = teal500
const primary2 = teal600
const primary3 = teal700
const accent = deepOrange500
const error = 'rgb(244, 67, 54)'

// const muiTheme = getMuiTheme({
// 	palette: {
// 		primary1Color: primary,
// 		primary2Color: primary2,
// 		primary3Color: primary3,
// 		accent1Color: accent
// 	}
// })

const mui1Theme = createMuiTheme({
  palette: {
    primary: {
      light: '#52c7b8',
      main: '#009688',
      dark: '#00675b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff8a50',
      main: '#ff5722',
      dark: '#c41c00',
      contrastText: '#fff',
    },
  },
})



export { mui1Theme, primary, primary2, primary3, accent, error }