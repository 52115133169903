import React from 'react'
import { connect } from 'react-redux'
import { Desktop, Mobile } from '../Responsive'
import { primary, accent } from '../Theme'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import { Grid, Row, Col } from 'react-flexbox-grid'
import { fetchStatistics, fetchStatisticsIds,fetchUsers, selectStoreStatusId, selectUserStats } from '../actions'
import Print from '@material-ui/icons/Print'
import { InlineDatePicker, DatePicker } from 'material-ui-pickers';
import socket from '../socket'
import ClosingReceipt from '../classes/ClosingReceipt'
import ForkDialog from '../components/ForkDialog'
import { IconButton, withStyles } from "@material-ui/core";
import moment from 'moment'
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';

import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';

let localize = (num)=>{
		return (typeof num != 'undefined') ? Number(num).toLocaleString() : ""
}

//import translator from '../translator'
//const _t = translator('HOME')
class _Statistics extends React.Component{

	constructor(props) {
		super(props)
		this.state = {
			startDate: moment().startOf('week').startOf('day'),
			endDate: moment().startOf('day'),
			sortBy: 0,
			sortDir: 'desc',
			selectedPreset: 'week',
			users: [
				{id: null, username: "Nincs", selected: true}
			],
			userSelectOpen: false,
			storeStatusSelectOpen: false

		}
		
		this.datePresets = [
		{name: 'today', label: 'Ma'},
		{name: 'yesterday', label: 'Tegnap'},
		{name: 'week', label: 'Hét'},
		{name: 'month', label: 'Hónap'},
		{name: 'year', label: 'Év'},
		{name: 'alltime', label: 'Teljes'},
		/*
		{name: '7days', label: '7 nap'},
		{name: '31days', label: '31 nap'},
		*/
		]

		this.renderDay = this.renderDay.bind(this)
		this.labelFormat = this.labelFormat.bind(this)
		this.fetchStatistics = this.fetchStatistics.bind(this)
		this.fetchStatisticsIds = this.fetchStatisticsIds.bind(this)
		this.onSortClick = this.onSortClick.bind(this)
		this.props.dispatch(fetchUsers()).then(()=>{
			let newUsers = this.props.users.map(u=>{return{...u, selected: true}})
			this.setState({users: [...this.state.users, ...newUsers]}, ()=>{
				this.fetchStatisticsIds()				
			})
		})
	}

	onSortClick(i){
		if(i!=this.state.sortBy) return this.setState({sortBy: i, sortDir: (i==0||i==1) ? 'desc' : 'asc'})
		this.setState({sortDir: (this.state.sortDir=='asc') ? 'desc' : 'asc'})
	}
	
	renderDay(date, selectedDate, dayInCurrentMonth){
		if (!dayInCurrentMonth) return (
			<div style={{position: "relative", width: 36, height: 36, margin: "0 2px"}} />
		)
		if (date.diff(moment())>0){
			return <div style={{position: "relative", width: 36, height: 36, margin: "0 2px", fontSize: '12px', color: '#bfbfbf'}}>
				<span style={{marginTop: '-1px', position:'absolute', left: '50%', top:'50%', transform:'translate(-50%, -50%)'}}>{date.format('D')}</span>
			</div>
		}

		let selected = date.isBetween(this.state.startDate, this.state.endDate, 'days', '[]')
		let first = date.isSame(this.state.startDate)
		let last = date.isSame(this.state.endDate)
		
		if (!this.state.endDate) {
			selected = date.isSame(this.state.startDate)
			if (selected){
				first = true
				last = false
			}
		}
		return (
			<div style={{position: "relative"}}>
				<IconButton
				onClick={()=>{this.setDate(date)}}
				style={{
						width: 36+(selected*4)-(first*2)-(last*2),
						height: 36,
						marginLeft: (selected&&!first) ? '0px' : '2px',
						marginRight: (selected&&!last) ? '0px' : '2px',
						fontSize: '12px',
						color: selected ? "#fff" : "inherit",
						backgroundColor: selected ? '#009688' : '#fff',
						borderTopLeftRadius: (first || !selected) ? "50%" : "0",
    					borderBottomLeftRadius: (first || !selected) ? "50%" : "0",
    					borderTopRightRadius: (last || !selected) ? "50%" : "0",
    					borderBottomRightRadius: (last || !selected) ? "50%" : "0",
  					}}>
					<span style={{marginTop: '-3px'}}>{date.format('D')}</span>
				</IconButton>
			</div>
		)
	}

	labelFormat(date){
		return new Date(this.state.startDate).toLocaleDateString() + ' - ' + new Date(this.state.endDate).toLocaleDateString()
	}

	setDate(date){
		if(date.diff(moment())>0) return
		let start = this.state.startDate
		let end = this.state.endDate
		this.setState({selectedPreset: null})
		if (!start && !end) return this.setState({startDate: date})
		if (start && !end && date.diff(start)>=0) return this.setState({endDate: date})
		if (start && !end && date.diff(start)<0) return this.setState({startDate: date, endDate: start})
		if (start && end) return this.setState({startDate: date, endDate: null})
	}

	setPresetDate(s){
		let startDate, endDate
		switch (s){
			case 'today':
				startDate = moment().startOf('day')
				endDate = moment().startOf('day')
				break
			case 'yesterday':
				startDate = moment().subtract(1,'d').startOf('day')
				endDate = moment().subtract(1,'d').startOf('day')
				break
			case 'week':
				startDate = moment().startOf('week').startOf('day')
				endDate = moment().startOf('day')
				break
			case 'month':
				startDate = moment().startOf('month').startOf('day')
				endDate = moment().startOf('day')
				break
			case 'year':
				startDate = moment().startOf('year').startOf('month').startOf('day')
				endDate = moment().startOf('day')
				break
			case 'alltime':
				startDate = moment(0)
				endDate = moment().startOf('day')
				break
			case '7days':
				startDate = moment().subtract(7,'d').startOf('day')
				endDate = moment().startOf('day')
				break
			case '31days':
				startDate = moment().subtract(31,'d').startOf('day')
				endDate = moment().startOf('day')
				break
		}
		this.setState({startDate, endDate, selectedPreset: s}, ()=>{
			this.fetchStatisticsIds()
		})
	}

	setSimpleDate(newState){
		this.setState(newState, ()=>{
			this.fetchStatisticsIds()
		})
	}

	fetchStatisticsIds(){
		let endDate = this.state.endDate
		if (!this.state.endDate) {
			this.setState({endDate: this.state.startDate})
			endDate=this.state.startDate
		}
		return this.props.dispatch(
			fetchStatisticsIds(this.state.startDate.clone().format('x'), endDate.clone().endOf('day').format('x'))
		).then(()=>{
			this.fetchStatistics()
		})
	}

	fetchStatistics(){
		return this.props.dispatch(fetchStatistics(this.props.statisticsIds.filter(sS=>sS.selected).map(sS=>sS.id), this.state.users.filter(u=>u.selected).map(u=>u.id)))
	}
	
	render(){
		return (
			<Grid fluid style={{padding: '20px 10px 10px 10px'}}>
				<Row>
					<Col md={12} xs={12} style={{padding: '0 8px'}}>
						<Paper style={{padding: ' 20px 10px', marginBottom: '10px', position:'relative'}}>
							<Grid fluid style={{padding: '0'}}>
							<Row>
								<Col md={4} xs={8} style={{marginBottom: '10px'}}>
									<TextField 
										label="Időszak"
										value={
											(this.state.endDate && this.state.startDate.diff(this.state.endDate)!=0) ? new Date(this.state.startDate).toLocaleDateString() + ' - ' + new Date(this.state.endDate).toLocaleDateString()
											: new Date(this.state.startDate).toLocaleDateString()
										}
										style={{borderColor: '#009688'}}
										inputProps={{style: {cursor: 'pointer'}}}
										onFocus={(e)=>{this.periodPicker.open(); e.target.blur()}}
										variant="outlined"
										readOnly
										fullWidth
									/>
									<DateRangePicker
										onChange={()=>{this.fetchStatisticsIds()}}
										renderDay={this.renderDay}
										labelFunc={this.labelFormat}
										nodeRef={(node) => {
											this.periodPicker = node
										}}
									/>
									
								</Col>

								<Col md={2} xs={4} style={{marginBottom: '10px'}}>
									<TextField 
										label="Nyitások"
										value={this.props.statisticsIds.filter(s=>s.selected).length + ' kiválasztva'}
										onFocus={(e)=>{e.target.blur(); this.setState({storeStatusSelectOpen: true})}}
										variant="outlined"
										inputProps={{style: {cursor: 'pointer'}}}
										readOnly
										fullWidth
									/>
									<ForkDialog
										open={this.state.storeStatusSelectOpen}
										action={(act)=>{
											this.fetchStatistics()
											this.setState({storeStatusSelectOpen: false})
										}}
										title="Nyitások"
										disablePadding
										yesLabel="OK"
										hideNo
										noLabel="Mégsem"
									>
										{(!this.props.statisticsIds.length) ?
											<Typography variant="subheading" color="textSecondary" align="center" style={{marginTop: '20px'}}>Nincs megjeleníthető elem.<br/>Válasszon más időszakot.</Typography>
											: null
										}
										<List>
											{(this.props.statisticsIds.length) ? 
											<div><ListItem role={undefined} dense button onClick={()=>{
													this.props.dispatch(selectStoreStatusId("all"))
											}}>
												<Checkbox
												style={{padding: '5px 0'}}
												checked={this.props.statisticsIds.every(u=>u.selected)}
												tabIndex={-1}
												disableRipple
												/>
												<ListItemText
												primary={"Mind kijelölése"} />
											</ListItem>
											<Divider /></div>
											: null}

											{this.props.statisticsIds.map(sS => (
												<ListItem key={sS.id} role={undefined} dense button onClick={()=>{
													this.props.dispatch(selectStoreStatusId(sS.id))
												}}>
													<Checkbox
													style={{padding: '5px 0'}}
													checked={sS.selected}
													tabIndex={-1}
													disableRipple
													/>
													<ListItemText
													primary={ (sS.closeTime) ? (
													moment(sS.createdAt).format('YYYY. MM. DD. HH:mm')
													+ ' -     ' + moment(sS.closeTime).format('YYYY. MM. DD. HH:mm')
													) : moment(sS.createdAt).format('YYYY. MM. DD. HH:mm') + ' (Jelenleg nyitva)'
													} />
												</ListItem>
											))}
											
										</List>
									</ForkDialog>
								</Col>
								<Col md={2} xs={4} style={{marginBottom: '10px'}}>
									<TextField 
										label="Felszolgálók"
										value={this.state.users.filter(s=>s.selected).length + ' kiválasztva'}
										onFocus={(e)=>{e.target.blur(); this.setState({userSelectOpen: true})}}
										variant="outlined"
										inputProps={{style: {cursor: 'pointer'}}}
										readOnly
										fullWidth
									/>
									<ForkDialog
										open={this.state.userSelectOpen}
										action={(act)=>{
											this.fetchStatistics()
											this.setState({userSelectOpen: false})
										}}
										title="Felszolgálók"
										disablePadding
										yesLabel="OK"
										hideNo
										noLabel="Mégsem"
									>
										{(!this.props.users.length) ?
											<Typography variant="subheading" color="textSecondary" align="center" style={{marginTop: '20px'}}>Nincs megjeleníthető felszolgáló.</Typography>
											: null
										}
										<List>
											<ListItem role={undefined} dense button onClick={()=>{
													let s = this.state.users.some(u=>!u.selected)
													this.setState({	
														users: this.state.users.map(u=>{return {...u, selected: s}})
													})
													
											}}>
												<Checkbox
												style={{padding: '5px 0'}}
												checked={this.state.users.every(u=>u.selected)}
												tabIndex={-1}
												disableRipple
												/>
												<ListItemText
												primary={"Mind kijelölése"} />
											</ListItem>
											<Divider />
											{this.state.users.map(user => (
												<ListItem key={user.id} role={undefined} dense button onClick={()=>{
														//this.props.dispatch(selectUserStats(sS.id))
														this.setState({
															users: this.state.users.map(u=>{return {...u, selected: (u.id==user.id) ? !u.selected : u.selected}})
														})
														
												}}>
													<Checkbox
													style={{padding: '5px 0'}}
													checked={user.selected}
													tabIndex={-1}
													disableRipple
													/>
													<ListItemText
													primary={<span>{(user.lastName ? user.lastName + " " : "")}{(user.firstName ? user.firstName + " " : "")}{(!user.firstName && !user.lastName ? user.username : "")}</span>} />
												</ListItem>
											))}
											
										</List>
									</ForkDialog>
								</Col>
								<Col md={12} xs={12}>
									<div style={{marginTop: '10px', marginBottom: '0px'}}>
										{this.datePresets.map((preset, key)=>{
											return <Chip
												variant={this.state.selectedPreset==preset.name ? "default" : "outlined"}
												label={preset.label}
												style={{marginRight: '5px'}}
												color={this.state.selectedPreset==preset.name ? "primary" : "primary"}
												onClick={()=>{this.setPresetDate(preset.name)}}/>
										})}
									</div>									
								</Col>
							</Row>
							</Grid>
						</Paper>
					</Col>
					<Col md={4} xs={12}>
						<Sums traffic={this.props.statistics} />
						<Paper style={{padding: '20px', marginBottom: '20px', position: 'relative'}}>
							<Typography variant="h6">Időbeli eloszlás</Typography>
							<ResponsiveContainer width='100%' height={200}>
								<BarChart data={this.props.statistics.sumsByTime.map(r=>{return {...r, "Összesen":r.sum, name: moment(r.time*15*1000*60).format('HH:mm')}})}>
									<Tooltip formatter={val=>{return Number(val).toLocaleString('hu-hu', { style: 'currency', currency: 'HUF', minimumFractionDigits: 0})}}/>
									<CartesianGrid strokeDasharray="3 3" vertical={false}/>
									<Bar dataKey="Összesen" fill="#009688" />
									<XAxis padding={{left: 30, right: 30}} dataKey="name"/>
									<YAxis hide domain={[0, 1.1 * Math.max.apply(Math, this.props.statistics.sumsByTime.map(r=>r.sum))]}/>
								</BarChart>
							</ResponsiveContainer>
						</Paper>
					</Col>
					
					
					<Col md={8} xs={12}>
						<Paper style={{padding: '20px', marginBottom: '20px', position: 'relative'}}>
							<Typography variant="title">Napi bontás</Typography>
							<ResponsiveContainer width='100%' height={281}>
								
								<LineChart margin={{bottom: 30, top: 30}} data={this.props.statistics.groupedSums.map(r=>{return {...r, "Összesen":r.sum, name: moment(r.open).format('YYYY. MM. DD.')}})}>
									<YAxis hide domain={[0, Math.max.apply(Math, this.props.statistics.groupedSums.map(r=>r.sum))]}/>
									<XAxis padding={{left: 30, right: 30}} dataKey="name"/>
									<Tooltip isAnimationActive={false} labelFormatter={val=>{return moment(val).format('MMMM D., dddd')}} formatter={val=>{return Number(val).toLocaleString('hu-hu', { style: 'currency', currency: 'HUF', minimumFractionDigits: 0})}} />
									<Line type="linear" dataKey="Összesen" stroke="#ff5722" strokeWidth={2} animationDuration={500}/>
									<CartesianGrid strokeDasharray="3 3" vertical={false}/>
								</LineChart>
							</ResponsiveContainer>
						</Paper>
						<TrafficTable
							sortBy={this.state.sortBy}
							sortDir={this.state.sortDir}
							onSortClick={this.onSortClick}
							isMobile={this.props.isMobile}
							traffic={this.props.statistics} />
					</Col>
					<Col md={4} xs={12}>
						
					</Col>
					<Col md={8} xs={12}>
						
					</Col>
					{/*
					<Col md={6}>
						<Paper style={{padding: '20px', marginBottom: '20px', position: 'relative'}}>
							<span style={{fontWeight: 300, fontSize: '25px'}}>Legnépszerűbb termékek</span>
							<ResponsiveContainer width='100%' height={300}>
								<PieChart>
									<Pie data={this.props.statistics.items} dataKey="quantity" innerRadius={80} outerRadius={110} fill="#82ca9d" label={(e,p)=>e.name}>
										{
											this.props.statistics.items.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
										}
									</Pie>
									<Tooltip/>
								</PieChart>
							</ResponsiveContainer>
						</Paper>
					</Col>

					*/}
				</Row>
				
			</Grid>
		)
	}
}

class DateRangePicker extends React.PureComponent{
	shouldComponentUpdate(){
		return false
	}
	render(){
		return <div style={{display: 'none'}}>
		<DatePicker
			value={new Date()}
			fullWidth
			cancelLabel=""
			okLabel="OK"
			label="Időszak"
			onChange={this.props.onChange}
			style={{marginRight: '20px'}}
			autoOk={false}
			renderDay={this.props.renderDay}
			variant="outlined"
			labelFunc={this.props.labelFunc}
			disableFuture
			ref={this.props.nodeRef}
		/>
		</div>
	}
}

const Sums = (props)=>{
	let vatKeyIds = (props.traffic.sums.vatKeys) ? Object.keys(props.traffic.sums.vatKeys) : []
	return (
		<div>
		<Paper style={{padding: '20px', marginBottom: '10px'}}>
			<Typography variant="h6">Összesített forgalom</Typography>
			
			<Grid fluid style={{padding: '20px 5px 0 5px'}}>
				{
					vatKeyIds.map((vatKeyId)=>{
						let vatKey = props.traffic.sums.vatKeys[vatKeyId]
						return (
							<Row style={{marginBottom: '10px', fontSize: '13px'}} key={vatKey.id}>
								<Col md={6} xs={6}>
									{vatKey.name} ({vatKey.value*100}%)
								</Col>
								<Col md={6} xs={6} style={{textAlign: 'right'}}>
									{localize(vatKey.sum)} Ft
								</Col>
							</Row>
						)
					})
				}
				<Divider />
				<Row style={{paddingTop: '10px', paddingBottom: '8px'}}>
					<Col md={6} xs={6}>
						<span style={{fontWeight: 600, fontSize: '20px'}}>Összesen</span>
					</Col>
					<Col md={6} xs={6} style={{textAlign: 'right'}}>
						<span style={{fontWeight: 600, fontSize: '20px'}}>{localize(props.traffic.sums.sum)} Ft</span>
					</Col>
				</Row>
				<Divider />
				{	(props.traffic.sums.serviceFee) ? 
					<Row style={{paddingTop: '5px'}}>
						<Col md={6} xs={6}>
							<span style={{fontWeight: 400, fontSize: '13px'}}>Szervizdíj</span>
						</Col>
						<Col md={6} xs={6} style={{textAlign: 'right'}}>
							<span style={{fontWeight: 400, fontSize: '13px'}}>{localize(props.traffic.sums.serviceFee)} Ft</span>
						</Col>
						<Col md={6} xs={6} style={{paddingTop: '5px'}}>
							<span style={{fontWeight: 400, fontSize: '13px'}}>Különbség</span>
						</Col>
						<Col md={6} xs={6} style={{textAlign: 'right', paddingTop: '5px'}}>
							<span style={{fontWeight: 400, fontSize: '13px'}}>{localize(props.traffic.sums.sum - props.traffic.sums.serviceFee)} Ft</span>
						</Col>
					</Row> : null
				}
				<Row style={{paddingTop: '5px', paddingBottom: '8px'}}>
					<Col md={6} xs={6}>
						<span style={{fontWeight: 400, fontSize: '13px'}}>Kedvezmény ({props.traffic.sums.discountCount} tétel)</span>
					</Col>
					<Col md={6} xs={6} style={{textAlign: 'right'}}>
						<span style={{fontWeight: 400, fontSize: '13px'}}>{localize(props.traffic.sums.discount)} Ft</span>
					</Col>
				</Row>
				
			</Grid>
		</Paper>
		<Paper style={{padding: '20px', marginBottom: '10px'}}>
				<Typography variant="h6">Sztornózott/mínuszos tételek</Typography>
				<Grid fluid style={{padding: '20px 5px 0 5px'}}>
					<Row style={{marginBottom: '5px', fontSize: '13px', color: 'rgba(0,0,0,0.54)'}}>
						<Col md={6} xs={6}>
							Név
						</Col>
						<Col md={2} xs={2} style={{textAlign: 'right'}}>
							Mennyiség
						</Col>
						<Col md={2} xs={2} style={{textAlign: 'center'}}>
							Rögzítve
						</Col>
						<Col md={2} xs={2} style={{textAlign: 'center'}}>
							Sztornózva
						</Col>
					</Row>
					<Divider style={{marginBottom: '10px'}}/>

					{
						props.traffic.stornoAndNegativeItems.map((item, key)=>{
							return (
								<div>
								<Row style={{marginBottom: '10px', fontSize: '13px'}} key={key}>
									<Col md={6} xs={6}>
										{item.name}
									</Col>
									<Col md={2} xs={2} style={{textAlign: 'right'}}>
										{Number(item.quantity)}
									</Col>
									<Col md={2} xs={2} style={{textAlign: 'center'}}>
										{moment(item.createdAt).format("HH:mm")}
									</Col>
									<Col md={2} xs={2} style={{textAlign: 'center'}}>
										{item.deletedAt ? moment(item.deletedAt).format("HH:mm") : "-"}
									</Col>
								</Row>
								<Divider style={{marginBottom: '10px'}}/>
								</div>
							)
						})
					}
				</Grid>
			</Paper>
		{(props.traffic.sumsByUsers.length>0 && !props.traffic.sumsByUsers.every(u=>u.userId==null)) ? 
		<Paper style={{padding: '20px', marginBottom: '10px'}}>
				<Typography variant="h6">Felszolgálók</Typography>
				<Grid fluid style={{padding: '20px 5px 0 5px'}}>
					{
						props.traffic.sumsByUsers.map((user, key)=>{
							let name = (user.userId) ? String((user.firstName) ? user.firstName + " " + user.lastName.substr(0,1) : user.username) : 'Nincs'
							return (
								<div>
								<Row style={{marginBottom: '10px', fontSize: '13px'}} key={key}>
									<Col md={6} xs={6}>
										{name}
									</Col>
									<Col md={6} xs={6} style={{textAlign: 'right'}}>
										{localize(Number(user.sum))} Ft
									</Col>
								</Row>
								<Divider style={{marginBottom: '10px'}}/>
								</div>
							)
						})
					}
				</Grid>
			</Paper> : null}
			</div>
	)
}

const TrafficTable = (props)=>{
	let getVal = [(a)=>a.menuCardItem.id, (a)=>a.menuCardItem.name, (a)=>a.quantity, (a)=>a.menuCardItem.price, (a)=>a.discount, (a)=>a.sumPrice][props.sortBy]
	function sorter(a,b) {
		return props.sortDir === 'asc'
    		? (getVal(b) < getVal(a) ? -1 : 1)
    		: (getVal(a) < getVal(b) ? -1 : 1)
	}
	return (
		<Paper style={{padding: '20px', marginBottom: '20px', position: 'relative', overflow: 'auto'}}>
			<Typography variant="h6">Tételes forgalom</Typography>
			{/*<Desktop>
				<Button variant="contained" className="no-print" onClick={()=>{window.print()}} color="primary" children="Forgalom nyomtatása" style={{position: 'absolute', right: '20px', top: '10px'}} icon={<Print />}/>
			</Desktop>*/}
			{ (props.traffic.trafficByItems.length) ? 
			<Table style={{maxWidth: '100%'}}>
				<TableHead>
					<TableRow>
						<TableCell padding="dense" style={{width: '0px', paddingLeft: 0}} numeric className="no-print hidden-xs">
							<TableSortLabel onClick={()=>{props.onSortClick(0)}} direction={props.sortDir} active={props.sortBy==0}>Kód</TableSortLabel>
						</TableCell>
						<TableCell padding="dense">
							<TableSortLabel onClick={()=>{props.onSortClick(1)}} direction={props.sortDir} active={props.sortBy==1}>Név</TableSortLabel>
						</TableCell>
						<TableCell padding="dense" style={{width: '100px'}} numeric>
							<TableSortLabel onClick={()=>{props.onSortClick(2)}} direction={props.sortDir} active={props.sortBy==2}>Mennyiség</TableSortLabel>
						</TableCell>
						<TableCell padding="dense" className="hidden-xs print-only table-cell" style={{width: '100px', display: (props.isMobile) ? 'none' : 'table-cell'}} numeric>
							<TableSortLabel onClick={()=>{props.onSortClick(3)}} direction={props.sortDir} active={props.sortBy==3}>Egységár</TableSortLabel>
						</TableCell>
						{/*
						<TableCell padding="dense" className="hidden-xs no-print" numeric style={{width: '100px', display: (props.isMobile) ? 'none' : 'table-cell'}}>
							<TableSortLabel onClick={()=>{props.onSortClick(4)}} direction={props.sortDir} active={props.sortBy==4}>Kedvezmény</TableSortLabel>
						</TableCell>
						*/}
						<TableCell padding="dense" className="hidden-xs" numeric style={{width: '120px', display: (props.isMobile) ? 'none' : 'table-cell'}}>
							<TableSortLabel onClick={()=>{props.onSortClick(5)}} direction={props.sortDir} active={props.sortBy==5}>Össz. ár</TableSortLabel>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.traffic.trafficByItems.sort(sorter).map((item, key)=>{
						return (
							<TableRow key={key} className="print-tr-condensed">
								<TableCell padding="dense" numeric style={{width: '30px'}} className="no-print hidden-xs">{item.menuCardItem.id}</TableCell>
								<TableCell padding="dense" style={{maxWidth: '140px'}}><Typography noWrap>{item.menuCardItem.name}</Typography></TableCell>
								<TableCell padding="dense" numeric style={{width: '100px'}}>{item.quantity}</TableCell>
								<TableCell padding="dense" numeric style={{width: '100px', display: (props.isMobile) ? 'none' : 'table-cell'}} className="hidden-xs print-only table-cell">{localize(item.menuCardItem.price)} Ft</TableCell>
								{/*<TableCell padding="dense" numeric style={{width: '100px', display: (props.isMobile) ? 'none' : 'table-cell'}} className="no-print hidden-xs">{localize(item.discount)} Ft</TableCell>*/}
								<TableCell padding="dense" numeric style={{width: '110px', display: (props.isMobile) ? 'none' : 'table-cell'}} className="hidden-xs">{localize(item.sumPrice)} Ft</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</Table> :
			<Typography variant="subtitle1" color="textSecondary" align="center" style={{marginTop: '20px'}}>Nincs megjeleníthető elem</Typography>
			} 
		</Paper>
	)
}

const mapStateToProps = (state)=>{
	return {
		statistics: state.statistics,
		statisticsIds: state.statisticsIds,
		users: state.users
	}
}


const Statistics = connect(mapStateToProps)(_Statistics)

export default Statistics
