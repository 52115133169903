import React from 'react'
import { connect } from 'react-redux'
import { fetchProfile, pushSubscribe, pushUnsubscribe, twoFAEnable, twoFAVerify, twoFADisable } from '../actions'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { withRouter } from 'react-router-dom';
import QRCode from 'qrcode'

import Person from '@material-ui/icons/AccountCircle';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ForkDialog from '../components/ForkDialog';
//import translator from '../translator'
//const _t = translator('USERS')

class _Profile extends React.Component{
	constructor(props) {
		super(props)
		this.state = {
			pushSubscription: null,
			pushDisabled: false,
			pushEnabled: false,
			pushError: null,
			twoFAEnabled: false,
			twoFADisabled: false,
			dataUrl: null,
			token: '',
			twoFAVerifyError: false,
			twoFASuccess: false,
		}
		this.props.dispatch(fetchProfile())
	}

	urlB64ToUint8Array(base64String){
		const padding = '='.repeat((4 - base64String.length % 4) % 4);
		const base64 = (base64String + padding) // eslint-disable-next-line
			.replace(/\-/g, '+') // eslint-disable-next-line
			.replace(/_/g, '/');

		const rawData = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}
		return outputArray;
	}

	subscribePush(){
		this.setState({pushDisabled: true, pushEnabled: true})
		if ('serviceWorker' in navigator){
			navigator.serviceWorker.getRegistration().then(registration=>{
				registration.pushManager.getSubscription().then(subscription=>{
					if (!subscription){
						const applicationServerKey = this.urlB64ToUint8Array(this.props.preferences.pushPublicKey)
						registration.pushManager.subscribe({
							userVisibleOnly: true,
							applicationServerKey
						}).then(subscription=>{
							this.props.dispatch(pushSubscribe(subscription, this.props.profile.uniqueId)).then(()=>{
								this.setState({pushSubscription: subscription, pushDisabled: false, pushEnabled: true})	
							}).catch(error=>{
								this.setState({pushDisabled: false, pushEnabled: false, pushError: 'FETCH'})
								console.log(error)	
							})
						}).catch(error=>{
							this.setState({pushDisabled: false, pushEnabled: false, pushError: 'SUBSCRIBE'})
							console.log(error)
						})
					}
				}).catch(error=>{
					setTimeout(()=>{
						this.setState({pushDisabled: false, pushEnabled: false, pushError: 'SUBSCRIPTION'})
					}, 400)
					console.log(error)
				})
			}).catch(error=>{
				setTimeout(()=>{
					this.setState({pushDisabled: false, pushEnabled: false, pushError: 'SERVICE_WORKER'})
				}, 400)
				console.log(error)
			})
		} else {
			setTimeout(()=>{
				this.setState({pushDisabled: false, pushEnabled: false, pushError: 'SERVICE_WORKER'})
			}, 400)
		}
	}
	unsubscribePush(){
		this.props.dispatch(pushUnsubscribe(this.props.profile.uniqueId)).then(()=>{
			navigator.serviceWorker.getRegistration().then(registration=>{
				registration.pushManager.getSubscription().then(subscription=>{
					if (subscription){
						subscription.unsubscribe().then(()=>{
							this.setState({pushSubscription: null, pushDisabled: false, pushEnabled: false})
						})
					}
				}).catch(error=>{
					console.log(error)
					this.setState({pushDisabled: false, pushEnabled: false})				
				})
			}).catch(error=>{
				console.log(error)
				this.setState({pushDisabled: false, pushEnabled: false})
			})
		})
	}

	componentDidMount(){
		if ('serviceWorker' in navigator){
			navigator.serviceWorker.getRegistration().then(registration=>{
				registration.pushManager.getSubscription().then(subscription=>{
					this.setState({pushSubscription: subscription, pushEnabled: Boolean(subscription)})
				})
			})	
		} else {
			//this.unsubscribePush()
		}
	}

	pushToggle(toggled){
		this.setState({pushError: null})
		if (toggled){
			this.subscribePush()
		} else {
			this.unsubscribePush()
		}
	}

	twoFAToggle(toggled){
		if (toggled){
			this.setState({twoFAEnabled: true, twoFADisabled: true, twoFAVerifyError: false})
			this.props.dispatch(twoFAEnable()).then(()=>{
				QRCode.toDataURL(this.props.profile.twoFAUrl, {}, (err, url)=>{
					if (err) console.log(err)
					this.setState({dataUrl: url})
				})
			})
		} else {
			this.setState({twoFAEnabled: false, twoFADisabled: true, twoFAVerifyError: false})
			this.props.dispatch(twoFADisable()).then(()=>{
				this.setState({twoFADisabled: false})
			})
		}
	}

	twoFAVerify(){
		this.props.dispatch(twoFAVerify(this.state.token)).then(()=>{
			if (this.props.profile.twoFAVerified){
				this.setState({twoFASuccess: true, dataUrl: null, twoFAEnabled: true, twoFADisabled: false})
			} else {
				this.setState({twoFAVerifyError: true, token: ''})
				this.state.tokenInput.focus()
			}
		})
	}

	componentWillReceiveProps(nextProps){
		if (nextProps.profile.twoFactorEnabled) this.setState({twoFAEnabled: true})
	}

	render(){
		return (
			<div>
				<ForkDialog
					open={Boolean(this.state.dataUrl)}
					action={(yes)=>{
						if (yes) this.twoFAVerify()
						else this.setState({dataUrl: null, twoFAEnabled: false, twoFADisabled: false})

					}}
					noLabel="Mégsem"
					yesLabel="Ellenőrzés"
					title="Kétfaktoros hitelesítés beállítása">
					<Typography>Szkennelje be...</Typography>
					<img src={this.state.dataUrl} alt="QR code" style={{display: 'block', margin: 'auto'}}/>
					<div style={{margin: 'auto', display: 'block', width: '180px'}}>
						<TextField
							value={this.state.token}
							onKeyDown={(e)=>{if (e.key=='Enter') this.twoFAVerify()}}
							onChange={(e)=>{this.setState({token: e.target.value, twoFAVerifyError: false})}}
							label="Kód"
							inputRef={(i)=>{this.setState({tokenInput: i})}}
							helperText={(this.state.twoFAVerifyError) ? 'Helytelen kód. Próbálja újra' : ''}
							error={this.state.twoFAVerifyError}
							fullWidth
							autoFocus/>	
					</div>
				</ForkDialog>
				<ForkDialog
					open={this.state.twoFASuccess}
					action={()=>{this.setState({twoFASuccess: false})}}
					hideNo
					yesLabel="Rendben"
					title="Siker"
					children={"A kétfaktoros autentikáció beállítása sikeresen megtörtént."}>
				</ForkDialog>
				<Grid fluid style={{padding: '10px 10px 0 10px', maxWidth: '100%', width: '600px'}}>
					<Row>
						<Col md={12} xs={12} style={{padding: '10px 10px 0 10px'}}>
							<Paper style={{padding: '20px'}}>
								<Person style={{width: '100%', height: '150px', color: '#757575'}}/>
								<Typography variant="h4" style={{color:'#000'}} align="center">{this.props.profile.lastName} {this.props.profile.firstName}</Typography>
								<Typography variant="h5" color="textSecondary" align="center">{this.props.profile.username}</Typography>
								<Typography variant="body1" color="textSecondary" align="center">
									{this.props.profile.roleGroups.map((roleGroup, key)=>{
										return (
											<span key={key}>{(key!=0) ? "• " : ""}{roleGroup.name} </span>
										)
									})}
								</Typography>
							</Paper>
						</Col>
						<Col md={12} xs={12} style={{padding: '10px'}}>
							<Paper style={{padding: '0'}}>
								<div style={{padding: '5px 10px 0px 20px'}}>
									<FormControlLabel 
										label="Értesítések"
										control={
											<Switch
												checked={this.state.pushEnabled}
												disabled={this.state.pushDisabled}
												onChange={(e, toggled)=>this.pushToggle(toggled)}/>
										}
									/>
									
									<div style={{display: (this.state.pushError) ? 'block' : 'none', color: 'red', marginBottom: '20px'}}>Nem sikerült az értesítések engedélyezése</div>
								</div>
								<Divider />
								<div style={{padding: '5px 10px 0px 20px'}}>
									<FormControlLabel 
										label="Kétfaktoros hitelesítés"
										disabled
										control={
											<Switch
												checked={this.state.twoFAEnabled}
												disabled={this.state.twoFADisabled}
												onChange={(e, toggled)=>this.twoFAToggle(toggled)}/>
										}
									/>
								</div>
							</Paper>
						</Col>
					</Row>
				</Grid>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		profile: state.profile,
		preferences: state.preferences
	}
}
const Profile = withRouter(connect(mapStateToProps)(_Profile))

export default Profile