import React from 'react'
import { connect } from 'react-redux'

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Tooltip from '@material-ui/core/Tooltip';

import MoreVert from '@material-ui/icons/MoreVert';
import Exit from '@material-ui/icons/ExitToApp';

import { primary, accent } from '../Theme'
import { logout, toggleDrawer } from '../actions'
import translator from '../translator'
const _t = translator('APPBAR')

class _ForkAppBar extends React.Component {
	constructor(props) {
		super(props);
		this.toggleDrawer = this.toggleDrawer.bind(this)
		this.logout = this.logout.bind(this)
	}

	toggleDrawer(){
		this.props.dispatch(toggleDrawer(false))
	}

	logout(){
		this.props.dispatch(logout())
	}

	render(){
		let title = 'Fork' //(this.props.preferences.storeName) ? this.props.preferences.storeName : 'Fork'
		return (
			<AppBar position="fixed" className="no-print" style={{paddingRight: '0px !important'}}>
				<Toolbar>
					<IconButton color="inherit" aria-label="Menu" style={{ marginLeft: -12, marginRight: 20}} onClick={this.toggleDrawer}>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" color="inherit" style={{flex: 1}}>
						{(this.props.preferences.dev) ? <span>{title} Admin <b style={{color: '#ff5722', textShadow: '-0.5px 0 white, 0 0.5px white, 0.5px 0 white, 0 -0.5px white'}}>*DEV*</b></span> : title + ' Admin'}
					</Typography>
					<Tooltip title="Kijelentkezés" PopperProps={{style:{zIndex: 9999}}}>
						<IconButton color="inherit" aria-label="Kijelentkezés" onClick={this.logout}>
							<Exit />
						</IconButton>
					</Tooltip>
				</Toolbar>
			</AppBar>
		)
		// return (
		// 	<AppBar
		// 		className="no-print"
		// 		style={{position:'fixed', zIndex: 999, backgroundColor: (this.props.preferences.dev) ? accent : primary}}
		// 		onLeftIconButtonTouchTap={this.toggleDrawer}
		// 		zDepth={1} title={(this.props.preferences.dev) ? title + ' Dev' : title + ' Admin'}
		// 		iconElementRight={<AppBarOptions dispatch={this.props.dispatch} />}>
		// 	</AppBar>
		// )
	}
}

// class AppBarOptions extends React.Component{
// 	constructor(props) {
// 		super(props);
// 		this.logout = this.logout.bind(this)
// 	}

// 	logout(){
// 		this.props.dispatch(logout())
// 	}

// 	render(){
// 		return (
// 			<IconMenu
// 				style={{zIndex:1000}}
// 				iconButtonElement={<IconButton><MoreVert color="white" /></IconButton>}
// 				anchorOrigin={{horizontal: 'right', vertical: 'top'}}
// 				targetOrigin={{horizontal: 'right', vertical: 'top'}}
// 			>
// 				{/*
// 				<MenuItem rightIcon={<Refresh />}>{_t("REFRESH")}</MenuItem>
// 				<MenuItem rightIcon={<Help />}>{_t("HELP")}</MenuItem>
// 				<MenuItem rightIcon={<Feedback />}>{_t("FEEDBACK")}</MenuItem>
// 				<Divider />
// 				*/}
// 				<MenuItem onTouchTap={this.logout} rightIcon={<Exit />}>{_t("LOGOUT")}</MenuItem>
// 			</IconMenu>
// 		)
// 	}
// }

const ForkAppBar = connect((state)=>{
	return {
		preferences: state.preferences
	}
})(_ForkAppBar)

export default ForkAppBar