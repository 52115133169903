import React from 'react'
import { connect } from 'react-redux'
import { fetchCustomers, alterCustomer, newCustomer, deleteCustomer, fetchDiscounts } from '../actions'

import translator from '../translator'
import { withRouter } from 'react-router-dom';

import ForkPlainTable from '../components/ForkPlainTable'

const _t = translator('CUSTOMERS')

class _Customers extends React.Component{
	constructor(props) {
		super(props)
		this.state = {doneLoading: false, fields: []}
		Promise.all([
			this.props.dispatch(fetchCustomers()),
			this.props.dispatch(fetchDiscounts())
		]).then(() => {
			this.setState({
				fields: [
					{title:_t("NAME"), field:'name'},
					{title:_t("DISCOUNT"), field: 'discountId', type: 'select', value:'id', text:'name', nullable: true, options: this.props.discounts},
					{title:_t("EMAIL"), field:'email', required: false},
					{title:_t("PHONE"), field:'phone', required: false},
					{title:_t("ZIP"), field:'zip', required: false},
					{title:_t("CITY"), field:'city', required: false},
					{title:_t("STREET"), field:'street', required: false, editOnly: true},
					{title:_t("ADDRESS"), field:'address', required: false, editOnly: true},
					{title:_t("ADDRESS2"), field:'address2', required: false, editOnly: true},
					{title:_t("MESSAGE"), field:'message', required: false, editOnly: true},
					{title:_t("BILLINGNAME"), field:'billingName', required: false, editOnly: true},
					{title:_t("BILLINGZIP"), field:'billingZip', required: false, editOnly: true},
					{title:_t("BILLINGCITY"), field:'billingCity', required: false, editOnly: true},
					{title:_t("BILLINGSTREET"), field:'billingStreet', required: false, editOnly: true},
					{title:_t("BILLINGADDRESS"), field:'billingAddress', required: false, editOnly: true},
					{title:_t("BILLINGADDRESS2"), field:'billingAddress2', required: false, editOnly: true},
				],
				doneLoading: true
			})
		})
		this.save = this.save.bind(this)
		this.delete = this.delete.bind(this)
	}
	save(item, newItem){
		let promise
		if (newItem) promise = this.props.dispatch(newCustomer(item))
		if (!newItem) promise = this.props.dispatch(alterCustomer(item))
		promise.then(()=>{
			this.props.dispatch(fetchCustomers())
		})
		return promise
	}
	delete(id){
		return this.props.dispatch(deleteCustomer(id)).then(()=>{
			this.props.dispatch(fetchCustomers())
		})
	}
	render(){
		return (
			<div>
				<ForkPlainTable 
					dataSource={this.props.customers}
					fields={this.state.fields}
					name={_t("CUSTOMER")}
					save={this.save}
					delete={this.delete}
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		customers: state.customers,
		discounts: state.discounts
	}
}
const Customers = withRouter(connect(mapStateToProps)(_Customers))

export default Customers