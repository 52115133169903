import React from 'react'
import { connect } from 'react-redux'
import { fetchRoleFunctions, alterRoleFunction } from '../actions'

import translator from '../translator'
import { withRouter } from 'react-router-dom';

import ForkPlainTable from '../components/ForkPlainTable'

const _t = translator('ROLES')

class _RoleFunctions extends React.Component{
	constructor(props) {
		super(props)
		this.state = {
			fields: [
				{title:_t("DESCRIPTION"), field:'description', required: false, seeOnly: true},
				{title:_t("NAME"), field:'name', required: false, seeOnly: true},
				{
					title:_t("AUTHENTICATIONREQUIRED"),
					field:'authRequired',
					type:"boolean", switch: true,
					required: false,
					dependency: true
				},
				{title:_t("WITHPIN"), field:'withPin', type:"boolean", required: false, hideIf: ({authRequired})=>!authRequired},
				/* {title:_t("WITHPASSWORD"), field:'withPassword', type:"boolean", required: false, hideIf: ({authRequired})=>!authRequired} */
			]
		}
		this.props.dispatch(fetchRoleFunctions())
		this.save = this.save.bind(this)
		this.delete = this.delete.bind(this)
	}
	save(item, newItem){
		let promise
		item.withoutAuthentication = !item.authRequired
		if (newItem) promise = Promise.resolve()
		if (!newItem) promise = this.props.dispatch(alterRoleFunction(item))
		promise.then(()=>{
			this.props.dispatch(fetchRoleFunctions())
		})
		return promise
	}
	delete(id){
		return
	}
	render(){
		return (
			<div>
				<ForkPlainTable 
					dataSource={this.props.roleFunctions.map(i=>{return {...i, authRequired: !i.withoutAuthentication}})}
					fields={this.state.fields}
					name={_t("ROLEFUNCTION")}
					save={this.save}
					delete={this.delete}
					disableAdd
					disableDelete
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		roleFunctions: state.roleFunctions,
	}
}
const RoleFunctions = withRouter(connect(mapStateToProps)(_RoleFunctions))

export default RoleFunctions