import React from 'react'
import { connect } from 'react-redux'
import { fetchUsers, alterUser, newUser, deleteUser, fetchRoleGroups } from '../actions'

import translator from '../translator'
import { withRouter } from 'react-router-dom';

import ForkPlainTable from '../components/ForkPlainTable'

const _t = translator('USERS')

class _Users extends React.Component{
	constructor(props) {
		super(props)
		this.state = {fields: []}
		Promise.all([this.props.dispatch(fetchUsers()), this.props.dispatch(fetchRoleGroups())
			]).then(()=>{
				this.setState({fields: [
					{title:_t("USERNAME"), field:'username', disableModify: true},
					{title:_t("LASTNAME"), field:'lastName', required: false, hiddenXs:true},
					{title:_t("FIRSTNAME"), field:'firstName', required: true},
					{title:_t("EMAIL"), field:'email', required: false, hiddenXs:true},
					{title:_t("ROLEGROUPS"), field:'roleGroups', required: false, editOnly: true, type: 'select', value:'id', text:'name', options: this.props.roleGroups, multiple: true, chips: true},
					{title:_t("PIN"), field:'pin', required: false, editOnly: true, type: 'password', autoComplete: "off"},
					{title:_t("NEWPASSWORD"), field:'new_password', type: 'password', editOnly: true, required: false, autoComplete:"new-password"},
					{title:_t("NEWPASSWORD_AGAIN"), field:'new_password_again', type: 'password', editOnly: true, required: false, autoComplete:"new-password"}
				]})
			})
		
		
		this.save = this.save.bind(this)
		this.delete = this.delete.bind(this)
	}
	save(item, newItem){
		let promise
		if ((item.new_password == '' || item.new_password_again == '') && newItem) return Promise.reject(_t("EMPTY_PASSWORD"))
		if (item.new_password != item.new_password_again) return Promise.reject(_t("PASSWORDS_DO_NOT_MATCH"))
		if (item.new_password != '') item.newPassword = item.new_password
		if (newItem) promise = this.props.dispatch(newUser(item))
		if (!newItem) promise = this.props.dispatch(alterUser(item))
		promise.then(()=>{
			this.props.dispatch(fetchUsers())
		})
		return promise
	}
	delete(id){
		return this.props.dispatch(deleteUser(id)).then(()=>{
			this.props.dispatch(fetchUsers())
		})
	}
	render(){
		return (
			<div>
				<ForkPlainTable 
					dataSource={this.props.users}
					fields={this.state.fields}
					name={_t("USER")}
					save={this.save}
					delete={this.delete}
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		users: state.users,
		roleGroups: state.roleGroups
	}
}
const Users = withRouter(connect(mapStateToProps)(_Users))

export default Users