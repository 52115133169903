import React from 'react'
import { connect } from 'react-redux'
import { 
	fetchMenuCard, 
	fetchMenuCardCategories, 
	newMenuCardCategory,
	alterMenuCardCategory,
	deleteMenuCardCategory,
	fetchUnits, 
	fetchVatKeys, 
	fetchServingLocations, 
	alterMenuCardItem, 
	newMenuCardItem,
	deleteMenuCardItem
} from '../actions'

import ContentAdd from '@material-ui/icons/Add';
import { withRouter } from 'react-router-dom';	
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { primary } from '../Theme.js'
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Delete from '@material-ui/icons/Delete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ForkDialog from '../components/ForkDialog'
import Checkbox from '@material-ui/core/Checkbox'
import Toolbar from '@material-ui/core/Toolbar'
import Print from '@material-ui/icons/Print'
import Search from '@material-ui/icons/Search'

import fuzzy from 'fuzzy'

import { connectScreenSize } from 'react-screen-size'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';

//import translator from '../translator'
//const _t = translator('MENUCARD')


class _MenuCard extends React.Component{
	constructor(props) {
		super(props);
		this.state = {scrollTop: 0, newItem: false}
		this.rowTouchTap = this.rowTouchTap.bind(this)
		this.cancelEditing = this.cancelEditing.bind(this)
		this.newItem = this.newItem.bind(this)
	}
	rowTouchTap(itemId){
		if (itemId == this.state.itemId) return this.cancelEditing()
		let item = this.props.menuCard.find((i)=>{return itemId==i.id})
		let lastValue = this.state.newItem
		this.setState({item: false, itemId: false, newItem: false}, ()=>{
			setTimeout(()=>{
				this.setState({scrollTop: 0, item, itemId})	
			}, (lastValue) ? 300 : 0)
		})
		
	}
	cancelEditing(e){
		this.setState({item: false, itemId: false, newItem: false})
	}
	newItem(){
		let lastValue = this.state.newItem || this.state.item
		this.setState({item: false, itemId: false, newItem: false}, ()=>{
			setTimeout(()=>{
				this.setState({newItem: true})
			}, (lastValue) ? 300 : 0)
		})
	}

	render(){
		return (
			<div>
				<MenuCardTable 
					isMobile={this.props.isMobile}
					dispatch={this.props.dispatch}
					scrollTop={this.state.scrollTop}
					rowTouchTap={this.rowTouchTap}
					menuCardCategories={this.props.menuCardCategories}
					menuCard={this.props.menuCard}
					selectedItem={this.state.item}
					/>
				<EditMenuCardItem
					refresh={this.refreshEdit}
					cancel={this.cancelEditing}
					item={this.state.item}
					dispatch={this.props.dispatch}
					units={this.props.units}
					vatKeys={this.props.vatKeys}
					servingLocations={this.props.servingLocations}
					newItem={this.state.newItem}
					preferences={this.props.preferences}
					menuCardCategories={this.props.menuCardCategories}/>
				<Button variant="fab" color="secondary" className="no-print" style={{position: 'fixed', right: (this.state.item) ? 450 : 50, bottom: 50}} onClick={this.newItem}>
					<ContentAdd />
				</Button>
			</div>
		)
	}
}

class EditMenuCardItem extends React.Component {
	constructor(props) {
		super(props);
		this.item = false
		this.save = this.save.bind(this)
		this.state = 
					{
						code: '',
						unit: null,
						vatKey1: null,
						vatKey2: null,
						servingLocation: null,
						name: '',
						price: '',
						menuCardCategory: null,
						error: false,
						isFood: true,
						isFoodString: "true",
						isExtra: false,
						showDeleteDialog: false
					}
	}
	componentWillReceiveProps(nextProps) {
		document.getElementById('mc-scrollable').scrollTop = 0
		if (nextProps.item && this.props.item && nextProps.item.id == this.props.item.id) return
		if (nextProps.newItem){
			this.setState({
				code: '',
				unit: null,
				vatKey1: this.props.preferences.defaultFoodVatKey1Id,
				servingLocation: this.props.preferences.defaultFoodServingLocationId,
				menuCardCategory: null,
				vatKey2: this.props.preferences.defaultFoodVatKey2Id,
				name: '',
				price: '',
				error: false,
				isFood: true,
				isFoodString: "true",
				isExtra: false
			})
		}
		if (nextProps.item) this.setState({
			code: nextProps.item.code,
			unit: nextProps.item.unit.id,
			menuCardCategory: nextProps.item.menuCardCategory.id,
			vatKey1: nextProps.item.vatKey1.id,
			vatKey2: nextProps.item.vatKey2.id,
			name: nextProps.item.name,
			price: nextProps.item.price,
			servingLocation: nextProps.item.servingLocation.id,
			isFood: nextProps.item.isFood,
			isFoodString: nextProps.item.isFood ? "true" : "false",
			isExtra: nextProps.item.isExtra,
			error: false
		})
	}
	changeIsFood(value){
		this.setState({
			isFood: value,
			isFoodString: (value) ? "true" : "false",
			vatKey1: (value) ? this.props.preferences.defaultFoodVatKey1Id : this.props.preferences.defaultDrinkVatKey1Id,
			servingLocation: (value) ? this.props.preferences.defaultFoodServingLocationId : this.props.preferences.defaultDrinkServingLocationId,
			vatKey2: (value) ? this.props.preferences.defaultFoodVatKey2Id : this.props.preferences.defaultDrinkVatKey2Id
		})
	}
	save(){
		let alteredItem = {
			id: (this.props.item) ? this.props.item.id : undefined,
			code: this.state.code,
			name: this.state.name,
			price: this.state.price,
			unitId: this.state.unit,
			vatKey1Id: this.state.vatKey1,
			vatKey2Id: this.state.vatKey2,
			menuCardCategoryId: this.state.menuCardCategory,
			servingLocationId: this.state.servingLocation,
			isFood: this.state.isFood,
			isExtra: this.state.isExtra
		}
		let promise = (this.props.item) ? this.props.dispatch(alterMenuCardItem(alteredItem)) : this.props.dispatch(newMenuCardItem(alteredItem))

		promise.then((action)=>{
			if (action.type == "FETCH_NEW_MENUCARDITEM_FAIL"){
				return this.setState({error: true})
			}
			this.props.dispatch(fetchMenuCard())
			this.props.cancel()
		})
	}
	deleteDialog(){
		this.setState({showDeleteDialog: true})
	}
	delete(doDelete){
		this.setState({showDeleteDialog: false})	
		if (!doDelete) return
		this.props.dispatch(deleteMenuCardItem(this.props.item.id)).then(()=>{
			this.props.cancel()
			this.props.dispatch(fetchMenuCard())
		})
	}
	render(){
		return (
			<Paper id="mc-scrollable" elevation={4} style={{zIndex: 200,width: '400px', maxWidth: '100vw', position: 'fixed', overflow:'scroll', right: (this.props.item || this.props.newItem) ? 0 : -410, top: '64px', backgroundColor: '#fff', height: '100%', 'transition': '0.1s ease-in-out'}}>
				<ForkDialog title="Valóban törölni szeretné az elemet?" yesLabel="Törlés" noLabel="Mégsem" open={this.state.showDeleteDialog} action={(doDelete)=>{this.delete(doDelete)}}/>
				<div style={{padding: '20px 30px'}}>
				<h1 style={{fontWeight: 300, color: primary}}>
					{(this.props.newItem) ? 'Termék hozzáadása' : 'Termék szerkesztése'}
				</h1>
				{/*<div style={{height: '48px', marginTop: '10px', display: (this.props.newItem) ? 'none' : 'block'}}>
					<Button variant="contained" color="primary" onClick={()=>{this.deleteDialog()}} style={{float: 'right'}} children="Elem törlése" icon={<Delete />}/>
				</div>*/}
				<h1 style={{fontWeight: 400, color: 'red', fontSize: (this.state.error) ? '20px' : '0px', transition: '.2s'}}>
					Hiba történt
				</h1>
				<TextField type="number" fullWidth label="Kód" autoFocus onFocus={(e)=>{if(!this.props.isMobile) e.target.select()}} style={{display: 'block', width: '100%'}} onChange={(e)=>{this.setState({code: e.target.value})}} value={this.state.code}/>
				{/* ?????? */}
				{(Boolean(this.state.name)||true) ? <TextField fullWidth label="Név" onFocus={(e)=>{if(!this.props.isMobile) e.target.select()}} style={{display: 'block', width: '100%', marginTop: '10px'}} onChange={(e)=>{this.setState({name: e.target.value})}} value={this.state.name}/> : null}
				{/* ?????? */}
				<FormControl fullWidth style={{marginTop: '18px'}}>
					<InputLabel shrink={Boolean(this.state.menuCardCategory)}>Kategória</InputLabel>
					<Select
						value={this.state.menuCardCategory}
						onChange={(e)=>{this.setState({menuCardCategory: e.target.value})}}
					>
						{
							this.props.menuCardCategories.map((mcc, key)=>{
								return <MenuItem key={key} value={mcc.id} children={mcc.name} />
							})
						}
					</Select>
				</FormControl>
				<TextField 
					label="Ár"
					fullWidth
					style={{marginTop: '18px'}}
					onFocus={(e)=>{e.target.select()}}
					onChange={(e)=>{this.setState({price: e.target.value})}}
					type="number"
					InputProps={{
						endAdornment: <InputAdornment position="end">Ft</InputAdornment>
					}}
					inputProps={{style:{textAlign: 'right'}}}
					value={this.state.price}/>
				<FormControl fullWidth style={{marginTop: '18px'}}>
					<InputLabel shrink={Boolean(this.state.unit)}>Mértékegység</InputLabel>
					<Select
						value={this.state.unit}
						onChange={(e)=>{this.setState({unit: e.target.value})}}
					>
						{
							this.props.units.map((unit, key)=>{
								return <MenuItem key={key} value={unit.id} children={unit.name} />
							})
						}
					</Select>
				</FormControl>
				<RadioGroup name="isFood" style={{marginTop: '20px'}} value={this.state.isFoodString} onChange={(e)=>{this.changeIsFood((e.target.value=="true"))}}>
					<FormControlLabel value={"true"} control={<Radio />} label="Étel" style={{}}/>
					<FormControlLabel value={"false"} control={<Radio />} label="Ital/Egyéb" style={{marginTop: '-20px', marginBottom: 0}}/>
				</RadioGroup>
				<FormControl fullWidth style={{marginTop: '18px'}}>
					<InputLabel shrink={Boolean(this.state.servingLocation)}>Kiszolgálási hely</InputLabel>
					<Select
						value={this.state.servingLocation}
						onChange={(e)=>{this.setState({servingLocation: e.target.value})}}
					>
						{
							this.props.servingLocations.map((servingLocation, key)=>{
								return <MenuItem key={key} value={servingLocation.id} children={servingLocation.name} />
							})
						}
					</Select>
				</FormControl>
				<FormControl fullWidth style={{marginTop: '18px'}}>
					<InputLabel shrink={this.state.vatKey1}>ÁFA helyben</InputLabel>
					<Select
						value={this.state.vatKey1}
						onChange={(e)=>{this.setState({vatKey1: e.target.value})}}
					>
						{
							this.props.vatKeys.map((vatKey, key)=>{
								return <MenuItem key={key} value={vatKey.id} children={vatKey.name + " (" + (vatKey.value*100) + "%)"} />
							})
						}
					</Select>
				</FormControl>
				<FormControl fullWidth style={{marginTop: '18px'}}>
					<InputLabel shrink={this.state.vatKey2}>ÁFA elvitelre</InputLabel>
					<Select
						value={this.state.vatKey2}
						onChange={(e)=>{this.setState({vatKey2: e.target.value})}}
					>
						{
							this.props.vatKeys.map((vatKey, key)=>{
								return <MenuItem key={key} value={vatKey.id} children={vatKey.name + " (" + (vatKey.value*100) + "%)"} />
							})
						}
					</Select>
				</FormControl>
				<FormControl fullWidth style={{marginTop: '18px', marginBottom: '-5px'}}>
					<FormControlLabel
						control={
							<Checkbox
								checked={this.state.isExtra}
								onChange={(e)=>{this.setState({isExtra: e.target.checked})}}
							/>
							}
						label="Kiegészítő"
					/>
				</FormControl>
				<div style={{height: '130px'}}/>
				<div style={{display: 'block', backgroundColor: '#e8e8e8', position: 'fixed', right: (this.props.item || this.props.newItem) ? 0 : -410, 'transition': '0.1s ease-in-out', bottom: 0, width: (this.props.isMobile) ? '100%' : '400px'}}>
					<div style={{padding: '20px'}}>
						<Button variant="text" onClick={this.props.cancel} style={{marginRight: '15px'}} children={'Elvetés'} />
						{
							(!this.props.newItem) ? <Button variant="contained" color="primary" onClick={()=>{this.deleteDialog()}} style={{}} children={'Elem törlése'} icon={<Delete />} />
							: null
						}
						<Button variant="contained" color="secondary" onClick={this.save} style={{position: 'absolute', right: (this.props.isMobile) ? '20px' : '40px'}} children={'Mentés'} />
					</div>
				</div>
				{/*
				<Button variant="text"  onClick={this.props.cancel} style={{float: 'left', marginTop: '20px'}} children="Elvetés" />
				<Button variant="contained" color="secondary" onClick={this.save} style={{float: 'right', marginTop: '20px'}} children="Mentés" />
				<div style={{height: '120px'}}/>
				*/}
				</div>
			</Paper>
		)
	}
}

class MenuCardTable extends React.PureComponent{
	constructor(props) {
		super(props);
		this.state = {
			selectedCategory: null,
			search: "",
			newMccName: "",
			newMccOpen: false,
			alterMcc: false,
			alterMccId: null,
			printing: false
		}
		this.mccRefs = {}
		this.openCategory = this.openCategory.bind(this)
	}
	componentDidMount() {
		this.props.dispatch(fetchMenuCardCategories())
		this.props.dispatch(fetchMenuCard())
		this.props.dispatch(fetchUnits())
		this.props.dispatch(fetchVatKeys())
		this.props.dispatch(fetchServingLocations())
		document.body.scrollTop = this.props.scrollTop
	}
	openCategory(mccId){
		this.setState({selectedCategory: (mccId == this.state.selectedCategory) ? null : mccId})
		//scrollToComponent(this.mccRefs[mccId])
	}
	saveNewMcc(){
		let promise = (this.state.alterMcc) ? this.props.dispatch(alterMenuCardCategory(this.state.alterMccId, {name: this.state.newMccName})) : this.props.dispatch(newMenuCardCategory({name: this.state.newMccName}))
		promise.then(()=>{
			this.props.dispatch(fetchMenuCardCategories())
		})
		this.setState({newMccOpen: false, newMccName: ""})
	}
	deleteMcc(id){
		this.props.dispatch(deleteMenuCardCategory(id)).then(()=>{
			this.props.dispatch(fetchMenuCardCategories())
		})
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.state.printing == true&&prevState.printing==false) {
			window.print()
			setTimeout(()=>{
				this.setState({printing: false})
				document.title = "Fork Admin"
			}, 50);
		}
	}
	render(){
		const actions = [
		      <Button
		        key={1}
		      	variant="text"
		        children="Elvetés"
		        onClick={()=>{this.setState({newMccOpen: false, newMccName: ""})}} 
		      />,
		      <Button
		        key={2}
				variant="contained"
		      	color="secondary"
		        children="Mentés"
		        style={{marginLeft: '20px'}}
		        onClick={()=>{this.saveNewMcc()}}
		      />,
		    ]
		return (
			<div style={{padding: '0px'}}>
			<Dialog onClose={()=>{this.setState({newMccOpen: false, newMccName: ""})}} open={this.state.newMccOpen}>
				<DialogTitle>{(this.state.alterMcc) ? "Étlapkategória szerkesztése" : "Étlapkategória hozzáadása"}</DialogTitle>
				<DialogContent style={{width: '400px', maxWidth: 'calc(100% - 10px)'}}>
					<TextField autoFocus style={{width: '100%'}} label="Név" value={this.state.newMccName} onChange={(e)=>{this.setState({newMccName: e.target.value})}}/>	
				</DialogContent>
				<DialogActions>{actions}</DialogActions>				
			</Dialog>
			<Toolbar style={{backgroundColor: '#eee', flexGrow: 1}}>
				<div>
					<TextField
						className="no-print"
						placeholder="Keresés"
						fullWidth={this.props.isMobile}
						style={{marginRight: '30px', backgroundColor: '#fff', paddingLeft: '8px', border: '1px solid #d6d5d5', width: '100%'}}
						value={this.state.search}
						onChange={(e, value)=>{this.setState({search: e.target.value})}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							),
							disableUnderline: true
						}}
					/>
				</div>
				<div style={{flex: 1}}></div>
				<div>
					<Button variant="contained" color="primary" className="no-print" fullWidth={this.props.isMobile} style={{marginLeft: 0, margin: '2px', padding: '0px 15px 0 10px'}} onClick={()=>{this.setState({newMccOpen: true, alterMcc: false})}}>
						<ContentAdd style={{marginRight: '5px'}}/> Kategória
					</Button>
					<Button variant="text" color="primary" className="no-print hidden-xs" style={{marginLeft: '10px', display: (this.props.isMobile) ? 'none' : 'inline-flex', padding: '0px 15px 0 10px'}} onClick={()=>{this.setState({printing: true}); document.title = "Étlap"}}>
						<Print style={{marginRight: '5px'}}/> Étlap nyomtatása
					</Button>
				</div>
			</Toolbar>
				{
					this.props.menuCardCategories.map((mcc, key)=>{
							let selected = (mcc.id == this.state.selectedCategory || this.state.search != "" || this.state.printing)
							let mccItems = this.props.menuCard.filter((i)=>{return i.menuCardCategory.id == mcc.id})
							let dataSource = (this.state.search == "")  ? mccItems : fuzzy.filter(this.state.search, mccItems, {extract: (i)=>{return i.name}}).map((f)=>{return f.original})
							if (this.state.search != "" && dataSource.length < 1) return null
							let table = (selected) ? 
								<MenuCardTableTable isMobile={this.props.isMobile} printing={this.state.printing} selectedItem={this.props.selectedItem} rowTouchTap={this.props.rowTouchTap}  dataSource={dataSource}/>
								 : null
							return (
							<div key={key} style={{width: '100%'}}>
								<div onClick={()=>{this.openCategory(mcc.id)}}
								ref={(i)=>{this.mccRefs[mcc.id] = i}}
								id={"mcc_" + mcc.id}
								style={{cursor: 'pointer', borderWidth: (this.state.printing) ? 0 : '1px 0 0 0', borderStyle: 'solid', borderColor: '#d2d2d2', width: '100%', backgroundColor: (selected) ? '#d6d5d5' : '#eaeaea', height: '50px', lineHeight: '50px'}}>
									<div style={{padding: ' 0 25px 0 25px'}}>
										{mcc.name}
										{(selected) ? (this.state.search=="") ? <ExpandLess  className="no-print" style={{float: 'right', height: '50px'}}/>:null : <ExpandMore style={{float: 'right', height: '50px'}}/>}
									</div>
								</div>
								{(/*selected*/false) ? <Button variant="contained" onClick={()=>{this.setState({newMccOpen: true, alterMcc: true, newMccName: mcc.name, alterMccId: mcc.id})}} style={{margin: '10px'}} color="primary" children="Kategória szerkesztése"  className="no-print"/> : null}
								{(selected && mccItems.length == 0) ? <Button variant="contained" onClick={()=>{this.deleteMcc(mcc.id)}} style={{margin: '10px'}} color="primary" children="Kategória törlése" className="no-print"/> : null}
								{table}
							</div>
							)
						})
				}
			</div>
		)
	}
}

const MenuCardTableTable = (props)=>{
	return (
		<Table style={{minWidth: '100%'}}>
			<TableHead>
				<TableRow className="print-tr-condensed">
					{/*<TableCell padding="checkbox" style={{width: '1px'}} className="no-print">
						<Checkbox disabled />
					</TableCell>*/}
					<TableCell style={{width: '10px'}} numeric padding="dense">Kód</TableCell>
					<TableCell padding="dense">Termék neve</TableCell>
					<TableCell style={{textAlign: 'right', width: '100px'}} padding="dense">Ár</TableCell>
					<TableCell className="no-print hidden-xs" padding="dense" style={{width: '100px', display: (props.isMobile) ? 'none' : 'table-cell'}}>Mértékegység</TableCell>
					<TableCell className="no-print hidden-xs" padding="dense" style={{width: '120px', display: (props.isMobile) ? 'none' : 'table-cell'}}>ÁFA Helyben</TableCell>
					<TableCell className="no-print hidden-xs" padding="dense" style={{width: '120px', display: (props.isMobile) ? 'none' : 'table-cell'}}>ÁFA Elvitelre</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.dataSource.map((item, key)=>{
					return (
						<TableRow className="print-tr-condensed" key={key} style={{cursor: 'pointer'}} selected={(props.selectedItem && props.selectedItem.id == item.id)} onClick={()=>{props.rowTouchTap(item.id)}}>
							{/*<TableCell padding="checkbox" className="no-print">
								<Checkbox checked={Boolean(props.selectedItem && props.selectedItem.id == item.id)} />
							</TableCell>*/}
							<TableCell numeric padding="dense">{item.code}</TableCell>
							<TableCell padding="dense">{item.name}</TableCell>
							<TableCell numeric padding="dense">{item.price} Ft</TableCell>
							<TableCell className="no-print hidden-xs" padding="dense" style={{display: (props.isMobile) ? 'none' : 'table-cell'}}>{item.unit.name}</TableCell>
							<TableCell className="no-print hidden-xs" padding="dense" style={{display: (props.isMobile) ? 'none' : 'table-cell'}}>{item.vatKey1.name} ({item.vatKey1.value * 100}%)</TableCell>
							<TableCell className="no-print hidden-xs" padding="dense" style={{display: (props.isMobile) ? 'none' : 'table-cell'}}>{item.vatKey2.name} ({item.vatKey2.value * 100}%)</TableCell>
						</TableRow>
					)
				})}
			</TableBody>
		</Table>
	)
}

const mapStateToProps = (state)=>{
	return {
		menuCard: state.menuCard,
		menuCardItem: state.menuCardItem,
		menuCardCategories: state.menuCardCategories,
		units: state.units,
		vatKeys: state.vatKeys,
		servingLocations: state.servingLocations,
		preferences: state.preferences
	}
}
const mapScreenSizeToProps = (screenSize) => {
	return {
		isMobile: screenSize['mobile'] || screenSize['small'],
		isDesktop: screenSize['> mobile']
	}
}
const MenuCard = withRouter(connectScreenSize(mapScreenSizeToProps)(connect(mapStateToProps)(_MenuCard)))

export default MenuCard