import React from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Warning from '@material-ui/icons/Warning';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';
import Check from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import socket from '../socket'
import ForkDialog from '../components/ForkDialog';
import languages from '../translator/languages.json'

import { changeLanguage, fetchStatus, fetchVatKeys, fetchServingLocations, fetchPreferences, alterPreferences, downloadUpdate, installUpdate, checkUpdate } from '../actions'
import { connectScreenSize } from 'react-screen-size'
import translator from '../translator'
import ForkLogo from '../fork-b.png'
const _t = translator('SETTINGS')
class _Settings extends React.Component{

	constructor(props) {
		super(props)
		Promise.all([
			this.props.dispatch(fetchStatus()),
			this.props.dispatch(fetchVatKeys()),
			this.props.dispatch(fetchServingLocations()),
			this.props.dispatch(fetchPreferences()),
		]).then(()=>{
			this.setState({loadedData: true})
		})
		this.state = {
			storeName: " ",
			storeCity: " ",
			storeStreet: " ",
			taxNumber: " ",
			archiveHistory: false,
			defaultFoodVatKey1Id: null,
			defaultFoodVatKey2Id: null,
			defaultDrinkVatKey1Id: null,
			defaultDrinkVatKey2Id: null,
			defaultFoodServingLocationId: null,
			defaultDrinkServingLocationId: null,
			successDialog: false,
			saveSuccessDialog: false,
			onlySendFrenchAfterInvoice: false,
			denyOtherUsersOnOrder: false,
			isFastFoodRestaurant: false,
			optionalServiceFee: "",
			updateDownloading: false,
			updateProgress: 0,
			showConfirmUpdateInstall: false,
			installingUpdate: false,
			installedUpdate: false,
			loadedData: false,
			updateAvailable: false,
			checkDisabled: false
		}
		socket.on('updateDownloadProgress', data=>{			
			console.log(data)
			if(data>this.state.updateProgress+5) this.setState({updateProgress: data})
		})
		socket.on('updateDownloadError', data=>{
			console.log(data)
			this.setState({updateError: true, updateDownloading: false})
		})
		socket.on('updateDownloadDone', data=>{
			this.setState({updateReady: true, updateDownloading: false})
		})
		socket.on('updateAvailable', data=>{
			this.setState({updateAvailable: true})
		})
		socket.on('serverReady', data=>{
			if (this.state.installingUpdate){
				this.props.dispatch(fetchStatus()).then(()=>{
					this.setState({installedUpdate: true, installingUpdate: false, installError: (this.props.status.release.version==this.state.versionBeforeUpdate) ? true : false})
				})
			}
		})
		this.changeLanguage = this.changeLanguage.bind(this)
	}


	changeLanguage(e, i, value){
		this.props.dispatch(changeLanguage(e.target.value))
	}

	saveChanges(){
		let newPrefs = {...this.state}
		newPrefs.optionalServiceFee = newPrefs.optionalServiceFee/100
		this.props.dispatch(alterPreferences(newPrefs)).then(()=>{
			this.props.dispatch(fetchPreferences())
			this.setState({saveSuccessDialog: true})
		})
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.preferences){
			this.setState({
				storeName: nextProps.preferences.storeName,
				storeCity: nextProps.preferences.storeCity,
				storeStreet: nextProps.preferences.storeStreet,
				taxNumber: nextProps.preferences.taxNumber,
				archiveHistory: Boolean(nextProps.preferences.archiveHistory),
				onlySendFrenchAfterInvoice: Boolean(nextProps.preferences.onlySendFrenchAfterInvoice),
				denyOtherUsersOnOrder: Boolean(nextProps.preferences.denyOtherUsersOnOrder),
				isFastFoodRestaurant: Boolean(nextProps.preferences.isFastFoodRestaurant),
				optionalServiceFee: nextProps.preferences.optionalServiceFee * 100
			})
		}
		if (nextProps.vatKeys && nextProps.servingLocations && nextProps.preferences){
			this.setState({
				defaultFoodVatKey1Id: nextProps.preferences.defaultFoodVatKey1Id,
				defaultFoodVatKey2Id: nextProps.preferences.defaultFoodVatKey2Id,
				defaultFoodServingLocationId: nextProps.preferences.defaultFoodServingLocationId,
				defaultDrinkVatKey1Id: nextProps.preferences.defaultDrinkVatKey1Id,
				defaultDrinkVatKey2Id: nextProps.preferences.defaultDrinkVatKey2Id,
				defaultDrinkServingLocationId: nextProps.preferences.defaultDrinkServingLocationId,
			})
		}
	}

	startDownloadUpdate(){
		this.props.dispatch(downloadUpdate())
		this.setState({updateDownloading: true, updateError: false})
	}

	checkUpdate(){
		this.setState({checkDisabled: true}, ()=>{
			this.props.dispatch(checkUpdate()).then(()=>{
				this.props.dispatch(fetchStatus()).then(()=>{
					this.setState({checkDisabled: false})
				})
			})
		})
	}

	startInstallUpdate(){
		if (this.state.showConfirmUpdateInstall){
			this.setState({versionBeforeUpdate: this.props.status.release.version}, ()=>{
				this.props.dispatch(installUpdate()).then(()=>{
					setTimeout(()=>{
						if (this.state.installingUpdate){
							this.props.dispatch(fetchStatus()).then(()=>{
								this.setState({installedUpdate: true, installingUpdate: false, installError: (this.props.status.release.version==this.state.versionBeforeUpdate) ? true : false})
							})
						}
					}, 40000)
				})
				this.setState({showConfirmUpdateInstall: false, installingUpdate: true})
			})			
		} else {
			this.setState({showConfirmUpdateInstall: true})
		}
	}

	render(){
		if (!this.props.preferences || !this.state.loadedData) return (
			<div style={{position: 'absolute', top:'50%', left: '50%', transform: 'translate(-50%, -50%)'}} >
				<CircularProgress/>
			</div>
			)
		return(
			<div>
				<ForkDialog
					open={this.state.showConfirmUpdateInstall}
					action={(choice)=>{
						if (choice) this.startInstallUpdate()
						else this.setState({showConfirmUpdateInstall: false})
					}} 
					yesLabel="Telepítés"
					noLabel="Mégsem"
					children={"A telepítés nem tart tovább néhány másodpercnél, azonban a programnak újra kell indulnia. Érdemes zárt üzlettel elvégezni a műveletet."}
					title="Telepíti a frissítést?"/>
				<ForkDialog
					open={this.state.saveSuccessDialog}
					action={()=>{this.setState({saveSuccessDialog: false})}} 
					yesLabel="Rendben"
					hideNo
					title="A beállítások mentése sikeresen megtörtént."/>
				<ForkDialog
					open={this.state.installingUpdate}
					action={()=>{}} 
					hideYes
					hideNo
					children={<LinearProgress color="secondary" style={{width: '100%'}} variant="indeterminate"/>}
					title="Frissítés telepítése..."/>
				<ForkDialog
					open={this.state.installedUpdate}
					action={()=>{window.location.reload()}} 
					hideNo
					yesLabel="Rendben"
					children={null}
					title={this.state.installError ? "A frissítést nem sikerült telepíteni." : "A frissítés sikeresen telepítve lett."}/>
				<Grid fluid style={{marginBottom: '90px', padding: '30px 10px 0 10px', maxWidth: '100%', width: '600px'}}>
					<Row>
						<Col md={12} xs={12}>
							<Typography variant="h6">Üzlet</Typography>
							<Paper style={{padding: '20px', margin: '10px 0 30px 0'}}>
								<TextField
									fullWidth
									style={{marginBottom: '15px'}}
									label={_t("STORE_NAME")}
									value={this.state.storeName}
									onChange={(e, value)=>{this.setState({storeName: e.target.value})}}/>
								<TextField
									fullWidth
									style={{marginBottom: '15px'}}
									label={_t("STORE_ADDRESS_CITY")} 
									value={this.state.storeCity} 
									onChange={(e, value)=>{this.setState({storeCity: e.target.value})}}/>
								<TextField 
									fullWidth
									style={{marginBottom: '15px'}}
									label={_t("STORE_ADDRESS_STREET")} 
									value={this.state.storeStreet} 
									onChange={(e, value)=>{this.setState({storeStreet: e.target.value})}}/>
								<TextField 
									fullWidth
									style={{marginBottom: '15px'}}
									label={_t("TAX_NUMBER")} 
									value={this.state.taxNumber} 
									onChange={(e, value)=>{this.setState({taxNumber: e.target.value})}}/>
								<FormControlLabel style={{marginBottom: '0px'}} control={
									<Checkbox 
										fullWidth
										style={{}}
										checked={this.state.isFastFoodRestaurant} 
										onChange={(e, value)=>{this.setState({isFastFoodRestaurant: value})}}/>
								} label={_t("IS_FAST_FOOD_RESTAURANT")}/>

								
							</Paper>
							
						</Col>
						<Col md={12} xs={12}>
							
							<Typography variant="h6">Preferenciák</Typography>
							<Paper style={{padding: '10px 20px 20px 20px', margin: '10px 0 30px 0'}}>
								<FormControlLabel fullWidth control={
									<Checkbox 
									checked={!this.state.archiveHistory} 
									onChange={(e, value)=>{this.setState({archiveHistory: !value})}}/>
								} label={_t("DELETEHISTORY")}/>
								<br/>
								<FormControlLabel fullWidth control={
									<Checkbox 
									checked={this.state.denyOtherUsersOnOrder} 
									onChange={(e, value)=>{this.setState({denyOtherUsersOnOrder: value})}}/>
								} label={_t("DENYOTHERUSERSONORDER")}/>
								<br/>
								{/* <FormControlLabel style={{marginTop: '-10px'}} fullWidth control={
									<Checkbox 
									checked={this.state.onlySendFrenchAfterInvoice} 
									onChange={(e, value)=>{this.setState({onlySendFrenchAfterInvoice: value})}}/>
								} label={_t("ONLY_SEND_FRENCH_AFTER_INVOICE")}/> */}
								
								<TextField 
									type="number"
									fullWidth
									label={_t("OPTIONALSERVICEFEE")} 
									value={this.state.optionalServiceFee} 
									InputProps={{
										endAdornment: <InputAdornment position="end">%</InputAdornment>,
									}}
									helperText="Adjon meg 0%-ot az elrejtéshez"
									inputProps={{style: {textAlign: 'right'}}}
									onChange={(e)=>{this.setState({optionalServiceFee: e.target.value})}}/>
							</Paper>
							{/*<Typography variant="title">Nyelvi beállítások</Typography>
							<Paper style={{padding: '20px', margin: '10px 0 30px 0'}}>
								<FormControl fullWidth>
									<InputLabel shrink={true} htmlFor={'language-select'}>{_t("LANGUAGE")}</InputLabel>
									<Select
									 id="language-select"
									 value={this.props.language}
									 onChange={this.changeLanguage}
									>
										{
											languages.map((item, key)=>{
												return <MenuItem key={key} value={item.shortCode} children={item.name} />
											})
										}
									</Select>
								</FormControl>
							</Paper>*/}
						</Col>
						<Col md={12} xs={12}>
							<Typography variant="h6">Étlap alapértelmezései</Typography>
							<Paper style={{padding: '20px', margin: '10px 0 30px 0'}}>
								<Grid fluid style={{padding: 0}}>
									<Row>
										<Col md={6} xs={6}>
											<Typography variant="subtitle1" style={{marginBottom: '15px'}}>Étel</Typography>
											<FormControl fullWidth style={{marginBottom: '15px'}}>
												<InputLabel shrink={true}>{_t("DEFAULT_FOOD_VATKEY1")}</InputLabel>
												<Select
												 value={this.state.defaultFoodVatKey1Id}
												 onChange={(e)=>{this.setState({defaultFoodVatKey1Id: e.target.value})}}
												>
													{
														this.props.vatKeys.map((item, key)=>{
															return <MenuItem key={key} value={item.id} children={item.name + " (" + item.value*100 + "%)"} />
														})
													}
												</Select>
											</FormControl>
											<FormControl fullWidth style={{marginBottom: '15px'}}>
												<InputLabel shrink={true}>{_t("DEFAULT_FOOD_VATKEY2")}</InputLabel>
												<Select
												 value={this.state.defaultFoodVatKey2Id}
												 onChange={(e)=>{this.setState({defaultFoodVatKey2Id: e.target.value})}}
												>
													{
														this.props.vatKeys.map((item, key)=>{
															return <MenuItem key={key} value={item.id} children={item.name + " (" + item.value*100 + "%)"} />
														})
													}
												</Select>
											</FormControl>
											<FormControl fullWidth style={{marginBottom: '0'}}>
												<InputLabel shrink={true}>{_t("DEFAULT_FOOD_SERVINGLOCATION")}</InputLabel>
												<Select
												 value={this.state.defaultFoodServingLocationId}
												 onChange={(e)=>{this.setState({defaultFoodServingLocationId: e.target.value})}}
												>
													{
														this.props.servingLocations.map((item, key)=>{
															return <MenuItem key={key} value={item.id} children={item.name} />
														})
													}
												</Select>
											</FormControl>
										</Col>
										<Col md={6} xs={6}>
											<Typography variant="subtitle1" style={{marginBottom: '15px'}}>Ital/Egyéb</Typography>
											<FormControl fullWidth style={{marginBottom: '15px'}}>
												<InputLabel shrink={true}>{_t("DEFAULT_DRINK_VATKEY1")}</InputLabel>
												<Select
												 value={this.state.defaultDrinkVatKey1Id}
												 onChange={(e, i, value)=>{this.setState({defaultDrinkVatKey1Id: e.target.value})}}
												>
													{
														this.props.vatKeys.map((item, key)=>{
															return <MenuItem key={key} value={item.id} children={item.name + " (" + item.value*100 + "%)"} />
														})
													}
												</Select>
											</FormControl>
											<FormControl fullWidth style={{marginBottom: '15px'}}>
												<InputLabel shrink={true}>{_t("DEFAULT_DRINK_VATKEY2")}</InputLabel>
												<Select
												 value={this.state.defaultDrinkVatKey2Id}
												 onChange={(e)=>{this.setState({defaultDrinkVatKey2Id: e.target.value})}}
												>
													{
														this.props.vatKeys.map((item, key)=>{
															return <MenuItem key={key} value={item.id} children={item.name + " (" + item.value*100 + "%)"} />
														})
													}
												</Select>
											</FormControl>
											<FormControl fullWidth style={{marginBottom: '0'}}>
												<InputLabel shrink={true}>{_t("DEFAULT_DRINK_SERVINGLOCATION")}</InputLabel>
												<Select
												 value={this.state.defaultDrinkServingLocationId}
												 onChange={(e)=>{this.setState({defaultDrinkServingLocationId: e.target.value})}}
												>
													{
														this.props.servingLocations.map((item, key)=>{
															return <MenuItem key={key} value={item.id} children={item.name} />
														})
													}
												</Select>
											</FormControl>
										</Col>
									</Row>
								</Grid>
							</Paper>

							<Typography variant="h6">Névjegy & Frissítés</Typography>
							<Paper style={{padding: '0px', margin: '10px 0 30px 0'}}>
								<div style={{padding: '20px'}}>
									<img alt="FORK" style={{width: '110px'}} src={ForkLogo} />
									{ (this.props.status.bundled) ? 
										<div>
											{
												(this.props.status.updateAvailable || this.state.updateAvailable) ?
												<div>
													<Warning color="secondary" style={{margin: '20px 0 0 0'}}/>
													<Typography style={{margin: '-32px 0 0 30px'}} variant="subheading">{this.state.updateReady || this.props.status.updateReady ? "A frissítés készen áll a telepítésre" : "Frissítés érhető el"}</Typography>
													{/*<Typography style={{fontSize: '10px', margin: '-5px 0 0 30px'}} variant="body1">Utolsó ellenőrzés: soha</Typography>*/}
													{
														(!this.state.updateReady && !this.props.status.updateReady) ?
														<Button onClick={()=>this.startDownloadUpdate()} variant="contained" disabled={this.state.updateDownloading || this.props.status.updateDownloading} color="secondary" size="small" children={"Letöltés"} style={{float: 'right', marginTop: '-28px'}}/>
														: null
													}
													{
														(this.state.updateReady || this.props.status.updateReady) ? 
														<Button onClick={()=>this.startInstallUpdate()} variant="contained" disabled={false} color="secondary" size="small" children={"Telepítés"} style={{float: 'right', marginTop: '-28px'}}/>
														: null
													}
												</div>
												: 
												<div>
													<Check color="primary" style={{margin: '20px 0 0 0'}}/>
													<Typography style={{margin: '-36px 0 0 30px'}} variant="subheading">A Fork naprakész</Typography>
													<Typography style={{fontSize: '10px', margin: '-5px 0 0 30px'}} variant="body1">Utolsó ellenőrzés: {moment(this.props.status.updateLastChecked).fromNow()}</Typography>
													<Button onClick={()=>this.checkUpdate()} variant="contained" color="primary" disabled={this.state.checkDisabled} size="small" children={"Ellenőrzés most"} style={{float: 'right', marginTop: '-33px'}}/>
												</div>
											}
											{(this.state.updateError) ? 
												<Typography color="error" variant="body1" style={{marginTop: '26px'}}>Nem sikerült letölteni a frissítést.</Typography>
												: null
											}
											{(this.state.updateDownloading || this.props.status.updateDownloading) ? 
												<div style={{marginTop: '25px'}}>
													<Typography variant="body1">Frissítés letöltése folyamatban</Typography>
													<LinearProgress style={{}} variant="determinate" color="secondary" value={this.state.updateProgress}/>
												</div>
											 : null}
										 </div>
										 : null
									}
								</div>
								<Divider style={{margin: '0'}} />
								<div style={{padding: '20px'}}>
									<Typography>Szerver verzió: {this.props.status.serverVersion}</Typography>
									
									<Typography style={{position: 'relative'}}>
										Csomagverzió: {this.props.status.release['release-date']} - {this.props.status.release['release-version']}
										<Tooltip placement="right" title={"" + this.props.status.release['comment']}>
												<Info style={{fontSize: '17px', position: 'absolute', marginLeft: '5px'}}/>
										</Tooltip>
									</Typography>
									<Typography></Typography>
									<Typography>Adatbázis: v{this.props.status.dbVersion.version} {this.props.status.dbVersion.name} </Typography>
									<Typography>Könyvtár: "{this.props.status.rootPath}"</Typography>
									{(this.props.status.error) ? 
									[<Typography color="error" key={0}>Hiba:</Typography>,
									<Typography color="error" key={1}>{this.props.status.stacktrace}</Typography>] : null}
								</div>
								<Divider style={{margin: '0'}} />
								<div style={{padding: '20px'}}>
									<Typography style={{marginTop: '0', fontSize: '12px'}} variant="body1">Fork Éttermi Program © 2018</Typography>
									<Typography style={{fontSize: '12px'}} variant="body1"><a href="mailto:info@fork.hu">info@fork.hu</a></Typography>
								</div>
							</Paper>
						</Col>
					</Row>
				</Grid>
				<div style={{width: (this.props.isMobile) ? '100vw' : 'calc(100vw - ' + this.props.drawerWidth + 'px)', transition:'0.3s', height: '80px', backgroundColor:'#eee', zIndex: 999, position:'fixed', bottom: 0}}>
					<Button variant="contained" color="secondary" children="Változások mentése" onClick={()=>{this.saveChanges()}} style={{margin: '20px', position:'absolute', right: '20px'}}/>
				</div>
				

				
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		language: state.language,
		preferences: state.preferences,
		vatKeys: state.vatKeys,
		servingLocations: state.servingLocations,
		drawerWidth: state.drawer.width,
		status: state.status
	}
}
const mapScreenSizeToProps = (screenSize) => {
	return {
		isMobile: screenSize['mobile'],
		isDesktop: screenSize['> mobile']
	}
}
const Settings = connectScreenSize(mapScreenSizeToProps)(connect(mapStateToProps)(_Settings))

export default Settings
