
import React from 'react'

import Button from '@material-ui/core/Button';
import ContentAdd from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import { primary } from '../Theme.js'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import { connectScreenSize } from 'react-screen-size'
import { Grid, Row, Col } from 'react-flexbox-grid'
import ForkDialog from './ForkDialog'
import translator from '../translator'
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import fuzzy from 'fuzzy'

const _t = translator('TABLE')

/*

FIELD PROPERTIES:
	{
		title: string|Readable name of the field
		field: string|property name in data
		disableModify: bool|Disable altering value, initial value still addable, still show a disabled input field in edit mode
		seeOnly: bool|Not alterable, still show a disabled input field
		inTableOnly: bool|No input field at all, only table column
		editOnly: bool|Only input field, no table column
		hideOnNew: bool|Only show field in edit mode
		hiddenXs: bool|Hide table column on small devices
		required: bool
		type: string|["boolean", "text", "select", "pair"]
		options: array|only on type 'select'|select data
		value: string|only on type 'select'|data key from options
		text: string|only on type 'select'|data readable value from options
		nullable: bool|only on type 'select'
		multiple: bool|only on type 'select'
		left|right: obj|only on type 'pair'|{title, options, value, text, sourceValue}
	}

*/

class _ForkPlainTable extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			selectedItem: null,
			open:false,
			newItem: false,
			errors: {},
			showDeleteDialog: false,
			promiseError: {error: true, message: ''},
			selectFieldValues: {},
			selectFieldStates: {},
			pairValues: {},
			dependencies: {},
			groups: this.props.group ? this.props.groupBy.data.map(i=>i[this.props.groupBy.value]) : [null]
		}
		this.newItem = this.newItem.bind(this)
	}
	rowTouchTap(id){
		let wasNewItem = this.state.newItem
		let lastValue = this.state.selectedItem
		this.setState({pairValues: {}, selectedItem: null, open: false, newItem: false, errors: {}, promiseError: {error: false, message:''}}, ()=>{
			let selectFieldValues = {}
			let selectFieldStates = {}
			let pairValues = {}
			let dependencies = {}
			let item = this.props.dataSource.find(i=>{return i.id == id})
			this.props.fields.map(field=>{
				if (field.type=='select' || field.type=='radio'){
					if (field.multiple){
						selectFieldValues[field.field] = (item) ? item[field.field].map(i=>{return i[field.value]}) : []
					} else {
						selectFieldValues[field.field] = (item) ? (item[field.field]==null ? "null*null*null" :String(item[field.field])) : "null*null*null"
					}
					selectFieldStates[field.field] = false
				}
				if (field.type=='pair' && item){
					pairValues[field.field] = item[field.field].concat([])
				}
				if (field.dependency) dependencies[field.field] = item[field.field]
			})
			if (wasNewItem && lastValue != id){
				return setTimeout(()=>{
					this.setState({selectedItem: id, open: true, selectFieldValues, selectFieldStates, pairValues, dependencies})
				}, 300)
			}
			if (lastValue != id) return this.setState({selectedItem: id, open: true, selectFieldStates, selectFieldValues, pairValues, dependencies})
			this.setState({selectedItem: null, open: false, selectFieldValues: {}, pairValues: {}, dependencies: {}})
		})
	}
	save(){
		let alteredItem = {}
		let error = false
		let tempErrors = {}
		this.setState({errors: {}, promiseError: {error: false, message:''}})
		this.props.fields.map(field=>{
			if (field.seeOnly||(field.hideOnNew && this.state.newItem)||field.inTableOnly||(field.hideIf && field.hideIf(this.state.dependencies))) return
			if (field.type == 'boolean') {
				alteredItem[field.field] = this.refs[field.field].checked
				return 
			} else if (field.type == 'select' || field.type == 'radio'){
				if (!field.nullable && field.required!="false" && (this.state.selectFieldValues[field.field]=="null*null*null" || !this.state.selectFieldValues[field.field])){
					error = true
					tempErrors[field.field] = true
				} else {
					alteredItem[field.field] = (this.state.selectFieldValues[field.field]=="null*null*null") ? null : this.state.selectFieldValues[field.field]
					return
				}
			} else if (field.type == 'pair'){
				let arr = []
				this.state.pairValues[field.field].map(row=>{
					let obj = {}
					obj[field.left.sourceValue] = row[field.left.sourceValue]
					obj[field.right.sourceValue] = row[field.right.sourceValue]
					if (!row[field.left.sourceValue] && !row[field.right.sourceValue]) return
					arr.push(obj)
				})
				alteredItem[field.field] = arr
				return
			} else {
				if (this.refs[field.field] && this.refs[field.field].input.value == "" && field.required != false) {
					error = true
					tempErrors[field.field] = true
				}
				alteredItem[field.field] = this.refs[field.field].input.value
			}
		})
		if (error) return this.setState({errors: tempErrors})
		if (!this.state.newItem) alteredItem.id = this.state.selectedItem
		this.props.save(alteredItem, this.state.newItem).then(()=>{
			if (this.state.newItem && !this.props.isMobile) return this.newItem()
			this.rowTouchTap(this.state.selectedItem)
		}).catch(err=>{
			this.setState({promiseError: {error: true, message: err}})
		})
	}
	deleteDialog(){
		this.setState({showDeleteDialog: true})
	}
	delete(doDelete){
		this.setState({showDeleteDialog: false})
		if (!doDelete) return
		this.props.delete(this.state.selectedItem).then(()=>{
			this.rowTouchTap(this.state.selectedItem)
		})
	}
	updateFromOnChange(newValues){
		if (typeof newValues != 'object') return
		let selectFieldValues = this.state.selectFieldValues

		Object.keys(newValues).map(newFieldName=>{
			let newValue = newValues[newFieldName]
			let field = this.props.fields.find(f=>f.field==newFieldName)
			if (field.type=='select' || field.type=='radio'){	
				selectFieldValues[field.field] = String(newValue)
			}
		})
		
		this.setState({selectFieldValues})
	}

	newItem(){
		let lastValue = this.state.open
		let selectFieldValues = this.state.selectFieldValues
		let pairValues = this.state.pairValues
		this.props.fields.filter(f=>!f.noClear).map(field=>{
			if (field.type=='select'){
				if (field.multiple){
					selectFieldValues[field.field] = []
				} else {
					selectFieldValues[field.field] = null
				}
			}
			else if (field.type=='pair'){
				pairValues[field.field] = []
			} else {
				let i = this.refs[field.field]
				if (i && i.input) i.input.value=null
			}
		})
		this.setState({selectedItem: null, open: false, errors: {}, pairValues, selectFieldValues}, ()=>{
			setTimeout(()=>{

				this.setState({newItem: true, open: true}, ()=>{
					let fieldNames = this.props.fields.map(f=>f.field)
					let firstInput = this.refs[Object.keys(this.refs).filter(k=>fieldNames.includes(k))[0]]					
					if (firstInput && firstInput.input && !this.props.isMobile) firstInput.input.focus()
				})
			}, (lastValue) ? 300 : 0)
		})
	}
	render(){
		return (
			<div className="no-print" style={{paddingRight: (this.state.open) ? (this.props.isMobile) ? '0' : '400px' : 0, transition: '0.2s ease-out'}}>
				<ForkDialog yesLabel="Törlés" noLabel="Mégsem" title="Valóban törölni szeretné az elemet?" open={this.state.showDeleteDialog} action={(doDelete)=>{this.delete(doDelete)}}/>
				<Button variant="fab" color="secondary" onClick={this.newItem} aria-label="Hozzáadás" style={{paddingTop: '3px', position: 'fixed', right: (this.state.open && !this.state.newItem) ? 450 : 50, bottom: 50 + (this.props.fabOffset || 0), display:(this.props.disableAdd) ? 'none' : 'inherit', transition: '0.2s ease-out'}}>
					<ContentAdd />
				</Button>
				{/*<FloatingActionButton secondary style={{position: 'fixed', right: (this.state.open && !this.state.newItem) ? 450 : 50, bottom: 50 + (this.props.fabOffset || 0), display:(this.props.disableAdd) ? 'none' : 'inherit'}} onTouchTap={this.newItem}>
					<ContentAdd />
				</FloatingActionButton>*/}
				<Paper
				ref="formPaper"
				onKeyDown={(e)=>{
					if(e.key=='Enter') {
						this.save()
					} else if(e.key=='Escape'){
						if (Object.values(this.state.selectFieldStates).includes(true)){

						} else {
							this.rowTouchTap(this.state.selectedItem)	
						}
					}
				}}
				elevation={4}
				style={{zIndex: 200, width: (this.props.isMobile) ? '100%' : '400px', maxWidth: '100vw', padding: '0', position: 'fixed', overflow:'scroll', right: (this.state.open) ? 0 : (this.props.isMobile) ? '-100vw' : -410, top: (this.props.isMobile) ? '56px' : '64px', backgroundColor: '#fff', height: '100%', transition: '0.2s ease-out'}}>
					<div style={{padding: '20px 30px'}}>
					<h1 style={{fontWeight: 300, color: primary}}>
						{(this.state.newItem) ? (<span>{_t("ADDPREFIX")} {this.props.name} {_t("ADDPOSTFIX")}</span>) : 
												(<span>{_t("EDITPREFIX")} {this.props.name} {_t("EDITPOSTFIX")}</span>)
						}
					</h1>

					{/*
						(!this.state.newItem && !this.props.disableDelete && this.state.open) ? <div style={{height: '48px'}}><Button variant="contained" color="primary" onClick={()=>{this.deleteDialog()}} style={{float: 'right'}} children={_t("DELETE")} icon={<Delete />} /></div>
						: null
					*/}
					<h1 style={{fontWeight: 400, color: 'red', fontSize: (this.state.promiseError.error) ? '20px' : '0px', transition: '.2s'}}>
						{this.state.promiseError.message}
					</h1>
					{
						(this.state.selectedItem || this.state.newItem) ? 
							<div>
								{this.props.fields.map((field, key)=>{
									if(field.inTableOnly||(field.hideOnNew&&this.state.newItem)||(field.hideIf && field.hideIf(this.state.dependencies))) return null
									let item = {}
									let label = (field.required == false) ? field.title : <span>{field.title}*</span>
									if (!this.state.newItem) item = this.props.dataSource.find(i=>{return i.id == this.state.selectedItem})
									if (field.type=='boolean'){
										if (field.switch){
											return (
												<div key={key}>
													<FormControlLabel style={{marginTop: '10px', marginBottom: '0px'}} key={key} control={
														<Switch 
															key={key}
															inputRef={(i)=>{this.refs[field.field]=i}}
															defaultChecked={(this.state.newItem) ? false : Boolean(item[field.field])}
															onChange={(e)=>{
																let dependencies = this.state.dependencies
																dependencies[field.field] = e.target.checked
																this.setState({dependencies})
															}}
														/>
													} label={label}/>
												</div>
											)	
										}
										return (
											<div key={key}>
												<FormControlLabel style={{marginTop: '10px', marginBottom: '0px'}} key={key} control={
													<Checkbox
														key={key}
														inputRef={(i)=>{this.refs[field.field]=i}}
														defaultChecked={(this.state.newItem) ? false : Boolean(item[field.field])}
													/>	
												} label={label}/>
											</div>
										)
									}
									
									if (field.type=='radio'){
										return (
											<FormControl key={key} component="fieldset" style={{margin: '15px 0 -15px 0'}}>
												{field.title ? <FormLabel component="legend">{label}</FormLabel> : null}
												<RadioGroup
													value={this.state.selectFieldValues[field.field]}
													onChange={
													(field.onChange) ? 
													(e)=>this.updateFromOnChange(field.onChange(e.target.value))
													:
													(e)=>{
														let selectFieldValues = {...this.state.selectFieldValues}
														selectFieldValues[field.field] = e.target.value
														let errors = {...this.state.errors}
														errors[field.field] = false
														this.setState({selectFieldValues, errors})
													}}
												>
													{
														field.options.map((option, key)=>{
															return <FormControlLabel key={key} value={String(option.value)} control={<Radio style={{padding: '3px 12px'}} />} label={option.text} />
														})
													}
												</RadioGroup>
											</FormControl>
										)
									}
									if (field.type=='select'){
										return (
											<div key={key}>
												<FormControl error={this.state.errors[field.field]} fullWidth style={{marginTop: '18px'}}>
													<InputLabel shrink={Boolean(this.state.selectFieldValues[field.field]||field.nullable)} htmlFor={field.field}>{label}</InputLabel>
													<Select
														inputProps={{id:field.field}}
														multiple={field.multiple}
														key={key}
														value={(this.state.selectFieldValues[field.field])?this.state.selectFieldValues[field.field]:"null*null*null"}
														onChange={(e, i, value)=>{
															let selectFieldValues = {...this.state.selectFieldValues}
															selectFieldValues[field.field] = e.target.value
															let errors = {...this.state.errors}
															errors[field.field] = false
															this.setState({selectFieldValues, errors})
														}}
														fullWidth
														open={this.state.selectFieldStates[field.field]}
														onOpen={()=>{
															let selectFieldStates = {...this.state.selectFieldStates}
															selectFieldStates[field.field] = true
															this.setState({selectFieldStates})
														}}
														onClose={()=>{
															let selectFieldStates = {...this.state.selectFieldStates}
															selectFieldStates[field.field] = false
															this.setState({selectFieldStates})
														}}
														renderValue={(field.chips) ? selected => {
															//if(!) return selected
															if (!selected) return <div><Chip label={'Nincs'} style={{marginRight: '5px'}}/></div>
															return(
																<div>
																	{selected.map(value => {
																		return <Chip key={value} label={field.options.find(o=>o[field.value]==value)[field.text]} style={{marginRight: '5px'}}/>
																	})}
																</div>
															)	
														} : null}
													>
														{field.nullable ? <MenuItem value={"null*null*null"} children="Nincs" /> : null}
														{field.options.map((option, key)=>{

															return <MenuItem
																key={key}
																value={option[field.value]}
																checked={(field.mutiple) ? this.state.selectFieldValues[field.field].indexOf(option[field.value]) > -1 : false}>
																	{(field.multiple) ? <Checkbox checked={this.state.selectFieldValues[field.field].indexOf(option[field.value]) > -1} /> : null}
																	{option[field.text]}
																</MenuItem>
														})}

													</Select>
													{(this.state.errors[field.field] ? <FormHelperText>Ez a mező nem maradhat üresen</FormHelperText> : null)}
												</FormControl>
											</div>
										)
									}
									if (field.type=='pair'){
										return (
											<Grid key={key} style={{padding: 0}} fluid>
												<Row>
													<Col md={12} style={{marginTop: '18px', marginBottom: '10px', fontSize: '16px'}}>{field.title}</Col>
												</Row>
												{this.state.pairValues[field.field].map((row, key2)=>{
													return (
														<Row key={key2}>
															<Col md={5}>
																<FormControl fullWidth>
																	<InputLabel shrink htmlFor={field.field+"left"}>{field.left.title}</InputLabel>
																	<Select
																		inputProps={{id:field.field+"left"}}
																		value={this.state.pairValues[field.field][key2][field.left.sourceValue]}
																		onChange={(e,i,value)=>{
																			let pairValues = {...this.state.pairValues}
																			pairValues[field.field][key2][field.left.sourceValue] = e.target.value
																			this.setState({pairValues})
																		}}
																		fullWidth>
																		{field.left.options.map((option, key3)=>{
																			return <MenuItem key={key3} value={option[field.left.value]} children={option[field.left.text]}/>
																		})}
																	</Select>
																</FormControl>
															</Col>
															<Col md={5}>
																<FormControl fullWidth>
																	<InputLabel shrink htmlFor={field.field+"right"}>{field.right.title}</InputLabel>
																	<Select
																		inputProps={{id:field.field+"right"}}
																		value={this.state.pairValues[field.field][key2][field.right.sourceValue]}
																		onChange={(e,i,value)=>{
																			let pairValues = {...this.state.pairValues}
																			pairValues[field.field][key2][field.right.sourceValue] = e.target.value
																			this.setState({pairValues})
																		}}
																		fullWidth>
																		{field.right.options.map((option, key3)=>{
																			return <MenuItem key={key3} value={option[field.right.value]} children={option[field.right.text]}/>
																		})}
																	</Select>
																</FormControl>
															</Col>
															<Col md={2}>
																<IconButton onClick={()=>{
																	let pairValues = {...this.state.pairValues}
																	pairValues[field.field] = pairValues[field.field].filter((i, index)=>{return index!=key2})
																	this.setState({pairValues})
																}} style={{marginTop: '13px'}}>
																	<Delete />
																</IconButton>
															</Col>
														</Row>
													)
												})}
												<Row>
													<Col md={2} mdOffset={10}>
														<IconButton onClick={()=>{
															let pairValues = {...this.state.pairValues}
															let newObj = {}
															newObj[field.left.sourceValue] = null
															newObj[field.right.sourceValue] = null
															pairValues[field.field].push(newObj)
															this.setState({pairValues})
														}} style={{marginTop: '0'}}>
															<ContentAdd />
														</IconButton>
													</Col>
												</Row>
											</Grid>
											
										)
									}
									return (
										<div key={key}>
											<TextField
												onChange={()=>{
													let errors = {...this.state.errors}
													errors[field.field] = false
													this.setState({errors})
												}}
												disabled={(field.disableModify && !this.state.newItem) || field.seeOnly}
												helperText={(this.state.errors[field.field] ? "Ez a mező nem maradhat üresen" : "")}
												error={this.state.errors[field.field]}
												type={(field.type) ? field.type : 'text'}
												autoComplete={(field.autoComplete) ? field.autoComplete : 'on'}
												inputRef={(i)=>{this.refs[field.field]={input: i}}}
												onFocus={(e)=>{
													if(!this.props.isMobile) e.target.select()
												}}
												label={label}
												style={{display: 'block', width: '100%', marginTop: '18px'}}
												fullWidth
												inputProps={{style:{textAlign: (field.align) ? field.align : 'left', ...field.inputProps}}}
												defaultValue={(this.state.newItem) ? '' : item[field.field]}
												autoFocus={(key==0&&!this.props.isMobile)}
												InputProps={field.unit ? {
													endAdornment: <InputAdornment position="end">{field.unit}</InputAdornment>,
												} : {}}
											/>
										</div>
									)
								})}
							</div>
						: null
					}
					
					<div style={{height: '130px'}}/>
					<div style={{display: (this.state.open) ? 'block' : 'none', backgroundColor: '#e8e8e8', position: 'fixed', right: 0, bottom: 0, width: (this.props.isMobile) ? '100%' : '400px'}}>
						<div style={{padding: '20px'}}>
						<Button variant="text" onClick={()=>{this.rowTouchTap(this.state.selectedItem)}} style={{marginRight: '15px'}} children={_t("DISCARD")} />
						{
							(!this.state.newItem && !this.props.disableDelete && this.state.open) ? <Button variant="contained" color="primary" onClick={()=>{this.deleteDialog()}} style={{}} children={(this.props.isMobile) ? _t("DELETE_SHORT") : _t("DELETE")} icon={<Delete />} />
							: null
						}
						<Button variant="contained" color="secondary" onClick={()=>{this.save()}} style={{position: 'absolute', right: (this.props.isMobile) ? '20px' : '40px'}} children={_t("SAVE")} />
						</div>
					</div>
					</div>
				</Paper>

				{
					(this.props.group ? this.props.groupBy.data.map(i=>i[this.props.groupBy.value]) : [null]).map((group, tableKey)=>{
						let selected = this.state.activeGroup == group || this.props.search
						let dataSource = this.props.search ? fuzzy.filter(this.props.search, this.props.dataSource, {extract: (i)=>{return i.name}}).map((f)=>{return f.original}) : this.props.dataSource
						dataSource = dataSource.filter(i=>(group) ? (i[this.props.groupBy.field] == group) : true)
						if (!dataSource.length && this.props.search) return null
						let table = (!this.props.group || (this.props.group && selected)) ? 
							<Table key={tableKey}>
								<TableHead>
									<TableRow>
										{
											this.props.fields.filter((i)=>{return !i.editOnly}).map((field, key)=>{
												if (field.hideOnOpen && this.state.open) return null
												return (!(field.hiddenXs && this.props.isMobile)) ? 
													<TableCell className={(field.hiddenXs) ? "hidden-xs" : ""} style={{textAlign: (field.align) ? field.align : 'left', padding: '0 13px', width: field.width ? field.width : 'auto'}} key={key}>{field.title}</TableCell>
													: null
											})
										}
									</TableRow>
								</TableHead>
								<TableBody>
									{dataSource.map((item, key)=>{
										return (
											<TableRow key={key} style={{cursor: 'pointer'}} selected={this.state.selectedItem==item.id} onClick={()=>{this.rowTouchTap(item.id)}}>
												{this.props.fields.filter((i)=>{return !i.editOnly}).map((field, key2)=>{
													if (field.hideOnOpen && this.state.open) return null
													return (!(field.hiddenXs && this.props.isMobile)) ? 
													<TableCell className={(field.hiddenXs) ? "hidden-xs" : ""} style={{textAlign: (field.align) ? field.align : 'left', padding: '0 13px'}} key={key2}>
														{(field.type=='boolean') ? 
															(item[field.field]) ? 'Igen' : 'Nem' : 
															(field.type == 'select') ?
																(field.options.find(i=>{return i[field.value] == item[field.field]})) ? field.options.find(i=>{return i[field.value] == item[field.field]})[field.text] : 'Nincs' :
																item[field.field]} {field.unit}
													</TableCell>
													: null
												})}
												
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
							: null
						
						if (!this.props.group) return table
						return (
							<div key={group} style={{width: '100%'}}>
								<div onClick={()=>{this.setState({activeGroup: (this.state.activeGroup==group) ? null : group})}}
								style={{cursor: 'pointer', borderWidth: '1px 0 0 0', borderStyle: 'solid', borderColor: '#d2d2d2', width: '100%', backgroundColor: (selected) ? '#d6d5d5' : '#eaeaea', height: '50px', lineHeight: '50px'}}>
									<div style={{padding: ' 0 25px 0 25px'}}>
										{this.props.groupBy.data.find(g=>g[this.props.groupBy.value]==group)[this.props.groupBy.name]}
										{(selected) ? ((!this.props.search) ? <ExpandLess className="no-print" style={{float: 'right', height: '50px'}}/> : null) : <ExpandMore style={{float: 'right', height: '50px'}}/>}
									</div>
								</div>
								{(selected && !dataSource.length) ? <Button variant="contained" onClick={()=>{this.props.deleteGroup(group)}} style={{margin: '10px'}} color="primary" children="Kategória törlése" className="no-print"/> : null}
								{table}
							</div>
						)
					})
				}
			</div>
		)
	}
}
const mapScreenSizeToProps = (screenSize) => {
	return {
		isMobile: screenSize['mobile'] || screenSize['small'],
		isDesktop: screenSize['> mobile']
	}
}
const ForkPlainTable = connectScreenSize(mapScreenSizeToProps)(_ForkPlainTable)
export default ForkPlainTable