import React from 'react'
import { connect } from 'react-redux'
import { fetchServingLocations, alterServingLocation, newServingLocation, deleteServingLocation } from '../actions'

import translator from '../translator'
import { withRouter } from 'react-router-dom';

import ForkPlainTable from '../components/ForkPlainTable'

const _t = translator('SERVINGLOCATIONS')

class _ServingLocations extends React.Component{
	constructor(props) {
		super(props)
		this.fields = [
			{title:_t("NAME"), field:'name'},
			{title:_t("KITCHEN"), field:'isKitchen', type:'boolean', required: false}
		]
		this.props.dispatch(fetchServingLocations())
		this.save = this.save.bind(this)
		this.delete = this.delete.bind(this)
	}
	save(item, newItem){
		let promise
		if (newItem) promise = this.props.dispatch(newServingLocation(item))
		if (!newItem) promise = this.props.dispatch(alterServingLocation(item))
		promise.then(()=>{
			this.props.dispatch(fetchServingLocations())
		})
		return promise
	}
	delete(id){
		return this.props.dispatch(deleteServingLocation(id)).then(()=>{
			this.props.dispatch(fetchServingLocations())
		})
	}
	render(){
		return (
			<div>
				<ForkPlainTable
					dataSource={this.props.servingLocations}
					fields={this.fields}
					name={_t("SERVINGLOCATION")}
					save={this.save}
					delete={this.delete}
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		servingLocations: state.servingLocations
	}
}
const ServingLocations = withRouter(connect(mapStateToProps)(_ServingLocations))

export default ServingLocations