import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { connectScreenSize } from 'react-screen-size'

import CircularProgress from '@material-ui/core/CircularProgress'
import { Route, Switch } from 'react-router-dom';
import { Mobile, Desktop } from './Responsive'
import ForkDrawer from './components/ForkDrawer'
import ForkAppBar from './components/ForkAppBar'
import ForkDialog from './components/ForkDialog'
import Content from './content'
import Login from './Login'
import Auth from './components/Auth'
import { resetFetchError, fetchStatus, logout } from './actions'

//import translator from './translator'
//const _t = translator("GENERAL")

class _ForkAdmin extends React.Component{
	
	constructor(props) {
		super(props);
		let retries = 0
		this.state = {statusTimeout: false}
		let doFetchStatus = ()=>{
			this.props.dispatch(fetchStatus()).then((action)=>{
				if((action.payload && action.payload.data && action.payload.data.error) || (action.error && action.error.data)){
					setTimeout(()=>{
						console.log(retries)
						if(retries++>15){
							this.setState({statusTimeout: true})
						} else doFetchStatus()
					}, 1500)
				}
			})
		}
		doFetchStatus()
		this.snackBarClose = this.snackBarClose.bind(this)
	}

	snackBarClose(){
		if (this.props.fetchError.invalidToken){
			this.props.dispatch(logout())	
		}
		this.props.dispatch(resetFetchError())

	}

	render(){
		let errorMessage = "Nem sikerült teljesíteni a kérést."
		if (this.props.fetchError.error && this.props.fetchError.error.error && this.props.fetchError.error.error.response){
			switch (this.props.fetchError.error.error.response.status){
				case 403:
					errorMessage = "Nincs megfelelő jogosultsága a kért művelethez."
					break
				case 404:
					errorMessage = "A kért erőforrás nem található"
					break
				case 503:
					errorMessage = "A szervernek nem sikerült elérnie a nyomtatókiszolgálót."
					break
				default:
					errorMessage = "Ismeretlen hiba történt."
					break
			}
			if (this.props.fetchError.invalidToken){
				errorMessage = "A munkamenet lejárt vagy nem érvényes. Jelentkezzen be újra."
			}
		}
		return (
			<Switch>
				<Route path="/login" exact>
					<Login />
				</Route>
				<Route>
					<Auth>
						<ForkAppBar />
							<InnerForkAdmin mobile={this.props.isMobile} status={this.props.status} timeout={this.state.statusTimeout}/>
						{/*<Mobile>
							<InnerForkAdmin mobile={true} status={this.props.status} timeout={this.state.statusTimeout}/>	
						</Mobile>						
						<Desktop>
							<InnerForkAdmin mobile={false} status={this.props.status} timeout={this.state.statusTimeout}/>
						</Desktop>
						*/}
						<ForkDialog title="Hiba történt" action={this.snackBarClose} open={(this.props.fetchError.failed && this.props.status.fetched)} hideNo yesLabel="Rendben">
							{errorMessage}
						</ForkDialog>
						
					</Auth>
				</Route>
			</Switch>
		)
	}
}

const InnerForkAdmin = (props)=>{
	return (
		<div>
			<ForkDrawer mobile={props.mobile} />
			{(props.status.fetched) ?
				(!props.status.error) ? 
					<Content mobile={props.mobile} /> : 
					<StatusErrorModal timeout={props.timeout}/> :
				<CircularProgress style={{position: 'absolute', left: '50%', top: '50%'}}/>
			}
		</div>
	)
}

const StatusErrorModal = (props)=>{
	return (
	<ForkDialog title="A Fork indítása folyamatban" action={()=>{window.location.reload()}} open={true} hideNo yesLabel="Újra" hideYes={!props.timeout}>
		<div>
			{(props.timeout) ? <span style={{color: 'red'}}>Nem sikerült kapcsolódni az adatbázishoz</span>
				:"Várakozás az adatbázisra"}
		</div>
		{
			(!props.timeout) ? 
			<div style={{position: 'relative', height: '55px'}}>
				<div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translateX(-50%)'}}>
					<CircularProgress />
				</div >
			</div>
			: null
		}
	</ForkDialog>)
}

const mapStateToProps = (state)=>{
	return {
		fetchError: state.fetchError,
		status: state.status
	}
}
const mapScreenSizeToProps = (screenSize) => {
	return {
		isMobile: screenSize['mobile'] || screenSize['small'],
		isDesktop: screenSize['> mobile']
	}
}

const ForkAdmin = withRouter(connect(mapStateToProps)(connectScreenSize(mapScreenSizeToProps)(_ForkAdmin)))
export default ForkAdmin