import React from 'react'
import { connect } from 'react-redux'
import { fetchRoleGroups, fetchRoleFunctions, newRoleGroup, alterRoleGroup } from '../actions'

import translator from '../translator'
import { withRouter } from 'react-router-dom';

import ForkPlainTable from '../components/ForkPlainTable'

const _t = translator('ROLES')

class _Roles extends React.Component{
	constructor(props) {
		super(props)
		this.state = {fields: []}
		Promise.all([this.props.dispatch(fetchRoleGroups()), this.props.dispatch(fetchRoleFunctions())]).then(()=>{
				this.setState({fields: [
					{title:_t("NAME"), field:'name'},
					{
						title:_t("ROLES"),
						type:'select',
						chips: true,
						field: 'roleFunctions',
						multiple: true,
						options: this.props.roleFunctions.map(rF=>{
							let a = {...rF, description: (rF.description) ? rF.description : rF.name}
							if (a.withoutAuthentication) a.description += " (Hitelesítés nem szükséges!)"
							return a
						}),
						value:'id',
						text:'description',
						editOnly: true},
				]})
			})
		
		
		this.save = this.save.bind(this)
		this.delete = this.delete.bind(this)
	}
	save(item, newItem){
		let promise
		if (newItem) promise = this.props.dispatch(newRoleGroup(item))
		if (!newItem) promise = this.props.dispatch(alterRoleGroup(item))
		promise.then(()=>{
			this.props.dispatch(fetchRoleGroups())
		})
		return promise
	}
	delete(id){
		
	}
	render(){
		return (
			<div>
				<ForkPlainTable 
					dataSource={this.props.roleGroups}
					fields={this.state.fields}
					name={_t("ROLEGROUP")}
					save={this.save}
					delete={this.delete}
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		roleGroups: state.roleGroups,
		roleFunctions: state.roleFunctions,
	}
}
const Roles = withRouter(connect(mapStateToProps)(_Roles))

export default Roles