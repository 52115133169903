import React from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

const _Auth = (props)=>{
	return (props.authed) ? (<div>{props.children}</div>) : (<Redirect to="/login" />)
}

const mapStateToProps = (state)=>{
	return {
		authed: state.auth.authed
	}
}

const Auth = connect(mapStateToProps)(_Auth)

export default Auth