import React from 'react'
import { connect } from 'react-redux'
import { fetchPrinterServers, alterPrinterServer, newPrinterServer, deletePrinterServer } from '../actions'

import translator from '../translator'
import { withRouter } from 'react-router-dom';

import ForkPlainTable from '../components/ForkPlainTable'

const _t = translator('PRINTERS')

class _PrinterServers extends React.Component{
	constructor(props) {
		super(props)
		this.fields = [
			{title:_t("DESCRIPTION"), field:'name'},
			{title:_t("IPADDRESS"), field:'ipAddress', required: false, seeOnly: true, hideOnNew: true},
			{title:_t("PORT"), field:'port', required: false, seeOnly: true, hideOnNew: true}
		]
		this.props.dispatch(fetchPrinterServers())
		this.save = this.save.bind(this)
		this.delete = this.delete.bind(this)
	}
	save(item, newItem){
		let promise
		if (newItem) promise = this.props.dispatch(newPrinterServer(item))
		if (!newItem) promise = this.props.dispatch(alterPrinterServer(item))
		promise.then(()=>{
			this.props.dispatch(fetchPrinterServers())
		})
		return promise
	}
	delete(id){
		return this.props.dispatch(deletePrinterServer(id)).then(()=>{
			this.props.dispatch(fetchPrinterServers())
		})
	}
	render(){
		return (
			<div>
				<ForkPlainTable
					dataSource={this.props.printerServers}
					fields={this.fields}
					name={_t("PRINTERSERVER")}
					save={this.save}
					delete={this.delete}
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		printerServers: state.printerServers
	}
}
const PrinterServers = withRouter(connect(mapStateToProps)(_PrinterServers))

export default PrinterServers