import React from 'react'
import { connect } from 'react-redux'

import { 
	fetchMenuCard, 
	fetchMenuCardCategories, 
	newMenuCardCategory,
	alterMenuCardCategory,
	deleteMenuCardCategory,
	fetchUnits, 
	fetchVatKeys, 
	fetchServingLocations, 
	alterMenuCardItem, 
	newMenuCardItem,
	deleteMenuCardItem
} from '../actions'

import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search'
import ContentAdd from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print'

import ForkPlainTable from '../components/ForkPlainTable'
import ForkDialog from '../components/ForkDialog'

import translator from '../translator'
const _t = translator('MENUCARD')


class _PlainMenuCard extends React.Component{
	constructor(props) {
		super(props)
		this.state = {doneLoading: false, search: '', newMccName: '', newMccOpen: false}
		this.refreshMenuCard()
		this.save = this.save.bind(this)
		this.delete = this.delete.bind(this)
		this.refreshMenuCard = this.refreshMenuCard.bind(this)
	}
	refreshMenuCard(){
		Promise.all([
			this.props.dispatch(fetchMenuCardCategories()),
			this.props.dispatch(fetchMenuCard()),
			this.props.dispatch(fetchUnits()),
			this.props.dispatch(fetchVatKeys()),
			this.props.dispatch(fetchServingLocations()),
		]).then(()=>{
			this.setState({doneLoading: true,
			fields: [
							{title:_t("CODE"), field:'id', inTableOnly: true, width: '10px'},
							{title:_t("NAME"), field:'name'},
							{title:_t("PRICE"), field:'price', unit: 'Ft', align: 'right', type: 'number', width: '140px'},
							{
								title:_t("MENUCARDCATEGORY"),
								field:'menuCardCategoryId',
								type: 'select',
								value:'id',
								text:'name',
								options: this.props.menuCardCategories,
								required: true,
								nullable: false,
								editOnly: true,
								noClear: true,
							},
							{
								title:_t("UNIT"),
								field:'unitId',
								type: 'select',
								value:'id',
								text:'name',
								options: this.props.units,
								required: true,
								nullable: false,
								width: '140px',
								noClear: true,
							},
							{
								title:_t("SERVINGLOCATION"),
								field:'servingLocationId',
								type: 'select',
								value:'id',
								text:'name',
								options: this.props.servingLocations,
								required: true,
								nullable: false,
								editOnly: true,
								noClear: true,
							},
							{
								title:_t("VATKEY1"),
								field:'vatKey1Id',
								type: 'select',
								value:'id',
								text:'friendlyName',
								options: this.props.vatKeys,
								required: true,
								nullable: false,
								width: '140px',
								noClear: true,
							},
							{
								title:_t("VATKEY2"),
								field:'vatKey2Id',
								type: 'select',
								value:'id',
								text:'friendlyName',
								options: this.props.vatKeys,
								required: true,
								nullable: false,
								width: '140px',
								noClear: true,
							},
							{
								title:_t("ISEXTRA"),
								field:'isExtra',
								type: 'boolean',
								required: false,
								noClear: true,
							},
					]})
		})
	}
	save(item, newItem){
		let promise
		if (newItem) promise = this.props.dispatch(newMenuCardItem(item))
		if (!newItem) promise = this.props.dispatch(alterMenuCardItem(item))
		promise.then(()=>{
			this.props.dispatch(fetchMenuCard())
		})
		return promise
	}
	delete(id){
		return this.props.dispatch(deleteMenuCardItem(id)).then(()=>{
			this.refreshMenuCard()
		})
	}
	render(){
		if (!this.state.doneLoading) return null
		return (
			<div>
				<ForkDialog
					open={this.state.newMccOpen}
					action={(act)=>{
						this.setState({newMccOpen: false})
						if (act){
							this.props.dispatch(newMenuCardCategory({name: this.state.newMccName})).then(()=>{
								this.refreshMenuCard()
							})
						}
					}}
					title="Étlapkategória hozzáadása"
					noLabel="Mégsem"
					yesLabel="Mentés"
				>
					<TextField autoFocus fullWidth label="Név" value={this.state.newMccName} onChange={(e)=>{this.setState({newMccName: e.target.value})}}/>	
				</ForkDialog>
				<Toolbar style={{backgroundColor: '#eee', flexGrow: 1}}>
					<div>
						<TextField
							className="no-print"
							placeholder="Keresés"
							fullWidth={this.props.isMobile}
							style={{marginRight: '30px', backgroundColor: '#fff', paddingLeft: '8px', border: '1px solid #d6d5d5', width: '100%'}}
							value={this.state.search}
							onChange={(e, value)=>{this.setState({search: e.target.value})}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Search />
									</InputAdornment>
								),
								disableUnderline: true
							}}
						/>
					</div>
					<div style={{flex: 1}}></div>
					<div>
						<Button variant="contained" color="primary" className="no-print" fullWidth={this.props.isMobile} style={{marginLeft: 0, margin: '2px', padding: '0px 15px 0 10px'}} onClick={()=>{this.setState({newMccOpen: true})}}>
							<ContentAdd style={{marginRight: '5px'}}/> Kategória
						</Button>
						<Button variant="text" color="primary" className="no-print hidden-xs" style={{marginLeft: '10px', display: (this.props.isMobile) ? 'none' : 'inline-flex', padding: '0px 15px 0 10px'}} onClick={()=>{this.setState({printing: true}); document.title = "Étlap"}}>
							<Print style={{marginRight: '5px'}}/> Étlap nyomtatása
						</Button>
					</div>
				</Toolbar>
				<ForkPlainTable
					dataSource={this.props.menuCard}
					fields={this.state.fields}
					name={"Termék"}
					group
					search={this.state.search}
					groupBy={{
						field: "menuCardCategoryId",
						value: "id",
						name: "name",
						data: [...this.props.menuCardCategories]
					}}
					enableGroupDelete
					deleteGroup={(id)=>{
						this.props.dispatch(deleteMenuCardCategory(id)).then(()=>{
							this.refreshMenuCard()
						})
					}}
					save={this.save}
					delete={this.delete}
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		menuCard: state.menuCard,
		menuCardItem: state.menuCardItem,
		menuCardCategories: state.menuCardCategories,
		units: state.units,
		vatKeys: state.vatKeys.map(k=>{return {...k, friendlyName: k.name + " (" + k.value*100 + "%)"}}),
		servingLocations: state.servingLocations,
		preferences: state.preferences
	}
}

const PlainMenuCard = connect(mapStateToProps)(_PlainMenuCard)

export default PlainMenuCard