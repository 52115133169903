import React from 'react'
import { connect } from 'react-redux'

import { fetchPrinters, fetchServingLocations, fetchTerminals, alterTerminal, newTerminal, deleteTerminal } from '../actions'
import ForkPlainTable from '../components/ForkPlainTable'

import translator from '../translator'
const _t = translator('TERMINALS')


class _Terminals extends React.Component{
	constructor(props) {
		super(props)
		this.state = {doneLoading: false, fields: []}
		Promise.all([
			this.props.dispatch(fetchTerminals()),
			this.props.dispatch(fetchPrinters()),
			this.props.dispatch(fetchServingLocations())
		]).then(()=>{
			this.setState({fields: [
				{title:_t("NAME"), field:'name'},
				/*{title:_t("IPADDRESS"), field: 'ipAddress', disableModify: true, required: false, hiddenXs:true, seeOnly: false, inTableOnly: false},*/
				{title:_t("IPADDRESS"), field: 'ipAddress', hideOnNew: true, disableModify: true, hiddenXs:true, required: false},
				{title:_t("ISFASTFOODTERMINAL"), field: 'isFastFoodTerminal', type:'boolean', required: false},
				{title:_t("INVOICEPRINTER"), field: 'invoicePrinterId', type: 'select', value:'id', text:'description', nullable: true, options: this.props.printers, hiddenXs:true},
				{
					title:_t("FRENCHPRINTERS"),
					field: 'servingLocationPrinters',
					type: 'pair',
					editOnly: true,
					left: {title: 'Kiszolgálási hely', options: this.props.servingLocations, value: 'id', text: 'name', sourceValue: 'servingLocationId'},
					right: {title: 'Nyomtató', options: this.props.printers, value: 'id', text: 'description', sourceValue: 'printerId'}
				}
			], doneLoading: true})
		})
		this.save = this.save.bind(this)
		this.delete = this.delete.bind(this)
	}
	save(item, newItem){
		let promise
		if (newItem) promise = this.props.dispatch(newTerminal(item))
		if (!newItem) promise = this.props.dispatch(alterTerminal(item))
		promise.then(()=>{
			this.props.dispatch(fetchTerminals())
		})
		return promise
	}
	delete(id){
		return this.props.dispatch(deleteTerminal(id)).then(()=>{
			this.props.dispatch(fetchTerminals())
		})
	}
	render(){
		if (!this.state.doneLoading) return null
		return (
			<div>
				<ForkPlainTable
					disableAdd={false && Object.keys(this.props.terminals).length>=4}
					dataSource={[...this.props.terminals]}
					fields={this.state.fields}
					name={_t("TERMINAL")}
					save={this.save}
					delete={this.delete}
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		terminals: state.terminals,
		printers: state.printers,
		servingLocations: state.servingLocations
	}
}

const Terminals = connect(mapStateToProps)(_Terminals)

export default Terminals