import React from 'react'
import { connect } from 'react-redux'

var languages = {}
var languagesSrc = require('./languages.json')

for (let lan in languagesSrc){
	languages[languagesSrc[lan].shortCode] = require('./translations/' + languagesSrc[lan].shortCode + '.json')
}

const _T = (props)=>{
	let translation = languages[props.language][props.category][props.children]
	let text = (typeof translation != 'undefined') ? translation : '[' + props.children + ']'
	return (
		<span>{text}</span>
	)
}

const mapStateToProps = (state)=>{
	return {
		language: state.language
	}
}
const T = connect(mapStateToProps)(_T)

const translator = (category)=>{
	return (raw)=>{
		return (<T category={category}>{raw}</T>)	
	}
	
}

//let _t = require('./'+language+'.json')	
export default translator