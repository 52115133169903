import React from 'react'
import { connect } from 'react-redux'
import { fetchPrinters, alterPrinter, newPrinter, deletePrinter, fetchPrinterServers, alterPrinterServer, newPrinterServer, deletePrinterServer } from '../actions'
import translator from '../translator'
import { withRouter } from 'react-router-dom';

import ForkPlainTable from '../components/ForkPlainTable'

const _t = translator('PRINTERS')

class _Printers extends React.Component{
	constructor(props) {
		super(props)
		this.state = {printersFields: [], printerServersFields: []}
		
		Promise.all([this.props.dispatch(fetchPrinterServers()), this.props.dispatch(fetchPrinters())]).then(()=>{
			this.setState({
				printersFields: [
					{title:_t("DESCRIPTION"), field:'description'},
					{title:_t("NAME"), field:'name', required: false, hiddenXs: true},
					{title:_t("PRINTERSERVER"), field:'printerServerId', type: 'select', value:'id', text:'name', options: this.props.printerServers, required: false, nullable: true, hiddenXs: true},
					{title:_t("ISDIGITAL"), field:'isDigital', type:'boolean', required: false, hiddenXs: true},
					{title:_t("KICKDRAWER"), field:'kickDrawer', type:'boolean', required: false, hiddenXs: true}
				]
			})
		})

		this.printerSave = this.printerSave.bind(this)
		this.printerDelete = this.printerDelete.bind(this)
	}
	printerSave(item, newItem){
		let promise
		if (newItem) promise = this.props.dispatch(newPrinter(item))
		if (!newItem) promise = this.props.dispatch(alterPrinter(item))
		promise.then(()=>{
			this.props.dispatch(fetchPrinters())
		})
		return promise
	}
	printerDelete(id){
		return this.props.dispatch(deletePrinter(id)).then(()=>{
			this.props.dispatch(fetchPrinters())
		})
	}
	render(){
		return (
			<div>
				<ForkPlainTable
					dataSource={this.props.printers}
					fields={this.state.printersFields}
					name={_t("PRINTER")}
					save={this.printerSave}
					delete={this.printerDelete}
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		printers: state.printers,
		printerServers: state.printerServers
	}
}
const Printers = withRouter(connect(mapStateToProps)(_Printers))

export default Printers