
import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import Home from './Home'
import MenuCard from './MenuCard'
import PlainMenuCard from './PlainMenuCard'
import Terminals from './Terminals'
import Units from './Units'
import Discounts from './Discounts'
import VatKeys from './VatKeys'
import Tables from './Tables'
import ServingLocations from './ServingLocations'
import Printers from './Printers'
import PrinterServers from './PrinterServers'
import Roles from './Roles'
import RoleFunctions from './RoleFunctions'
import Users from './Users'
import Profile from './Profile'
import Settings from './Settings'
import Customers from './Customers'
import Statistics from './Statistics'
import { fetchPreferences } from '../actions'

//Order
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import translator from '../translator'
const _t = translator('NOTFOUND')

const progress = {
	position:'fixed',
	left: 'calc(50% - 25px)',
	top: 'calc(50% - 25px)'
}

class _Content extends React.Component{
	constructor(props) {
		super(props);
		this.props.dispatch(fetchPreferences())
	}
	render(){
		let loader = (Object.keys(this.props.fetching).length) ? <CircularProgress style={progress}/> : null
		return (
			<div id="mainContent" style={{marginLeft: (this.props.mobile) ? 0 : this.props.width, transition:'0.3s', position:'relative', height: '100vh'}}>
				<div style={{padding: (this.props.mobile) ? '56px 0 0 0' : '64px 0 0 0'}}>
					{ loader }
					<Switch>
						<Redirect exact from="/" to="/home"/>
						<Route path="/home" component={Home}/>
						<Route path="/statistics" component={Statistics}/>
						<Route path="/menu" component={MenuCard}/>
						<Route path="/plainmenu" component={PlainMenuCard}/>
						<Route path="/units" component={Units}/>
						<Route path="/discounts" component={Discounts}/>
						<Route path="/terminals" component={Terminals}/>
						<Route path="/vat-keys" component={VatKeys}/>
						<Route path="/tables" component={Tables}/>
						<Route path="/serving-locations" component={ServingLocations}/>
						<Route path="/printers" component={Printers}/>
						<Route path="/printer-servers" component={PrinterServers}/>
						<Route path="/users" component={Users}/>
						<Route path="/roles" component={Roles}/>
						<Route path="/role-functions" component={RoleFunctions}/>
						<Route path="/profile" component={Profile}/>
						<Route path="/customers" component={Customers}/>
						<Route path="/settings" component={Settings}/>
						<Route component={NotFound}/>
					</Switch>
				</div>
			</div>
		)
	}
}

const NotFound = (props)=>{
	return (
		<Paper style={{margin: '20px', padding: '40px'}}>
			{/*<svg style={{width: '50px', display: 'inline-block'}} id="smiley" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.33 108.33"><defs><style>{".cls-1{fill:#ffc71f;}.cls-2{fill:#87e4f5;}.cls-3,.cls-4{fill:none;stroke:#403108;stroke-linecap:round;}.cls-3{stroke-miterlimit:10;stroke-width:4px;}.cls-4{stroke-linejoin:round;stroke-width:5px;}"}</style></defs><title>777836</title><g id="_Group_" data-name="&lt;Group&gt;"><g id="_Group_2" data-name="&lt;Group&gt;"><circle id="_Path_" data-name="&lt;Path&gt;" class="cls-1" cx="54.17" cy="54.17" r="54.17"/></g><path id="_Path_2" data-name="&lt;Path&gt;" class="cls-2" d="M454.71,88.79a9,9,0,0,1-18,0c0-5,9-22,9-22S454.71,83.81,454.71,88.79Z" transform="translate(-350.5 -62.76)"/></g><g id="_Clip_Group_" data-name="&lt;Clip Group&gt;"><path id="_Clipping_Path_" data-name="&lt;Clipping Path&gt;" class="cls-3" d="M421,146.12a16.63,16.63,0,0,0-15.78-10.73c-10.35,0-14.62,7.74-15.91,10.81" transform="translate(-350.5 -62.76)"/></g><polyline id="_Path_3" data-name="&lt;Path&gt;" class="cls-4" points="27.64 40.08 40.64 47.43 27.74 54.77"/><polyline id="_Path_4" data-name="&lt;Path&gt;" class="cls-4" points="79.12 54.77 66.42 47.43 79.02 40.08"/></svg>*/}
			<Typography style={{display: 'inline-block'}} variant="h3">404</Typography>
			<Typography variant="h5">{_t("SHORT_MESSAGE")}</Typography>
		</Paper>
	)
		//<p>{props.location.pathname} - 404: {_t("SHORT_MESSAGE")}</p>
}

const Content = withRouter(connect((state)=>{return {width: state.drawer.width, fetching: state.fetching}})(_Content))

export default Content