export function toggleDrawer(){
	return {
		type: 'TOGGLE_DRAWER'
	}
}

export function login(username, password, twoFactorToken=null){
	return {
		type: 'LOGIN',
		payload: {
			request: {
				url: '/login/user',
				method: 'post',
				data: {
					username,
					password,
					twoFactorToken,
					intent: 'adminDashboard'
				}
			}
		}
	}
}

export function toggleStaySignedIn(stay){
	return {
		type: 'TOGGLE_STAYSIGNEDIN',
		stay
	}
}

export function logout(){
	return {
		type: 'LOGOUT'
	}
}

export function fetchPreferences(){
	return {
		type: 'FETCH_PREFERENCES',
		payload: {
			request: {
				url: '/preferences',
				method: 'get'
			}
		}
	}
}

export function alterPreferences(data){
	return {
		type: 'ALTER_PREFERENCES',
		payload: {
			request: {
				url: '/preferences',
				method: 'post',
				data
			}
		}
	}
}

export function fetchTraffic(){
	return {
		type: 'FETCH_TRAFFIC',
		payload: {
			request: {
				url: '/traffic',
				method: 'get'
			}
		}
	}
}

export function fetchStoreStatus(){
	return {
		type: 'FETCH_STORE_STATUS',
		payload: {
			request: {
				url: '/store/status',
				method: 'get'
			}
		}
	}
}

export function alterStoreStatus(open){
	return {
		type: 'FETCH_ALTER_STORE_STATUS',
		payload: {
			request: {
				url: '/store/status',
				method: 'post',
				data: {open}
			}
		}
	}
}

export function fetchMenuCard(){
	return {
		type: 'FETCH_MENUCARD',
		payload: {
			request: {
				url: '/menucard',
				method: 'get'
			}
		}
	}
}

export function fetchMenuCardItem(id){
	return {
		type: 'FETCH_MENUCARDITEM',
		payload: {
			request: {
				url: '/menucard/' + id,
				method: 'get'
			}
		}
	}
}



export function alterMenuCardItem(alteredItem){
	return {
		type:'FETCH_ALTER_MENUCARDITEM',
		payload: {
			request: {
				url:'/menucard/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function deleteMenuCardItem(id){
	return {
		type:'FETCH_DELETE_MENUCARDITEM',
		payload: {
			request: {
				url:'/menucard/' + id,
				method:'delete'
			}
		}
	}
}

export function newMenuCardItem(newItem){
	return {
		type:'FETCH_NEW_MENUCARDITEM',
		payload: {
			request: {
				url:'/menucard',
				method:'post',
				data: newItem
			}
		}
	}
}

export function fetchMenuCardCategories(){
		return {
		type: 'FETCH_MENUCARDCATEGORIES',
		payload: {
			request: {
				url: '/menucardcategories',
				method: 'get'
			}
		}
	}
}

export function newMenuCardCategory(newMcc){
		return {
		type: 'FETCH_NEW_MENUCARDCATEGORY',
		payload: {
			request: {
				url: '/menucardcategories',
				method: 'post',
				data: newMcc
			}
		}
	}
}

export function deleteMenuCardCategory(id){
		return {
		type: 'FETCH_DELETE_MENUCARDCATEGORY',
		payload: {
			request: {
				url: '/menucardcategories/' + id,
				method: 'delete'
			}
		}
	}
}

export function alterMenuCardCategory(id, data){
		return {
		type: 'FETCH_ALTER_MENUCARDCATEGORY',
		payload: {
			request: {
				url: '/menucardcategories/' + id,
				method: 'put',
				data
			}
		}
	}
}

export function fetchUnits(){
		return {
		type: 'FETCH_UNITS',
		payload: {
			request: {
				url: '/units',
				method: 'get'
			}
		}
	}
}

export function alterUnit(alteredItem){
	return {
		type:'FETCH_ALTER_UNIT',
		payload: {
			request: {
				url:'/units/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newUnit(newItem){
	return {
		type:'FETCH_NEW_UNIT',
		payload: {
			request: {
				url:'/units',
				method:'post',
				data: newItem
			}
		}
	}
}

export function deleteUnit(id){
	return {
		type:'FETCH_DELETE_UNIT',
		payload: {
			request: {
				url:'/units/' + id,
				method:'delete',
			}
		}
	}
}

export function fetchVatKeys(){
	return {
		type: 'FETCH_VATKEYS',
		payload: {
			request: {
				url: '/vatkeys',
				method: 'get'
			}
		}
	}
}

export function alterVatKey(alteredItem){
	return {
		type:'FETCH_ALTER_VATKEY',
		payload: {
			request: {
				url:'/vatkeys/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newVatKey(newItem){
	return {
		type:'FETCH_NEW_VATKEY',
		payload: {
			request: {
				url:'/vatkeys',
				method:'post',
				data: newItem
			}
		}
	}
}

export function deleteVatKey(id){
	return {
		type:'FETCH_DELETE_VATKEYS',
		payload: {
			request: {
				url:'/vatkeys/' + id,
				method:'delete',
			}
		}
	}
}

export function fetchUsers(){
	return {
		type: 'FETCH_USERS',
		payload: {
			request: {
				url: '/users',
				method: 'get'
			}
		}
	}
}

export function fetchRoleGroups(){
	return {
		type: 'FETCH_ROLE_GROUPS',
		payload: {
			request: {
				url: '/users/groups',
				method: 'get'
			}
		}
	}
}

export function alterRoleGroup(alteredItem){
	return {
		type:'FETCH_ALTER_ROLE_GROUP',
		payload: {
			request: {
				url:'/users/groups/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newRoleGroup(newItem){
	return {
		type:'FETCH_NEW_ROLE_GROUP',
		payload: {
			request: {
				url:'/users/groups',
				method:'post',
				data: newItem
			}
		}
	}
}


export function fetchRoleFunctions(){
	return {
		type: 'FETCH_ROLE_FUNCTIONS',
		payload: {
			request: {
				url: '/users/functions',
				method: 'get'
			}
		}
	}
}

export function alterRoleFunction(alteredItem){
	return {
		type: 'FETCH_ALTER_ROLE_FUNCTION',
		payload: {
			request: {
				url:'/users/functions/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function alterUser(alteredItem){
	return {
		type:'FETCH_ALTER_USER',
		payload: {
			request: {
				url:'/users/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newUser(newItem){
	return {
		type:'FETCH_NEW_USER',
		payload: {
			request: {
				url:'/users',
				method:'post',
				data: newItem
			}
		}
	}
}

export function deleteUser(id){
	return {
		type:'FETCH_DELETE_USERS',
		payload: {
			request: {
				url:'/users/' + id,
				method:'delete',
			}
		}
	}
}

export function fetchTableMaps(){
	return {
		type: 'FETCH_TABLEMAPS',
		payload: {
			request: {
				url: '/tablemaps',
				method: 'get'
			}
		}
	}
}

export function alterTableMap(alteredItem){
	return {
		type:'FETCH_ALTER_TABLEMAP',
		payload: {
			request: {
				url:'/tablemaps/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newTableMap(newItem){
	return {
		type:'FETCH_NEW_TABLEMAP',
		payload: {
			request: {
				url:'/tablemaps',
				method:'post',
				data: newItem
			}
		}
	}
}

export function deleteTableMap(id){
	return {
		type:'FETCH_DELETE_TABLEMAP',
		payload: {
			request: {
				url:'/tablemaps/' + id,
				method:'delete',
			}
		}
	}
}

export function alterTable(alteredItem){
	return {
		type:'FETCH_ALTER_TABLE',
		payload: {
			request: {
				url:'/tables/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newTable(newItem){
	return {
		type:'FETCH_NEW_TABLE',
		payload: {
			request: {
				url:'/tables',
				method:'post',
				data: newItem
			}
		}
	}
}

export function deleteTable(id){
	return {
		type:'FETCH_DELETE_TABLES',
		payload: {
			request: {
				url:'/tables/' + id,
				method:'delete',
			}
		}
	}
}

export function fetchServingLocations(){
	return {
		type: 'FETCH_SERVINGLOCATIONS',
		payload: {
			request: {
				url: '/servinglocations',
				method: 'get'
			}
		}
	}
}

export function alterServingLocation(alteredItem){
	return {
		type:'FETCH_ALTER_SERVINGLOCATION',
		payload: {
			request: {
				url:'/servinglocations/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newServingLocation(newItem){
	return {
		type:'FETCH_NEW_SERVINGLOCATION',
		payload: {
			request: {
				url:'/servinglocations',
				method:'post',
				data: newItem
			}
		}
	}
}

export function deleteServingLocation(id){
	return {
		type:'FETCH_DELETE_SERVINGLOCATIONS',
		payload: {
			request: {
				url:'/servinglocations/' + id,
				method:'delete',
			}
		}
	}
}

export function fetchPrinters(){
	return {
		type: 'FETCH_PRINTERS',
		payload: {
			request: {
				url: '/printers',
				method: 'get'
			}
		}
	}
}

export function alterPrinter(alteredItem){
	return {
		type:'FETCH_ALTER_PRINTER',
		payload: {
			request: {
				url:'/printers/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newPrinter(newItem){
	return {
		type:'FETCH_NEW_PRINTER',
		payload: {
			request: {
				url:'/printers',
				method:'post',
				data: newItem
			}
		}
	}
}

export function deletePrinter(id){
	return {
		type:'FETCH_DELETE_PRINTERS',
		payload: {
			request: {
				url:'/printers/' + id,
				method:'delete',
			}
		}
	}
}

export function fetchPrinterServers(){
	return {
		type: 'FETCH_PRINTERSERVERS',
		payload: {
			request: {
				url: '/printerservers',
				method: 'get'
			}
		}
	}
}

export function alterPrinterServer(alteredItem){
	return {
		type:'FETCH_ALTER_PRINTERSERVER',
		payload: {
			request: {
				url:'/printerservers/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newPrinterServer(newItem){
	return {
		type:'FETCH_NEW_PRINTERSERVER',
		payload: {
			request: {
				url:'/printerservers',
				method:'post',
				data: newItem
			}
		}
	}
}

export function deletePrinterServer(id){
	return {
		type:'FETCH_DELETE_PRINTERSERVERS',
		payload: {
			request: {
				url:'/printerservers/' + id,
				method:'delete',
			}
		}
	}
}

export function fetchDiscounts(){
	return {
		type: 'FETCH_DISCOUNTS',
		payload: {
			request: {
				url: '/discounts',
				method: 'get'
			}
		}
	}
}

export function alterDiscount(alteredItem){
	return {
		type:'FETCH_ALTER_DISCOUNT',
		payload: {
			request: {
				url:'/discounts/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newDiscount(newItem){
	return {
		type:'FETCH_NEW_DISCOUNT',
		payload: {
			request: {
				url:'/discounts',
				method:'post',
				data: newItem
			}
		}
	}
}

export function deleteDiscount(id){
	return {
		type:'FETCH_DELETE_DISCOUNTS',
		payload: {
			request: {
				url:'/discounts/' + id,
				method:'delete',
			}
		}
	}
}

export function fetchTerminals(){
	return {
		type: 'FETCH_TERMINALS',
		payload: {
			request: {
				url: '/terminals',
				method: 'get'
			}
		}
	}
}

export function alterTerminal(alteredItem){
	return {
		type:'FETCH_ALTER_TERMINAL',
		payload: {
			request: {
				url:'/terminals/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newTerminal(newItem){
	return {
		type:'FETCH_NEW_TERMINAL',
		payload: {
			request: {
				url:'/terminals',
				method:'post',
				data: newItem
			}
		}
	}
}

export function deleteTerminal(id){
	return {
		type:'FETCH_DELETE_TERMINALS',
		payload: {
			request: {
				url:'/terminals/' + id,
				method:'delete',
			}
		}
	}
}

export function resetFetchError(){
	return  {
		type: 'RESET_FETCH_ERROR'
	}
}

export function changeLanguage(language){
	return {
		type: 'CHANGE_LANGUAGE',
		language
	}
}

/* Deprecated */
export function printReceipt(rows, printerId){
	return {
		type: 'PRINT_RECEIPT',
		payload: {
			request: {
				url: '/print/printer/' + printerId,
				method: 'post',
				data: {
					rows
				}
			}
		}
	}
}

export function printClosingReceipt(printerId){
	return {
		type: 'PRINT_CLOSINGRECEIPT',
		payload: {
			request: {
				url: '/print/closingreceipt',
				method: 'post',
				data: {
					printerId
				}
			}
		}
	}
}

export function pushSubscribe(subscription, uniqueId){
	return {
		type: 'PUSH_SUBSCRIPTION',
		payload: {
			request: {
				url: '/users/push',
				method: 'post',
				data: {
					subscription: subscription,
					uniqueId
				}
			}
		}
	}	
}

export function pushUnsubscribe(uniqueId){
	return {
		type: 'PUSH_UNSUBSCRIPTION',
		payload: {
			request: {
				url: '/users/push',
				method: 'delete',
				data: {
					uniqueId
				}
			}
		}
	}	
}

export function twoFAEnable(){
	return {
		type: 'TWOFA_ENABLE',
		payload: {
			request: {
				method: 'post',
				url: '/users/twofactor/enable'
			}
		}
	}	
}

export function twoFAVerify(token){
	return {
		type: 'TWOFA_VERIFY',
		payload: {
			request: {
				url: '/users/twofactor/verify',
				method: 'post',
				data: {
					token
				}
			}
		}
	}	
}

export function twoFADisable(){
	return {
		type: 'TWOFA_DISABLE',
		payload: {
			request: {
				method: 'post',
				url: '/users/twofactor/disable'
			}
		}
	}	
}


export function fetchProfile(){
	return {
		type: 'FETCH_PROFILE',
		payload: {
			request: {
				url: '/users/profile',
				method: 'get'				
			}
		}
	}	
}

export function fetchStatistics(ids, userIds=null){
	if (!ids.length) ids = 'null*null'
	if (!userIds.length) userIds = 'null*null'
	return {
		type: 'FETCH_STATISTICS',
		payload: {
			request: {
				url: '/traffic/statistics',
				method: 'get',
				params: {ids, userIds}
			}
		}
	}
}

export function fetchStatisticsIds(startDate=null, endDate=null){
	return {
		type: 'FETCH_STATISTICS_IDS',
		payload: {
			request: {
				url: '/traffic/statistics/date',
				method: 'get',
				params: {startDate, endDate}
			}
		}
	}
}

export function selectStoreStatusId(id){
	return {
		type: 'SELECT_STORE_STATUS_ID',
		id
	}
}

export function selectUserStats(id){
	return {
		type: 'SELECT_USER_STATS',
		id
	}
}

export function fetchStatus(){
	return {
		type: 'FETCH_STATUS',
		payload: {
			request: {
				url: '/status',
				method: 'get'	
			}
		}
	}
}

export function fetchOpenTables(){
	return {
		type: 'FETCH_OPEN_TABLES',
		payload: {
			request: {
				url: '/tables/open',
				method: 'get'	
			}
		}
	}
}
export function downloadUpdate(){
	return {
		type: 'FETCH_DOWNLOAD_UPDATE',
		payload: {
			request: {
				url: '/status/update/download',
				method: 'post'
			}
		}
	}
}

export function installUpdate(){
	return {
		type: 'FETCH_INSTALL_UPDATE',
		payload: {
			request: {
				url: '/status/update/install',
				method: 'post'
			}
		}
	}
}

export function checkUpdate(){
	return {
		type: 'FETCH_CHECK_UPDATE',
		payload: {
			request: {
				url: '/status/update/check',
				method: 'post'
			}
		}
	}
}


export function fetchCustomers(){
	return {
		type: 'FETCH_CUSTOMERS',
		payload: {
			request: {
				url: '/customers',
				method: 'get'
			}
		}
	}
}

export function alterCustomer(alteredItem){
	return {
		type:'FETCH_ALTER_CUSTOMER',
		payload: {
			request: {
				url:'/customers/' + alteredItem.id,
				method:'put',
				data: alteredItem
			}
		}
	}
}

export function newCustomer(newItem){
	return {
		type:'FETCH_NEW_CUSTOMER',
		payload: {
			request: {
				url:'/customers',
				method:'post',
				data: newItem
			}
		}
	}
}

export function deleteCustomer(id){
	return {
		type:'FETCH_DELETE_CUSTOMER',
		payload: {
			request: {
				url:'/customers/' + id,
				method:'delete',
			}
		}
	}
}