import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class ForkDialog extends React.Component{	
	render(){
		this.actions = [
			<Button
			 key={1}
			 variant="text"
			 style={(!this.props.thirdLabel) ? {display: 'none'} : {float: 'left'}}
			 children={(this.props.thirdLabel) ? this.props.thirdLabel : "Mégsem"}
			 onClick={()=>{this.props.thirdAction()}}/>,
			<Button
			 key={2}
			 variant="text"
			 style={(this.props.hideNo) ? {display: 'none'} : {}}
			 children={(this.props.noLabel) ? this.props.noLabel : "Nem"}
			 disabled={(this.props.noDisabled) ? this.props.noDisabled : false}
			 onClick={()=>{this.props.action(false, this.props.id)}}/>,

			<Button
			 key={3}
			 variant="contained"
			 children={(this.props.yesLabel) ? this.props.yesLabel : "Igen"}
			 disabled={(this.props.yesDisabled) ? this.props.yesDisabled : false}
			 color="secondary"
			 style={{marginLeft: '10px', display: (this.props.hideYes) ? 'none' :  'inline-block'}}
			 onClick={()=>{this.props.action(true, this.props.id)}}/>
		]
		return(
				<Dialog open={this.props.open} onClose={()=>{this.props.action(false, this.props.id)}} style={{margin: '15px !important'}}>
					<DialogTitle>
						{this.props.title}
					</DialogTitle>
					<DialogContent style={{width: '400px', maxWidth: 'calc(100vw - 113px)', padding: (this.props.disablePadding) ? '0' : '0 24px 24px'}}>
						{this.props.children}
					</DialogContent>
					<DialogActions>
						{this.actions}
					</DialogActions>
				</Dialog>
		)
	}
}

export default ForkDialog