import React from 'react'
import { connect } from 'react-redux'
import { fetchVatKeys, alterVatKey, newVatKey, deleteVatKey } from '../actions'

import translator from '../translator'
import { withRouter } from 'react-router-dom';

import ForkPlainTable from '../components/ForkPlainTable'

const _t = translator('VATKEYS')

class _VatKeys extends React.Component{
	constructor(props) {
		super(props)
		this.fields = [
			{title:_t("NAME"), field:'name'},
			{title:_t("VALUE"), field:'value', unit: '%', align: 'right', type: 'number'},
			{title:_t("APPLY_DIRECT_DISCOUNT"), field:'applyDirectDiscount', type:'boolean', hiddenXs: true, required: false}

		]
		this.props.dispatch(fetchVatKeys())
		this.save = this.save.bind(this)
		this.delete = this.delete.bind(this)
	}
	save(item, newItem){
		let promise
		item.value = item.value / 100
		if (newItem) promise = this.props.dispatch(newVatKey(item))
		if (!newItem) promise = this.props.dispatch(alterVatKey(item))
		promise.then(()=>{
			this.props.dispatch(fetchVatKeys())
		})
		return promise
	}
	delete(id){
		return this.props.dispatch(deleteVatKey(id)).then(()=>{
			this.props.dispatch(fetchVatKeys())
		})
	}
	render(){
		return (
			<div>
				<ForkPlainTable 
					disableAdd
					disableDelete
					dataSource={this.props.vatKeys.map(item=>{return {...item, value: item.value*100}})}
					fields={this.fields}
					name={_t("VATKEY")}
					save={this.save}
					delete={this.delete}
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		vatKeys: state.vatKeys
	}
}
const VatKeys = withRouter(connect(mapStateToProps)(_VatKeys))

export default VatKeys