import React from 'react'
import { connect } from 'react-redux'

import { toggleDrawer } from '../actions'

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { NavLink, withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';


import Home from '@material-ui/icons/Home';
import Assessment from '@material-ui/icons/Assessment';
import RestaurantMenu from '@material-ui/icons/RestaurantMenu';
import People from '@material-ui/icons/People';
import Person from '@material-ui/icons/AccountCircle';
import DesktopIcon from '@material-ui/icons/DesktopWindows';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Place from '@material-ui/icons/Place';
import Layers from '@material-ui/icons/Layers';
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber';
import LocalGroceryStore from '@material-ui/icons/LocalGroceryStore';
import Print from '@material-ui/icons/Print';
import More from '@material-ui/icons/More';
import Settings from '@material-ui/icons/Settings';
import Security from '@material-ui/icons/Security';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';


import translator from '../translator'
const _t = translator('DRAWER')

class _ForkDrawer extends React.Component {
	constructor(props) {
		super(props)
		this.items = [
				{title: _t('HOME'), path: '/home', icon: <Home />},
				{title: _t('STATISTICS'), path: '/statistics', icon: <Assessment />, hidden: false},
				{title: _t('MENUCARD'), path: '/menu', icon: <RestaurantMenu />},
				{title: _t('UNITS'), path: '/units', icon: <ConfirmationNumber />},
				{title: _t('DISCOUNTS'), path: '/discounts', icon: <LocalGroceryStore />},
				{title: _t('TABLES'), path: '/tables', icon: <Layers />, hiddenXs:false},
				{title: _t('USERS'), path: '/users', icon: <People />},
				{title: _t('PRINTERS'), dropdown: true, id:'printers', icon: <Print />, nestedItems: [
					{title: _t('PRINTERS_SERVERS'), path: '/printer-servers'},
					{title: _t('PRINTERS_DEVICES'), path: '/printers'},
				]},
				{title: _t('SPECIAL'), dropdown: true, id:'special', icon: <More />, nestedItems: [
					{title: _t('VATKEYS'), path: '/vat-keys', icon: <LocalOffer />},
					{title: _t('TERMINALS'), path: '/terminals', icon: <DesktopIcon />},
					{title: _t('SERVINGLOCATIONS'), path: '/serving-locations', icon: <Place />},
					
					{title: _t('ROLES'), path: '/roles', icon: <AssignmentInd />},
					{title: _t('ROLEFUNCTIONS'), path: '/role-functions', icon: <Security />},
					{title: _t('CUSTOMERS'), path: '/customers', icon: <People />},
				]},
				{title: _t('PROFILE'), path: '/profile', icon: <Person />},
				{title: _t('SETTINGS'), path: '/settings', icon: <Settings />},	
				
			]
		this.state = {
			dropdowns: {
				special: false,
				printers: false
			}
		}
		this.toggleDrawer = this.toggleDrawer.bind(this)
	}

	toggleDrawer(){
		this.props.dispatch(toggleDrawer())
	}

	render(){
		let bigDrawer = this.props.width > 100 || this.props.mobile
		return (
			<SwipeableDrawer
				className="no-print"
				onClose={this.toggleDrawer}
				onOpen={this.toggleDrawer}
				swipeAreaWidth={25}
				width={(this.props.mobile) ? 256 : this.props.width}
				open={(this.props.mobile) ? this.props.open : true}
				elevation={1}
				style={{overscrollBehaviorY: 'contain'}}
				PaperProps={{style: (!this.props.mobile) ? {marginTop:'64px', transition:'0.3s', width: this.props.width} : {width: 256}}}
				variant={this.props.mobile ? "temporary" : "persistent"}>
				<List style={{paddingTop: 0}}>
				{

					this.items.map((item, i) => {
						if (item.hidden) return null
						if (item.divider) return <Divider />
						if (item.dropdown){
							// let nestedItems = item.nestedItems.map((nestedItem, key)=>{
							// 	return <ListItem button
							// 		key={key}
							// 		containerElement={<NavLink to={nestedItem.path} />}
							// 		onClick={(this.props.mobile) ? ()=>{this.toggleDrawer()} : null}
							// 		leftIcon={nestedItem.icon}
							// 	>{nestedItem.title}</ListItem>
							// })
							return (
								<div key={i}>

								<ListItem
									button
									key={i}
									onClick={()=>{
										let state = this.state
										Object.keys(state.dropdowns).map(id=>{
											if (id==item.id) state.dropdowns[id] = !state.dropdowns[id]
											else state.dropdowns[id] = false
										})
										this.setState({state})
									}}
								>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText inset primary={item.title}/>
									{this.state.dropdowns[item.id] ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse in={this.state.dropdowns[item.id]} timeout="auto" unmountOnExit>
									<Divider/>
									<List component="div" disablePadding>
										{
											item.nestedItems.map((nestedItem, key)=>{
												let s1 = this.props.location.pathname==nestedItem.path ? {backgroundColor:'#ccc', transition: '0.3s'} : {transition: '0.3s'}
												let s2 = bigDrawer ? {paddingLeft: '40px'} : {}
												return (
													<NavLink key={key} to={nestedItem.path} style={{textDecoration: 'none', color:'#969696'}}>
														<ListItem
														button
														onClick={(this.props.mobile) ? ()=>{this.toggleDrawer()} : null}
														style={{...s1, ...s2}}
														>
														<ListItemIcon>{nestedItem.icon}</ListItemIcon>
															<ListItemText inset primary={bigDrawer ? nestedItem.title : ''}/>
														</ListItem>
													</NavLink>
												)	
											})
										}
										
									</List>
									<Divider/>
								</Collapse>
								</div>
							)

							// return <ListItem button
							// 		key={i}
							// 		leftIcon={item.icon}
							// 		primaryTogglesNestedList={true}
							// 		nestedItems={nestedItems}
							// 		>{item.title}</ListItem>
							
						}
						return (
							<NavLink key={i} to={item.path} style={{textDecoration: 'none', color:'#969696'}}>
								<ListItem
								button
								style={this.props.location.pathname==item.path ? {backgroundColor:'#ccc', transition: '0.3s'} : {transition: '0.3s'}}
								
								onClick={()=>{
									let state = this.state
									Object.keys(state.dropdowns).map(id=>{
										state.dropdowns[id] = false
									})
									if (this.props.mobile) this.toggleDrawer()
									this.setState({state})
								}}
								>
								<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText inset primary={item.title}/>
								</ListItem>
							</NavLink>)
					})
				}
				<div style={{height: '56px'}}></div>
				</List>
			</SwipeableDrawer>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		width: state.drawer.width,
		open: state.drawer.open
	}
}
const ForkDrawer = withRouter(connect(mapStateToProps)(_ForkDrawer))
export default ForkDrawer