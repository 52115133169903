import React from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { primary } from './Theme'
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { accent, error } from './Theme'
import ForkLogo from './fork.png'
import { login, changeLanguage, toggleStaySignedIn } from './actions'
import languages from './translator/languages.json'

import translator from './translator'
const _t = translator('LOGIN')

class _Login extends React.Component {
	constructor(props) {
		super(props);
		this.styles = {
			login: {
				width: '350px', // 'calc(100% - 40px)',
				maxWidth: '100%',
				height: 'auto',
				position: 'absolute',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%, -50%)'
			},
			title:{
				height: '140px',
				backgroundColor: primary,
				fontSize: '30px',
				color: '#fff',
				fontWeight: 300,
			},
			logo:{
				height: '68px',
				display: 'block',
				//margin: 'auto',
				margin: '0 20px 10px 20px',
				padding: '20px 0 0 0'
			},
			text:{
				margin: '-5px 0 0 17px'
			},
			mainarea:{
				padding: '20px'
			},
			input:{
				width: '100%'
			},
			button:{
				margin: '0',
			},
			overlay:{
				backgroundColor: 'rgba(0,0,0,0.2)',
				width: '350px',
				position: 'absolute',
				zIndex: 100,
				top: '144px'
			},
			loading:{
				position: 'absolute',
				top: '140px',
				width: '350px',
				borderRadius: 0
			},
			specialButton:{
				margin: '20px 0 0 0'
			}, 
			special: {
				padding: '0 10px 10px 10px',
				overflow: 'hidden',
				margin: '20px 0 0 0'
			},
			cancel: {
				margin: '20px 10px 0 0',
				float: 'right'
			}
		}
				
		this.state = {
			specialOpen: false,
			gotValues: false,
		}

		this.login = this.login.bind(this)
		this.clearError = this.clearError.bind(this)
		this.usernameKeyDown = this.usernameKeyDown.bind(this)
		this.toggleSpecial = this.toggleSpecial.bind(this)
		this.saveSpecial = this.saveSpecial.bind(this)
		this.cancelSpecial = this.cancelSpecial.bind(this)
		this.changeLanguage = this.changeLanguage.bind(this)
	}

	login(e={preventDefault: ()=>{}}){
		e.preventDefault()
		this.usernameInput.blur()
		this.passwordInput.blur()
		//console.log(this.usernameInput)
		this.props.dispatch(login(this.usernameInput.value, this.passwordInput.value, this.state.token))
	}

	usernameKeyDown(e){
		if (e.key === 'Enter' && this.passwordInput.value === ''){
			e.preventDefault()
			this.passwordInput.focus()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.error.failed){
			if (this.props.error.message !== 'WRONG_TOKEN') this.passwordInput.value = ''
			if (this.props.error.message === 'WRONG_USERNAME') this.usernameInput.focus()
			if (this.props.error.message === 'WRONG_PASSWORD') this.passwordInput.focus()
			if (this.props.error.message === 'WRONG_TOKEN') {
				this.tokenInput.focus()
				this.tokenInput.select()	
			}
		}
	}
	
	clearError(){
		if (this.props.error.failed) this.props.dispatch({type:'LOGIN_RESET'})
	}

	toggleSpecial(){
		this.setState({
			specialOpen: !this.state.specialOpen
		})
	}

	staySignedInToggle(toggled){
		this.setState({staySignedIn: toggled})
	}

	saveSpecial(){
		if (this.serverUrlInput.input.value !== this.serverUrlInput.props.defaultValue){
			localStorage.setItem('serverUrl', 'https://' + this.serverUrlInput.input.value + '/api')
			window.location.reload()	
		}
	}

	cancelSpecial(){
		this.serverUrlInput.input.value = this.serverUrlInput.props.defaultValue
		this.toggleSpecial()
	}

	changeLanguage(e, i, value){
		this.props.dispatch(changeLanguage(value))
	}

	render(){
		if (this.props.authed) return (<Redirect to="/home" />)
		return (
			<Paper elevation={3} style={this.styles.login}>
				<Paper square elevation={1} style={this.styles.title}>
					<img alt="FORK" style={this.styles.logo} src={ForkLogo} />
					<div style={this.styles.text}>
						{_t('LOGIN_TEXT')}
					</div>
				</Paper>
				<LinearProgress color="secondary" style={{...this.styles.loading, display: (this.props.loading) ? 'block' : 'none'}} />
				
				<Paper square style={this.styles.mainarea} elevation={0}>
					<Paper elevation={1} style={{padding: '10px', transition: 'height 0.2s', display: (this.props.error.status === 0) ? 'block' : 'none', marginBottom: '15px'}}>
						<b style={{color: error}}>Hálózati hiba</b>
						<br/>
						Nem sikerült kapcsolódni a kiszolgálóhoz.
					</Paper>
					<form onSubmit={this.login}>
						<div style={{margin: 'auto', display: (this.props.tokenNeeded) ? 'block' : 'none', width: '100%'}}>
						<TextField
							value={this.state.token}
							onKeyDown={(e)=>{if (e.key=='Enter') this.login()}}
							onChange={(e)=>{this.clearError();this.setState({token: e.target.value, twoFAVerifyError: false})}}
							label="Kód"
							inputRef={(input)=>this.tokenInput = input}
							helperText={(this.props.wrongTokenCount && this.props.error.failed && this.props.error.message === 'WRONG_TOKEN') ? _t('WRONG_TOKEN') : null}
							error={(this.props.wrongTokenCount && this.props.error.failed && this.props.error.message === 'WRONG_TOKEN')}
							fullWidth
							autoFocus/>	
						</div>
						<div style={(this.props.tokenNeeded) ? {display: 'none'} : {}}>
						<TextField
							onKeyDown={this.usernameKeyDown}
							onChange={this.clearError}
							error={(this.props.error.failed && this.props.error.message === 'WRONG_USERNAME')}
							helperText={(this.props.error.failed && this.props.error.message === 'WRONG_USERNAME') ? _t('WRONG_USERNAME') : null}
							autoCapitalize="off"
							name="username"
							style={{...this.styles.input, display: (this.props.tokenNeeded) ? 'none' : 'block'}}
							inputRef={(input)=>this.usernameInput = input}
							autoComplete="username"
							label={_t('USERNAME')}
							fullWidth
							style={{margin: '0px 0 20px 0'}}/>
						<TextField
							onChange={this.clearError}
							error={(this.props.error.failed && this.props.error.message === 'WRONG_PASSWORD')}
							helperText={(this.props.error.failed && this.props.error.message === 'WRONG_PASSWORD') ? _t('WRONG_PASSWORD') : null}
							name="password"
							style={{...this.styles.input}}
							inputRef={(input)=>this.passwordInput = input}
							label={_t('PASSWORD')}
							type="password"
							autoComplete="current-password"/>
						</div>
						<FormControlLabel 
							style={{marginTop: '10px'}}
							label="Maradjak bejelentkezve"
							control={
								<Checkbox
									checked={this.props.staySignedIn}
									onChange={(e, toggled)=>this.props.dispatch(toggleStaySignedIn(toggled))}/>
							}
						/>
						<Button
							variant="contained"
							color="secondary"
							type="submit"
							style={this.styles.button}
							fullWidth>
							{_t('LOGIN_BUTTON')}
						</Button>
					</form>
					{/*<Button label={_t("SPECIAL")} style={{...this.styles.specialButton, display: (this.state.specialOpen) ? 'none' : 'block'}} onTouchTap={this.toggleSpecial}/>*/}
					{/*<Paper style={{...this.styles.special, display: (this.state.specialOpen) ? 'block' : 'none'}}>
						<SelectField
						 floatingLabelText={_t("LANGUAGE")}
						 value={this.props.language}
						 onChange={this.changeLanguage}
						 style={{width: '100%'}}
						>
							{
								languages.map((item, key)=>{
									return <MenuItem key={key} value={item.shortCode} primaryText={item.name} />
								})
							}
						</SelectField>
						<TextField ref={(serverUrlInput)=>{this.serverUrlInput = serverUrlInput}} floatingLabelText={_t("SERVERURL")} defaultValue={this.props.serverUrl.replace('https://', '').replace('/api', '')} style={this.styles.input}/>
						<RaisedButton onTouchTap={this.saveSpecial} style={{...this.styles.button, float: 'right'}} label={_t("SAVE")} secondary />
						<FlatButton onTouchTap={this.cancelSpecial} style={this.styles.cancel} label={_t("CANCEL")} />
					</Paper>*/}
				</Paper>
				
				<Paper style={{...this.styles.overlay, display: (this.props.loading) ? 'block' : 'none', height: (!this.props.tokenNeeded) ? '255px' : '187px'}} rounded={false} />
			</Paper>
		)
	}
}
const mapStateToProps = (state)=>{
	return {
		authed: state.auth.authed,
		loading: state.auth.loading,
		error: state.auth.error,
		serverUrl: state.serverUrl,
		staySignedIn: state.auth.staySignedIn,
		language: state.language,
		tokenNeeded: state.auth.tokenNeeded,
		wrongTokenCount: state.auth.wrongTokenCount
	}
}

const Login = connect(mapStateToProps)(_Login)

export default Login