import { createStore, applyMiddleware, compose } from 'redux'
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import jwt from 'jsonwebtoken'

let serverUrl = (localStorage.serverUrl) ? localStorage.serverUrl : 'https://' + window.location.hostname + '/api'
const client = axios.create({
	baseURL: serverUrl,
	responseType: 'json'
})


if (!localStorage.language) localStorage.setItem('language', 'hu')
if (!localStorage.uniqueId) localStorage.setItem('uniqueId', (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)  + Math.random().toString(36).substr(2, 5)))

const initialState = {
	language: localStorage.language,
	serverUrl,
	fetching: {},
	fetchError: {
		failed: false,
		error: null
	},
	drawer: {
		open: false,
		width: 256
	},
	auth: {
		authed: (sessionStorage.jwt || localStorage.jwt) ? true : false,
		jwt: (sessionStorage.jwt) ? sessionStorage.jwt : (localStorage.jwt) ? localStorage.jwt : null,
		//username: (sessionStorage.username) ? sessionStorage.username : null,
		//role: (sessionStorage.role) ? sessionStorage.role : null,
		loading: false,
		tokenNeeded: false,
		wrongTokenCount: -1,
		staySignedIn: false,
		error: {
			failed: false,
			status: null,
			statusCode: null
		}
	},
	menuCard: [],
	menuCardCategories: [],
	units: [],
	vatKeys: [],
	servingLocations: [],
	printers: [],
	printerServers: [],
	users: [],
	roleGroups: [],
	roleFunctions: [],
	openTables: [],
	tableMaps: [],
	discounts: [],
	terminals: [],
	customers: [],
	preferences: {},
	traffic: {
		sums: {
			beforeDiscount:{}
		},
		trafficByItems: [],
		sumsByUsers: [],
		stornoAndNegativeItems: []
	},
	storeStatus:{},
	profile: {
		roleGroups: [],
		twoFAUrl: null,
		twoFAVerified: false,
		uniqueId: localStorage.uniqueId,
	},
	statisticsIds: [],
	statistics: {
		sums: {
			beforeDiscount:{}
		},
		trafficByItems: [],
		storeStatuses: [],
		groupedSums: [],
		sumsByTime: [],
		sumsByUsers: [],
		stornoAndNegativeItems: []
	},
	status: {
		fetched: false,
		error: false
	}
}

function reducer(state = initialState, action){
	/*
	if (action.type.substring(0, 6) == "FETCH_" && !action.type.includes('_SUCCESS') && !action.type.includes('_FAIL')){
		let newFetching = {...state.fetching}
		newFetching[action.type] = true
		state = {...state, fetching: newFetching}
	}
	if (action.type.endsWith('_SUCCESS') || action.type.endsWith('_FAIL')){
		let newFetching = {...state.fetching}
		delete newFetching[action.type.replace('_SUCCESS', '').replace('_FAIL', '')]
		state = {...state, fetching: newFetching}
	}
	*/
	
	switch (action.type){
		case 'TOGGLE_DRAWER':
			return {...state, drawer: { width:(state.drawer.width === 256) ? 70 : 256, open: !state.drawer.open }}
		case 'LOGIN':
			return {...state, auth: {...state.auth, loading: true, error: false}}
		case 'LOGIN_SUCCESS':
			if (state.auth.staySignedIn) localStorage.setItem('jwt', action.payload.data.token)
			else sessionStorage.setItem('jwt', action.payload.data.token)
				
			let decoded = jwt.decode(action.payload.data.token)
			//sessionStorage.setItem('username', decoded.username)
			//sessionStorage.setItem('role', decoded.role)
			return {...state, auth: {wrongTokenCount: -1, authed: true, jwt: action.payload.data.token, loading: false, error: {failed: false, status: null, statusCode: null}}}
		case 'LOGIN_FAIL':
			if (action.error.status === 0 || action.error.response.status == 500){
				return {...state, auth: {...state.auth, loading: false, error: {failed: true, status: 0, message: 'NETWORK_ERROR'}}}	
			}
			return {...state, auth: {...state.auth, wrongTokenCount: (action.error.response.data.message=='WRONG_TOKEN') ? state.auth.wrongTokenCount+1 : state.auth.wrongTokenCount, tokenNeeded: (action.error.response.data.message=='WRONG_TOKEN') ? true: false, loading: false, error: {failed: true, status: action.error.status, message: action.error.response.data.message}}}
		case 'LOGIN_RESET':
			return {...state, auth: {...state.auth, error: {...state.auth.error, failed: false}}}
		case 'LOGOUT':
			if (sessionStorage.jwt) sessionStorage.removeItem('jwt')
			if (localStorage.jwt) localStorage.removeItem('jwt')
			return {...state, auth: {...state.auth, authed: false, jwt: null}}
		case 'TOGGLE_STAYSIGNEDIN':
			return {...state, auth: {...state.auth, staySignedIn: action.stay}}
		case 'CHANGE_LANGUAGE':
			localStorage.language = action.language
			return {...state, language: action.language}
		case 'FETCH_MENUCARD_SUCCESS':
			return {...state, menuCard: action.payload.data.map(i=>{
				return {
						...i,
						price: Number(i.price),
						menuCardCategoryId: i.menuCardCategory.id,
						servingLocationId: i.servingLocation.id,
						unitId: i.unit.id,
						vatKey1Id: i.vatKey1.id,
						vatKey2Id: i.vatKey2.id,
					}
			})}
		case 'FETCH_MENUCARDITEM_SUCCESS':
			return {...state, menuCardItem: action.payload.data}
		case 'FETCH_MENUCARDCATEGORIES_SUCCESS':
			return {...state,  menuCardCategories: action.payload.data}
		case 'FETCH_UNITS_SUCCESS':
			return {...state, units: action.payload.data}
		case 'FETCH_CUSTOMERS_SUCCESS':
			return {...state, customers: action.payload.data}
		case 'FETCH_VATKEYS_SUCCESS':
			return {...state, vatKeys: action.payload.data}
		case 'FETCH_USERS_SUCCESS':
			return {...state, users: action.payload.data}
		case 'FETCH_ROLE_GROUPS_SUCCESS':
			return {...state, roleGroups: action.payload.data}
		case 'FETCH_ROLE_FUNCTIONS_SUCCESS':
			return {...state, roleFunctions: action.payload.data}
		case 'FETCH_TABLEMAPS_SUCCESS':
			return {...state, tableMaps: action.payload.data}
		case 'FETCH_SERVINGLOCATIONS_SUCCESS':
			return {...state, servingLocations: action.payload.data}
		case 'FETCH_DISCOUNTS_SUCCESS':
			return {...state, discounts: action.payload.data}
		case 'FETCH_PREFERENCES_SUCCESS':
			return {...state, preferences: action.payload.data}
		case 'FETCH_TRAFFIC_SUCCESS':
			return {...state, traffic: action.payload.data}
		case 'FETCH_PRINTERS_SUCCESS':
			return {...state, printers: action.payload.data}
		case 'FETCH_PRINTERSERVERS_SUCCESS':
			return {...state, printerServers: action.payload.data}
		case 'FETCH_TERMINALS_SUCCESS':
			return {...state, terminals: action.payload.data}
		case 'FETCH_STORE_STATUS_SUCCESS':
			return {...state, storeStatus: action.payload.data}
		case 'FETCH_PROFILE_SUCCESS':
			return {...state, profile: {...state.profile, ...action.payload.data}}
		case 'TWOFA_ENABLE_SUCCESS':
			return {...state, profile: {...state.profile, twoFAUrl: action.payload.data.url}}
		case 'TWOFA_VERIFY_SUCCESS':
			return {...state, profile: {...state.profile, twoFAVerified: action.payload.data.verified}}
		case 'FETCH_STATISTICS_SUCCESS':
			return {...state, statistics: action.payload.data}
		case 'FETCH_STATISTICS_IDS_SUCCESS':

			return {...state, statisticsIds: action.payload.data.storeStatuses.map(sS=>{return {...sS, selected: true}})}
		case 'SELECT_STORE_STATUS_ID':
			if (action.id=="all"){
				let s = state.statisticsIds.some(u=>!u.selected)
				return {...state, statisticsIds: state.statisticsIds.map(sS=>{return {...sS, selected: s}})}
			}
			return {...state, statisticsIds: state.statisticsIds.map(sS=>{return {...sS, selected: (action.id==sS.id) ? !sS.selected : sS.selected}})}
		case 'SELECT_USER_STATS':
				return {...state, statisticsIds: state.statisticsIds.map(sS=>{return {...sS, selected: (action.id==sS.id) ? !sS.selected : sS.selected}})}
		case 'FETCH_STATUS_SUCCESS':
			return {...state, status: {...action.payload.data, fetched: true}}
		case 'FETCH_OPEN_TABLES_SUCCESS':
			return {...state, openTables: action.payload.data}
		case 'FETCH_STATUS_FAIL':
			return {...state, status: {...state.status, fetched: true}}
		case 'RESET_FETCH_ERROR':
			return {...state, fetchError: {...state.fetchError, failed: false}}
	}
	if (action.type.endsWith('_FAIL') && action.type != 'LOGIN_FAIL'){
		return {...state, fetchError: {failed: true, error: action, invalidToken: Boolean(action.error && action.error.response && action.error.response.data && action.error.response.data.invalidToken)}}
	}
	return {...state}
}

const middlewareConfig = {
	interceptors: {
		request: [
			({ getState }, req)=>{
				req.headers.common['Authorization'] = 'Bearer ' + getState().auth.jwt
				return req
			}
		]
	}
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(
	applyMiddleware(axiosMiddleware(client, middlewareConfig))
))

export default store