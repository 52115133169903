import React from 'react'
import { connect } from 'react-redux'
import { connectScreenSize } from 'react-screen-size'
import { fetchTableMaps, alterTableMap, newTableMap, deleteTableMap, alterTable, newTable, deleteTable } from '../actions'
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import { accent } from '../Theme'
import ForkDialog from '../components/ForkDialog'
import ExpandMore from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Info from '@material-ui/icons/Info';

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Rnd from 'react-rnd'
import Toolbar from '@material-ui/core/Toolbar'

import ForkPlainTable from '../components/ForkPlainTable'
import translator from '../translator'
const _t = translator('TABLES')

class _Tables extends React.Component{
	constructor(props) {
		super(props)
		this.props.dispatch(fetchTableMaps()).then((e)=>{
			this.setState({
				selectedTableMap: (e.payload.data[0]) ? e.payload.data[0].id : null,
				fields: [
					{title:_t("NAME"), field:'name'},
					{title:_t("CODE"), field:'code', align:'right'},
					{title:_t("TABLEMAP"), field:'tableMapId', type: 'select', value:'id', text:'name', nullable: false, options: this.props.tableMaps, hiddenXs:true}
				]
			})
		})
		this.state = {
			tableMapSelectOpen: false,
			selectedTableMap: null,
			editOpen: false,
			deleteOpen: false,
			newOpen: false,
			saveSuccessOpen: false,
			confirmTableMapChangeOpen: false,
			intendedTableMapId: null,
			tables: [],
			changes: {},
			editing: null,
			fields: []
		}
		this.inputRefs = {}
		this.simpleSave = this.simpleSave.bind(this)
		this.simpleDelete = this.simpleDelete.bind(this)
	}
	
	componentWillUpdate(nextProps, nextState) {
		/*
		if (this.state.selectedTableMap != nextState.selectedTableMap){
			if (!nextState.selectedTableMap) {
				this.state = {...this.state, editing: null, tables:[]}
			} else {
				this.state = {...this.state, editing: null, changes: [], tables:this.props.tableMaps.find((tM)=>{return tM.id == nextState.selectedTableMap}).tables}
			}
		}
		return true
		*/
	}
	switchTableMap(newTableMapId, force=false){
		if (!newTableMapId) return this.setState({editing: null, tables:[]})
		if (Object.keys(this.state.changes).length && !force){
			return this.setState({
				confirmTableMapChangeOpen: true,
				intendedTableMapId: newTableMapId,
				tableMapSelectOpen: false
			})
		}
		this.setState({
			editing: null,
			changes: {},
			tables: [],
			selectedTableMap: null,
			confirmTableMapChangeOpen: false
		}, ()=>{
			this.setState({
				tables: this.props.tableMaps.find((tM)=>{return tM.id == newTableMapId}).tables,
				selectedTableMap: newTableMapId,
				tableMapSelectOpen: false
			})
		})
	}
	editSave(answer){
		if (!answer) return this.setState({editOpen: false})
		let alteredTableMap = {
			id: this.state.selectedTableMap,
			name: this.inputRefs.editName.value,
			width: this.inputRefs.editWidth.value,
			height: this.inputRefs.editHeight.value,
		}
		this.props.dispatch(alterTableMap(alteredTableMap)).then(()=>{
			return this.props.dispatch(fetchTableMaps())
		}).then(()=>{
			this.setState({editOpen: false})
		})
	}
	newTableMap(answer){
		if (!answer) return this.setState({newOpen: false})
		let newTableMapObj = {
			name: this.inputRefs.newName.value,
			width: this.inputRefs.newWidth.value,
			height: this.inputRefs.newHeight.value,
		}
		let newId = null
		this.props.dispatch(newTableMap(newTableMapObj)).then((e)=>{
			newId = e.payload.data.id
			return this.props.dispatch(fetchTableMaps())
		}).then(()=>{
			this.setState({newOpen: false, selectedTableMap: newId})
		})
	}
	deleteTableMap(answer){
		if (!answer) return this.setState({deleteOpen: false})
		this.props.dispatch(deleteTableMap(this.state.selectedTableMap)).then(()=>{
			return this.props.dispatch(fetchTableMaps())
		}).then((e)=>{
			this.setState({deleteOpen: false, selectedTableMap: e.payload.data[0].id})
		})
	}
	dragStop(id, x, y){
		let newChanges = {...this.state.changes}
		newChanges[id] = {...this.state.changes[id], x, y}
		this.setState({changes: newChanges})
	}
	resizeStop(id, width, height){
		let newChanges = {...this.state.changes}
		newChanges[id] = {...this.state.changes[id], width, height}
		this.setState({changes: newChanges})
	}
	saveChanges(showPrompt=true){
		return Object.keys(this.state.changes).map(id=>{
			return this.props.dispatch(alterTable({...this.state.changes[id], id})).then(()=>{
				return this.props.dispatch(fetchTableMaps()).then(()=>{
					this.setState({changes: {}, saveSuccessOpen: showPrompt})
				})
			})
		})
	}
	reload(){
		let map = this.state.selectedTableMap
		this.setState({selectedTableMap: null}, ()=>{
			this.setState({selectedTableMap: map, changes: {}})
		})
	}
	newTable(){
		this.props.dispatch(newTable({
			tableMapId: this.state.selectedTableMap
		})).then(()=>{
			this.props.dispatch(fetchTableMaps())
		})
	}
	deleteTable(id){
		this.props.dispatch(deleteTable(id)).then(()=>{
			let newChanges = {...this.state.changes}
			delete newChanges[id]
			this.setState({changes: newChanges})
			this.props.dispatch(fetchTableMaps())
		})
	}
	onTableEdit(code, name){
		let newChanges = {...this.state.changes}
		newChanges[this.state.editing] = {...this.state.changes[this.state.editing], code, name}
		this.setState({changes: newChanges, editing: null})
	}
	cancelTableEdit(){
		this.setState({editing: null})
	}
	onDoubleClick(id, cb){
		this.setState({editing: id}, cb)
	}
	simpleSave(item, newItem){
		let promise
		if (newItem) promise = this.props.dispatch(newTable(item))
		if (!newItem) promise = this.props.dispatch(alterTable(item))
		promise.then(()=>{
			this.props.dispatch(fetchTableMaps())
		})
		return promise
	}
	simpleDelete(id){
		return this.props.dispatch(deleteTable(id)).then(()=>{
			this.props.dispatch(fetchTableMaps())
		})
	}
	render(){
		let currentTableMap = (this.props.tableMaps.length && this.state.selectedTableMap!=null) ? this.props.tableMaps.find((tM)=>{return tM.id == this.state.selectedTableMap}) : {tables: []}
		currentTableMap = (currentTableMap) ? currentTableMap : {tables: []}
		if (this.props.isMobile){
			let tables = []
			this.props.tableMaps.map((i)=>i.tables).map(i=>{tables=tables.concat(i)})
			return (
				<div style={{padding: '10px'}}>
					<div>
						<Paper style={{padding: '15px 10px', margin: '0 0 10px 0'}}>

							<Typography variant="h6" style={{marginBottom: '15px'}} align="center">
								<Info style={{marginBottom: '-5px'}}/> Egyszerűsített nézet
							</Typography>
							<Typography variant="subtitle1" align="center">Az asztaltérképeket, valamint az asztalok elhelyezkedését nagyobb képernyőről tudja módosítani.</Typography>
						</Paper>	
					</div>
					{/*
					<Paper style={{margin: '10px 0'}}>
						<ForkPlainTable 
						dataSource={this.props.tableMaps}
						fields={this.mapFields}
						name={_t("TABLEMAP")}
						save={this.save}
						delete={this.delete}
						/>
					</Paper>
					*/}
					<Paper>
					<ForkPlainTable 
						dataSource={tables}
						fields={this.state.fields}
						name={_t("TABLE")}
						save={this.simpleSave}
						delete={this.simpleDelete}
						/>
					</Paper>
				</div>
				
			)
		}
		return (
			<div>
				<Toolbar style={{backgroundColor: '#eee', flexGrow: 1}}>
					
							<div style={{flex: 1}}>
								<Button
									variant="contained"
									style={{marginRight: '10px', backgroundColor:'#fff', padding: '3px 10px 0 18px'}}
									onClick={(e)=>{this.setState({tableMapSelectOpen: true, anchorEl: e.currentTarget})}}
									aria-haspopup="true"
									aria-owns={this.state.tableMapSelectOpen ? 'tableMapSelect' : null}
								>
								{currentTableMap.name}
								<ExpandMore />
								</Button>
								<Menu id="tableMapSelect" anchorEl={this.state.anchorEl} open={this.state.tableMapSelectOpen} onClose={()=>{this.setState({tableMapSelectOpen: false})}}>
									{this.props.tableMaps.map((tableMap, key)=>{
										return <MenuItem style={{color: (tableMap.id == this.state.selectedTableMap) ? accent : 'initial'}} onClick={()=>{
											this.switchTableMap(tableMap.id)
										}} children={tableMap.name} key={key}/>
									})}
								</Menu>
								<Button onClick={()=>{this.setState({editOpen: true})}} variant="contained" color="primary" children={<Edit style={{margin: 0}}/>} style={{marginLeft: 0, minWidth: '40px', marginRight: '10px', display: (!currentTableMap.id) ? 'none' : 'initial', padding: '3px 0 0 0'}}/>
								<Button onClick={()=>{this.setState({deleteOpen: true})}} variant="contained" children={<Delete/>} style={{marginLeft: 0, minWidth: '40px', marginRight: '30px', display: (this.props.tableMaps.length <= 1) ? 'none' : 'initial',  padding: '3px 0 0 0', backgroundColor:'#fff'}}/>
								<Button onClick={()=>{this.setState({newOpen: true})}} variant="contained" color="secondary" style={{marginLeft: 0, minWidth: '40px', padding: '0px 15px 0 10px'}}>
									<Add style={{}}/>
									Asztaltérkép
								</Button>
							</div>
						<Button disabled={!Object.keys(this.state.changes).length} onClick={()=>{this.reload()}} variant="contained" children="Változások Elvetése" style={{marginRight: '10px', backgroundColor:'#fff'}}/>
						<Button disabled={!Object.keys(this.state.changes).length} onClick={()=>{this.saveChanges()}} variant="contained" color="secondary" children="Változások Mentése" style={{marginRight: 0}}/>
				</Toolbar>
				<ForkDialog title="Térkép szerkesztése" open={this.state.editOpen} yesLabel="Mentés" noLabel="Mégsem" action={(answer)=>{this.editSave(answer)}}>
					<TextField label="Név" inputRef={(i)=>{this.inputRefs.editName = i}} defaultValue={currentTableMap.name} fullWidth style={{marginBottom: '20px'}}/>
					<TextField type="number" label="Szélesség" inputRef={(i)=>{this.inputRefs.editWidth = i}} defaultValue={currentTableMap.width} style={{width: '50%'}}/>
					<TextField type="number" label="Magasság" inputRef={(i)=>{this.inputRefs.editHeight = i}} defaultValue={currentTableMap.height} style={{width: '50%'}}/>
				</ForkDialog>
				<ForkDialog title="Térkép hozzáadása" open={this.state.newOpen} yesLabel="Hozzáadás" noLabel="Mégsem" action={(answer)=>{this.newTableMap(answer)}}>
					<TextField autoFocus label="Név" inputRef={(i)=>{this.inputRefs.newName = i}} fullWidth style={{marginBottom: '20px'}}/>
					<TextField type="number" defaultValue="800" label="Szélesség" inputRef={(i)=>{this.inputRefs.newWidth = i}} style={{width: '50%'}}/>
					<TextField type="number" defaultValue="560" label="Magasság" inputRef={(i)=>{this.inputRefs.newHeight = i}} style={{width: '50%'}}/>
				</ForkDialog>

				<ForkDialog title="Változások elmentve" open={this.state.saveSuccessOpen} yesLabel="Rendben" hideNo action={(answer)=>{this.setState({saveSuccessOpen: false})}}>
					{"A változások mentése sikeres volt."}
				</ForkDialog>
				<ForkDialog title="Biztos?" open={this.state.confirmTableMapChangeOpen} yesLabel="Mentés" noLabel="Módosítások elvetése" thirdLabel="Mégsem" action={
					(answer)=>{
						if (!answer) {
							this.switchTableMap(this.state.intendedTableMapId, true)
						} else {
							Promise.all(this.saveChanges(false)).then(()=>{
								this.switchTableMap(this.state.intendedTableMapId, true)
							})
						}
					}
				} thirdAction={()=>{this.setState({confirmTableMapChangeOpen: false})}}>
					{"Ha most vált térképnézetet, akkor az eddigi módosításai elvesznek."}
				</ForkDialog>
				<ForkDialog open={this.state.deleteOpen} yesLabel="Törlés" noLabel="Mégsem" title="Asztal térkép törlése" children={"Biztos, hogy törli a(z) \"" + currentTableMap.name + "\" asztal térképet?"} action={(a)=>{this.deleteTableMap(a)}}/>
				<Button variant="fab" color="secondary" style={{position: 'fixed', right: 50, bottom: 50, zIndex: 999}} onClick={()=>{this.newTable()}}>
					<Add />
				</Button>
				<div style={{margin: '15px', padding: '10px', maxWidth: '100%', maxHeight: 'calc(100vh - 160px)', overflow: 'auto'}}>
					{(currentTableMap) ? 
						<Paper
							style={{width: currentTableMap.width, height: currentTableMap.height, position: 'relative', backgroundSize: '10px 10px', backgroundImage: "linear-gradient(to right, rgba(0,0,0,0.05) 1px, transparent 1px), linear-gradient(to bottom, rgba(0,0,0,0.05) 1px, transparent 1px)", padding: '10px'}}
							/*onMouseDown={()=>{this.setState({editing: null})}}*/
						>
							{currentTableMap.tables.map(((table, key)=>{
								return <Table
											onDoubleClick={(id)=>{this.onDoubleClick(id)}}
											editing={(this.state.editing==table.id)}
											onTableEdit={(code, name)=>{this.onTableEdit(code, name)}}
											cancelTableEdit={()=>{this.cancelTableEdit()}}
											deleteTable={(id)=>{this.deleteTable(id)}}
											x={table.x} y={table.y}
											width={table.width} height={table.height}
											id={table.id} name={table.name} code={table.code}
											key={key}
											resizeStop={(id, width, height)=>{this.resizeStop(id, width, height)}}
											dragStop={(id, x, y)=>{this.dragStop(id, x, y)}}/>
							}))}
						</Paper>
					: null }
				</div>
			</div>
		)
	}
}

class Table extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			x: this.props.x,
			y: this.props.y,
			width: this.props.width,
			heigth: this.props.height,
			name: this.props.name,
			code: this.props.code,
			lastName: this.props.name,
			lastCode: this.props.code
		}
		
	}
	onDragStop(e){
		let x = Math.max(0, Math.round(e.x / 10) * 10)
		let y = Math.max(0, Math.round(e.y / 10) * 10)
		if (x==this.props.x && y==this.props.y) return
		this.props.dragStop(this.props.id, x, y)
	}
	onResizeStop(e){
		let width = e.offsetWidth
		let height = e.offsetHeight
		if (width==this.props.width && height==this.props.height) return
		this.props.resizeStop(this.props.id, width, height)
	}
	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.editing && this.props.editing){
			this.code.focus()
		}
	}
	onKeyDown(key){
		if (key == 'Enter' || key == 'Escape'){
			if (key == 'Enter' && (this.state.code != this.state.lastCode || this.state.name != this.state.lastName)){
				this.setState({lastName: this.state.name, lastCode: this.state.code})
				this.props.onTableEdit(this.state.code, this.state.name)
			}
			if (key == 'Escape' || (this.state.code == this.state.lastCode && this.state.name == this.state.lastName)){
				this.setState({name: this.state.lastName, code: this.state.lastCode})
				this.props.cancelTableEdit()
				this.code.blur()
				this.name.blur()
			}
		}
	}
	render(){
		return (
			<Rnd
				default={{x: this.props.x, y: this.props.y, width: this.props.width, height: this.props.height}}
				resizeGrid={[10, 10]}
				dragGrid={[10, 10]}
				bounds="parent"
				minHeight={50}
				minWidth={50}
				style={{zIndex: (this.props.editing) ? 100 : 10}}
				disableDragging={this.props.editing}
				enableResizing={(this.props.editing) ? false : {top:false, right:true, bottom:true, left:false, topRight:false, bottomRight:true, bottomLeft: false, topLeft:false}}
				onDragStop={(m, e)=>{this.onDragStop(e)}}
				onResizeStop={(m, e, c)=>{this.onResizeStop(c)}}>
				<Paper
				onMouseDown={(e)=>{if(this.props.editing) e.stopPropagation()}}
				onDoubleClick={()=>{this.props.onDoubleClick(this.props.id)}}
				style={{backgroundColor: (this.props.editing) ? '#00796B' : '#009688', cursor: (this.props.editing) ? 'default' : 'move', transition: 'transform 0.07s ease-out', width: '100%', height: '100%'}}>
					<div 
						onClick={()=>{this.props.deleteTable(this.props.id)}}
						style={{cursor: 'pointer', position: 'absolute', top: 0, left: 0, display: (this.props.editing) ? 'initial' : 'none'}}>
							<Close style={{color: '#fff'}}/>
					</div>
					<div style={{color: '#fff', textAlign: 'center', position:'relative', top: '50%', transform: 'translateY(-50%)'}}>
						<TextField
							onFocus={(e)=>{e.target.select()}}
							id={this.props.code + this.props.name}
							inputRef={(i)=>{this.code=i}}
							disabled={!this.props.editing}
							style={{width: '50px', marginBottom: '10px', backgroundColor: (this.props.editing) ? '#00695C' : 'inherit', height: 'auto'}}
							InputProps={{disableUnderline: true}}
							inputProps={{style: {color: '#fff', textAlign: 'center', margin: 0, cursor: (this.props.editing) ? 'auto' : 'move'}}}
							value={this.state.code || ''}
							onChange={(e)=>{this.setState({code: e.target.value})}}
							onKeyDown={(e)=>{this.onKeyDown(e.key)}}/>
						<br />
						<TextField
							inputRef={(i)=>{this.name=i}}
							id={this.props.code + this.props.name + "name"}
							onFocus={(e)=>{e.target.select()}}
							disabled={!this.props.editing}
							style={{width: '90%', backgroundColor: (this.props.editing) ? '#00695C' : 'inherit', height: 'auto'}}
							InputProps={{disableUnderline: true}}
							inputProps={{style: {color: '#fff', textAlign: 'center', margin: 0, cursor: (this.props.editing) ? 'auto' : 'move'}}}
							value={this.state.name || ''}
							onChange={(e)=>{this.setState({name: e.target.value})}}
							onKeyDown={(e)=>{this.onKeyDown(e.key)}}/>
					</div>
					{/*<div className="table-resize" style={{backgroundColor: 'yellow', width: '20px', height: '20px', position: 'absolute', bottom: 0, right: 0, cursor: 'nwse-resize'}} onDrag={(e)=>{this.dragging(e)}}></div>*/}
				</Paper>
			</Rnd>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		tableMaps: state.tableMaps
	}
}
const mapScreenSizeToProps = (screenSize) => {
	return {
		isMobile: screenSize['mobile'] || screenSize['small'],
		isDesktop: screenSize['> mobile']
	}
}
const Tables = withRouter(connect(mapStateToProps)(connectScreenSize(mapScreenSizeToProps)(_Tables)))

export default Tables
