import React from 'react'
import { connect } from 'react-redux'
import { Desktop, Mobile } from '../Responsive'
import { primary, accent } from '../Theme'
import ForkDialog from '../components/ForkDialog'
import Select from '@material-ui/core/Select';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Grid, Row, Col } from 'react-flexbox-grid'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import { fetchVatKeys, fetchTraffic, fetchStoreStatus, alterStoreStatus, fetchPreferences, fetchPrinters, printReceipt, printClosingReceipt, pushSubscribe, fetchStatus, fetchOpenTables } from '../actions'
import Print from '@material-ui/icons/Print'
import Warning from '@material-ui/icons/Warning'
import socket from '../socket'
import ClosingReceipt from '../classes/ClosingReceipt'
import { connectScreenSize } from 'react-screen-size'
//import translator from '../translator'
//const _t = translator('HOME')

let localize = (num)=>{
		return (typeof num != 'undefined') ? num.toLocaleString() : ""
}

class _Home extends React.Component{

	constructor(props) {
		super(props);
		this.props.dispatch(fetchVatKeys())
		this.props.dispatch(fetchTraffic()).then(()=>{
			this.interval = window.setInterval(()=>{
				this.props.dispatch(fetchTraffic())
			}, 10000)
		})
		this.props.dispatch(fetchStoreStatus())
		//this.props.dispatch(fetchStatus())
		this.props.dispatch(fetchPreferences()).then(()=>{
			//this.subscribePush()
		})
		this.props.dispatch(fetchPrinters())
		this.state = {
			showStoreOpenCloseModal: false,
			showPrintModal: false,
			doPrint: false,
			printer: (localStorage.closingPrinter) ? Number(localStorage.closingPrinter) : null,
			sortBy: 2,
			sortDir: 'asc'
		}
		socket.on('store-status-change', data=>{
			this.props.dispatch(fetchStoreStatus())
		})
		this.printCloseReceipt = this.printCloseReceipt.bind(this)
		this.onSortClick = this.onSortClick.bind(this)
	}

	componentWillUnmount(){
		if (this.interval) window.clearInterval(this.interval)
	}

	storeOpenClose(){
		this.props.dispatch(alterStoreStatus(!this.props.storeStatus.open)).then(()=>{
			this.props.dispatch(fetchStoreStatus()).then(()=>{
				this.props.dispatch(fetchTraffic()).then(()=>{
					if (this.state.doPrint && !this.props.storeStatus.open){
						this.printCloseReceipt()
					}
				})
			})
			
			this.setState({showStoreOpenCloseModal: false})
		})
	}

	printCloseReceipt(){
		//let receipt = new ClosingReceipt(this.props.preferences, this.props.traffic)
		this.props.dispatch(printClosingReceipt(this.state.printer))
	}

	onSortClick(i){
		if(i!=this.state.sortBy) return this.setState({sortBy: i, sortDir: (i==0||i==1) ? 'desc' : 'asc'})
		this.setState({sortDir: (this.state.sortDir=='asc') ? 'desc' : 'asc'})
	}

	render(){
		return(
			<Grid fluid style={{padding: '20px 10px 10px 10px'}}>
				<ForkDialog
					open={this.state.showStoreOpenCloseModal}
					action={(act)=>{
						if (act) this.storeOpenClose()
						else this.setState({showStoreOpenCloseModal: false})
					}}
					noLabel="Mégsem"
					yesLabel={(this.props.storeStatus.open) ? 'Zárás' : 'Nyitás'}
					title={(this.props.storeStatus.open) ? 'Zárás megerősítése' : 'Nyitás megerősítése'}
				>
					{this.props.openTables.length ? 
						<div style={{margin: '10px 0 10px 0'}}>
							<Warning color="secondary"/>
							<Typography style={{margin: '-28px 0 10px 29px'}}>A következő asztalok még nincsenek leszámlázva:</Typography>
							{this.props.openTables.map(table=>{
								return <Typography style={{marginLeft: '29px'}}>• {table.code} - {table.name}</Typography>
							})}
						</div>
					: null}
					{(this.props.storeStatus.open) ? 
						<div style={{}}>
							<FormControlLabel label="Zárószalag nyomtatása" style={{marginTop: '0px', marginBottom: '0px'}} control={
								<Checkbox checked={this.state.doPrint} onChange={(e, value)=>{this.setState({doPrint: value})}}/>
							}/>
							{(this.state.doPrint) ? <FormControl fullWidth>
								<InputLabel>Nyomtató</InputLabel>
								<Select
								onChange={(e, t, printer)=>{this.setState({printer: e.target.value}); localStorage.setItem('closingPrinter', e.target.value)}}
								value={this.state.printer}>
									{this.props.printers.map((printer, key)=>{
										return <MenuItem key={key} children={printer.description} value={printer.id}/>
									})}
								</Select>
							</FormControl> : null}
						</div>
					: null}
				</ForkDialog>
				<ForkDialog
					open={this.state.showPrintModal}
					action={(act)=>{
						if (act) this.printCloseReceipt()
						this.setState({showPrintModal: false})
					}}
					title="Zárószalag nyomtatása"
					noLabel="Mégsem"
					yesLabel="Nyomtatás"
					yesDisabled={!this.state.printer}
				>
					<FormControl fullWidth>
						<InputLabel>Nyomtató</InputLabel>
						<Select
							onChange={(e, t, printer)=>{this.setState({printer: e.target.value}); localStorage.setItem('closingPrinter', e.target.value)}}
							value={this.state.printer}
							fullWidth>
								{this.props.printers.map((printer, key)=>{
									return <MenuItem key={key} children={printer.description} value={printer.id}/>
								})}
						</Select>
					</FormControl>
				</ForkDialog>
				<div className="print-only" style={{position: 'absolute !important', top: 0}}>
					<div  style={{fontSize: '30px', fontWeight: 700}}>{this.props.preferences.storeName}</div>
					{(this.props.traffic) ? 
						<div>
							{new Date(this.props.traffic.openTime).toLocaleString().slice(0, -3)} - {(this.props.traffic.closeTime) ? new Date(this.props.traffic.closeTime).toLocaleString().slice(0, -3) : new Date().toLocaleString().slice(0, -3) + " (Üzlet nyitva)"}
						</div>
						: null
					}
					<div style={{fontSize: '20px', fontWeight: 300}}>{(this.props.traffic.closeTime) ? 'Napi forgalomkimutatás' : 'Napközbeni forgalomkimutatás'}</div>
				</div>
				<Row>
					<Col md={12} xs={12}>
						<Paper style={{padding: '20px', marginBottom: '10px', position: 'relative'}} className="no-print">
							<Typography variant="h4" color="textPrimary">{this.props.preferences.storeName}{'\u00A0'}</Typography>
							<Typography gutterBottom variant="h6" color={(this.props.storeStatus.open) ? 'primary' : 'secondary'}>{(this.props.storeStatus.open) ? "Üzlet nyitva" : (typeof this.props.storeStatus.open != 'undefined') ? "Üzlet zárva" : "\u00A0"}</Typography>
							{/*<div style={{fontSize: '40px', fontWeight: 300, color: (this.props.storeStatus.open) ? primary : accent}}>{this.props.preferences.storeName}{(this.props.storeStatus.open) ? " - Üzlet nyitva" : (typeof this.props.storeStatus.open != 'undefined') ? " - Üzlet zárva" : ""}</div>*/}
							<Typography variant="subtitle1">
								{(this.props.storeStatus.open) ? "Nyitás ideje:" : (typeof this.props.storeStatus.open != 'undefined') ? "Zárás ideje:" : ''} {(this.props.storeStatus.createdAt) ? moment(this.props.storeStatus.createdAt).calendar() : "Nyitás ideje:"}
							</Typography>
							<Button fullWidth={this.props.isMobile} variant="contained" color="secondary"
								onClick={()=>{
									this.props.dispatch(fetchOpenTables()).then(()=>{
										this.setState({showStoreOpenCloseModal: true})
									})
								}} style={{margin: '10px 0 0 0'}}>
								{(this.props.storeStatus.open) ? "Zárás" : (typeof this.props.storeStatus.open != 'undefined') ? "Nyitás" : "..."}
							</Button>
							{(typeof this.props.storeStatus.open == 'undefined') ? 
							null :
							(!this.props.storeStatus.open) ? 
							<Button fullWidth={this.props.isMobile} variant="contained"  color="primary" className="no-print" onClick={()=>{this.setState({showPrintModal: true})}} style={{margin: (this.props.isMobile) ? '10px 0 0 0px' : '10px 0 0 10px'}}>
								<Print style={{marginRight: '10px'}}/>
								Zárószalag nyomtatása
							</Button> : null
							}
						</Paper>
					</Col>
					
				</Row>
				
				<Row>
					<Col md={4} mdPush={8} xs={12} id="sums" style={!this.props.isMobile ? {paddingRight: 0} : {}}>
						<Sums traffic={this.props.traffic} />
					</Col>
					<Col md={8} mdPull={4} xs={12} id="traffic"> 
						<TrafficTable sortBy={this.state.sortBy} sortDir={this.state.sortDir} onSortClick={this.onSortClick} isMobile={this.props.isMobile} traffic={this.props.traffic} />
					</Col>
				</Row>
			</Grid>
		)
	}
}

const Sums = (props)=>{
	let vatKeyIds = (props.traffic.sums.vatKeys) ? Object.keys(props.traffic.sums.vatKeys) : []
	return (
		<div>
			<Paper style={{padding: '20px', marginBottom: '10px'}}>
				<Typography variant="h6">Összesített forgalom</Typography>
				
				<Grid fluid style={{padding: '20px 5px 0 5px'}}>
					{
						vatKeyIds.map((vatKeyId)=>{
							let vatKey = props.traffic.sums.vatKeys[vatKeyId]
							return (
								<Row style={{marginBottom: '10px', fontSize: '13px'}} key={vatKey.id}>
									<Col md={6} xs={6}>
										{vatKey.name} ({vatKey.value*100}%)
									</Col>
									<Col md={6} xs={6} style={{textAlign: 'right'}}>
										{localize(vatKey.sum)} Ft
									</Col>
								</Row>
							)
						})
					}
					<Divider />
					<Row style={{paddingTop: '10px', paddingBottom: '8px'}}>
						<Col md={6} xs={6}>
							<span style={{fontWeight: 600, fontSize: '20px'}}>Összesen</span>
						</Col>
						<Col md={6} xs={6} style={{textAlign: 'right'}}>
							<span style={{fontWeight: 600, fontSize: '20px'}}>{localize(props.traffic.sums.sum)} Ft</span>
						</Col>
					</Row>
					<Divider />
					{	(props.traffic.sums.serviceFee) ? 
						<Row style={{paddingTop: '5px'}}>
							<Col md={6} xs={6}>
								<span style={{fontWeight: 400, fontSize: '13px'}}>Szervizdíj</span>
							</Col>
							<Col md={6} xs={6} style={{textAlign: 'right'}}>
								<span style={{fontWeight: 400, fontSize: '13px'}}>{localize(props.traffic.sums.serviceFee)} Ft</span>
							</Col>
							<Col md={6} xs={6} style={{paddingTop: '5px'}}>
								<span style={{fontWeight: 400, fontSize: '13px'}}>Különbség</span>
							</Col>
							<Col md={6} xs={6} style={{textAlign: 'right', paddingTop: '5px'}}>
								<span style={{fontWeight: 400, fontSize: '13px'}}>{localize(props.traffic.sums.sum - props.traffic.sums.serviceFee)} Ft</span>
							</Col>
						</Row> : null
					}
					<Row style={{paddingTop: '5px', paddingBottom: '8px'}}>
						<Col md={6} xs={6}>
							<span style={{fontWeight: 400, fontSize: '13px'}}>Kedvezmény ({props.traffic.sums.discountCount} tétel)</span>
						</Col>
						<Col md={6} xs={6} style={{textAlign: 'right'}}>
							<span style={{fontWeight: 400, fontSize: '13px'}}>{localize(props.traffic.sums.discount)} Ft</span>
						</Col>
					</Row>

					
				</Grid>
			</Paper>
			<Paper style={{padding: '20px', marginBottom: '10px'}}>
				<Typography variant="h6">Sztornózott/mínuszos tételek</Typography>
				<Grid fluid style={{padding: '20px 5px 0 5px'}}>
					<Row style={{marginBottom: '5px', fontSize: '13px', color: 'rgba(0,0,0,0.54)'}}>
						<Col md={6} xs={6}>
							Név
						</Col>
						<Col md={2} xs={2} style={{textAlign: 'right'}}>
							Mennyiség
						</Col>
						<Col md={2} xs={2} style={{textAlign: 'center'}}>
							Rögzítve
						</Col>
						<Col md={2} xs={2} style={{textAlign: 'center'}}>
							Sztornózva
						</Col>
					</Row>
					<Divider style={{marginBottom: '10px'}}/>

					{
						props.traffic.stornoAndNegativeItems.map((item, key)=>{
							return (
								<div>
								<Row style={{marginBottom: '10px', fontSize: '13px'}} key={key}>
									<Col md={6} xs={6}>
										{item.name}
									</Col>
									<Col md={2} xs={2} style={{textAlign: 'right'}}>
										{Number(item.quantity)}
									</Col>
									<Col md={2} xs={2} style={{textAlign: 'center'}}>
										{moment(item.createdAt).format("HH:mm")}
									</Col>
									<Col md={2} xs={2} style={{textAlign: 'center'}}>
										{item.deletedAt ? moment(item.deletedAt).format("HH:mm") : "-"}
									</Col>
								</Row>
								<Divider style={{marginBottom: '10px'}}/>
								</div>
							)
						})
					}
				</Grid>
			</Paper>
			{(props.traffic.sumsByUsers.length>0 && !props.traffic.sumsByUsers.every(u=>u.userId==null)) ?
			<Paper style={{padding: '20px', marginBottom: '10px'}}>
				<Typography variant="h6">Felszolgálók</Typography>
				<Grid fluid style={{padding: '20px 5px 0 5px'}}>
				
					{
						props.traffic.sumsByUsers.map((user, key)=>{
							let name = (user.userId) ? String((user.firstName) ? user.firstName + " " + user.lastName.substr(0,1) : user.username) : 'Nincs'
							return (
								<div>
								<Row style={{marginBottom: '10px', fontSize: '13px'}} key={key}>
									<Col md={6} xs={6}>
										{name}
									</Col>
									<Col md={6} xs={6} style={{textAlign: 'right'}}>
										{localize(Number(user.sum))} Ft
									</Col>
								</Row>
								<Divider style={{marginBottom: '10px'}}/>
								</div>
							)
						})
					}
				</Grid>
			</Paper> : null}
		</div>
	)
}

const TrafficTable = (props)=>{
	let getVal = [(a)=>a.menuCardItem.code, (a)=>a.menuCardItem.name, (a)=>a.quantity, (a)=>a.menuCardItem.price, (a)=>a.discount, (a)=>a.sumPrice][props.sortBy]
	function sorter(a,b) {
		return props.sortDir === 'asc'
    		? (getVal(b) < getVal(a) ? -1 : 1)
    		: (getVal(a) < getVal(b) ? -1 : 1)
	}
	return (
		<Paper style={{padding: '20px', marginBottom: '20px', position: 'relative', overflow: 'auto'}}>
			<Typography variant="h6">Tételes forgalom</Typography>
			<Desktop>
				<Button variant="contained" className="no-print" onClick={()=>{window.print()}} color="primary" children="Forgalom nyomtatása" style={{position: 'absolute', right: '20px', top: '10px'}} icon={<Print />}/>
			</Desktop>
			{ (props.traffic.trafficByItems.length) ? 
			<Table style={{maxWidth: '100%'}}>
				<TableHead>
					<TableRow>
						<TableCell padding="dense" style={{width: '0px', paddingLeft: 0}} numeric className="no-print hidden-xs">
							<TableSortLabel onClick={()=>{props.onSortClick(0)}} direction={props.sortDir} active={props.sortBy==0}>Kód</TableSortLabel>
						</TableCell>
						<TableCell padding="dense">
							<TableSortLabel onClick={()=>{props.onSortClick(1)}} direction={props.sortDir} active={props.sortBy==1}>Név</TableSortLabel>
						</TableCell>
						<TableCell padding="dense" style={{width: '100px'}} numeric>
							<TableSortLabel onClick={()=>{props.onSortClick(2)}} direction={props.sortDir} active={props.sortBy==2}>Mennyiség</TableSortLabel>
						</TableCell>
						<TableCell padding="dense" className="hidden-xs print-only table-cell" style={{width: '100px', display: (props.isMobile) ? 'none' : 'table-cell'}} numeric>
							<TableSortLabel onClick={()=>{props.onSortClick(3)}} direction={props.sortDir} active={props.sortBy==3}>Egységár</TableSortLabel>
						</TableCell>
						{/*
						<TableCell padding="dense" className="hidden-xs no-print" numeric style={{width: '100px', display: (props.isMobile) ? 'none' : 'table-cell'}}>
							<TableSortLabel onClick={()=>{props.onSortClick(4)}} direction={props.sortDir} active={props.sortBy==4}>Kedvezmény</TableSortLabel>
						</TableCell>
						*/}
						<TableCell padding="dense" className="hidden-xs" numeric style={{width: '120px', display: (props.isMobile) ? 'none' : 'table-cell'}}>
							<TableSortLabel onClick={()=>{props.onSortClick(5)}} direction={props.sortDir} active={props.sortBy==5}>Össz. ár</TableSortLabel>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.traffic.trafficByItems.sort(sorter).map((item, key)=>{
						return (
							<TableRow key={key} className="print-tr-condensed">
								<TableCell padding="dense" numeric style={{width: '30px'}} className="no-print hidden-xs">{item.menuCardItem.code}</TableCell>
								<TableCell padding="dense" style={{maxWidth: '140px'}}><Typography noWrap>{item.menuCardItem.name}</Typography></TableCell>
								<TableCell padding="dense" numeric style={{width: '100px'}}>{item.quantity}</TableCell>
								<TableCell padding="dense" numeric style={{width: '100px', display: (props.isMobile) ? 'none' : 'table-cell'}} className="hidden-xs print-only table-cell">{localize(item.menuCardItem.price)} Ft</TableCell>
								{/*<TableCell padding="dense" numeric style={{width: '100px', display: (props.isMobile) ? 'none' : 'table-cell'}} className="no-print hidden-xs">{localize(item.discount)} Ft</TableCell>*/}
								<TableCell padding="dense" numeric style={{width: '110px', display: (props.isMobile) ? 'none' : 'table-cell'}} className="hidden-xs">{localize(item.sumPrice)} Ft</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</Table> :
			<Typography variant="subtitle1" color="textSecondary" align="center" style={{marginTop: '20px'}}>Nincs megjeleníthető elem</Typography>
			} 
		</Paper>
	)
}

const mapStateToProps = (state)=>{
	return {
		traffic: state.traffic,
		vatKeys: state.vatKeys,
		storeStatus: state.storeStatus,
		preferences: state.preferences,
		printers: state.printers,
		openTables: state.openTables
	}
}
const mapScreenSizeToProps = (screenSize) => {
	return {
		isMobile: screenSize['mobile'] || screenSize['small'],
		isDesktop: screenSize['> mobile']
	}
}

const Home = connect(mapStateToProps)(connectScreenSize(mapScreenSizeToProps)(_Home))

export default Home
