import React from 'react'
import { connect } from 'react-redux'
import { fetchUnits, alterUnit, newUnit, deleteUnit } from '../actions'

import translator from '../translator'
import { withRouter } from 'react-router-dom';

import ForkPlainTable from '../components/ForkPlainTable'

const _t = translator('UNITS')

class _Units extends React.Component{
	constructor(props) {
		super(props)
		this.fields = [
			{title:_t("NAME"), field:'name'},
			{title:_t("SHORTNAME"), field:'shortName', required: false}
		]
		this.props.dispatch(fetchUnits())
		this.save = this.save.bind(this)
		this.delete = this.delete.bind(this)
	}
	save(item, newItem){
		let promise
		if (newItem) promise = this.props.dispatch(newUnit(item))
		if (!newItem) promise = this.props.dispatch(alterUnit(item))
		promise.then(()=>{
			this.props.dispatch(fetchUnits())
		})
		return promise
	}
	delete(id){
		return this.props.dispatch(deleteUnit(id)).then(()=>{
			this.props.dispatch(fetchUnits())
		})
	}
	render(){
		return (
			<div>
				<ForkPlainTable 
					dataSource={this.props.units}
					fields={this.fields}
					name={_t("UNIT")}
					save={this.save}
					delete={this.delete}
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		units: state.units
	}
}
const Units = withRouter(connect(mapStateToProps)(_Units))

export default Units