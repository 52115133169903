import React from 'react'
import { connect } from 'react-redux'
import { fetchDiscounts, alterDiscount, newDiscount, deleteDiscount, fetchVatKeys } from '../actions'

import translator from '../translator'
import { withRouter } from 'react-router-dom';

import ForkPlainTable from '../components/ForkPlainTable'

const _t = translator('DISCOUNTS')

class _Discounts extends React.Component{
	constructor(props) {
		super(props)
		
		this.props.dispatch(fetchDiscounts())
		this.props.dispatch(fetchVatKeys())
		this.save = this.save.bind(this)
		this.delete = this.delete.bind(this)
	}
	save(item, newItem){
		let promise
		item.vatKey1Discount = item.vatKey1Discount / 100
		item.vatKey2Discount = item.vatKey2Discount / 100
		item.vatKey3Discount = item.vatKey3Discount / 100
		item.vatKey4Discount = item.vatKey4Discount / 100
		if (newItem) promise = this.props.dispatch(newDiscount(item))
		if (!newItem) promise = this.props.dispatch(alterDiscount(item))
		promise.then(()=>{
			this.props.dispatch(fetchDiscounts())
		})
		return promise
	}
	delete(id){
		return this.props.dispatch(deleteDiscount(id)).then(()=>{
			this.props.dispatch(fetchDiscounts())
		})
	}
	render(){
		if (!this.props.vatKeys) return null
		
		let vatKeyFields = this.props.vatKeys.map(vatKey=>{
			return {title:"Kedvezmény: " + vatKey.name + " (" + vatKey.value*100 + "%)", field:'vatKey'+(vatKey.id)+'Discount', unit: '%', align: 'right', type: 'number', hiddenXs: true, hideOnOpen: false}
		})
		this.fields = [{title:_t("NAME"), field:'name'}, ...vatKeyFields]
		return (
			<div>
				<ForkPlainTable 
					dataSource={this.props.discounts.map(item=>{return {
						...item,
						vatKey1Discount: item.vatKey1Discount * 100,
						vatKey2Discount: item.vatKey2Discount * 100,
						vatKey3Discount: item.vatKey3Discount * 100,
						vatKey4Discount: item.vatKey4Discount * 100
					}})}
					fields={this.fields}
					name={_t("DISCOUNT")}
					save={this.save}
					delete={this.delete}
					/>
			</div>
		)
	}
}

const mapStateToProps = (state)=>{
	return {
		discounts: state.discounts,
		vatKeys: state.vatKeys
	}
}
const Discounts = withRouter(connect(mapStateToProps)(_Discounts))

export default Discounts